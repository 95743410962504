import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Select } from 'common-src/components/base';
import {
  getCognitivePatientScores,
  getPatientScoreResult,
  getPatientScoreType,
  ScoreType,
} from 'common-src/models/PatientScore';

import { BaseTable, CreatorItem, TablePagination, TextItem } from 'src/components/table';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import useCustomSelector from 'src/hooks/useCustomSelector';

import { scoreTypeOptions, TableHeaders } from './constants';
import styles from './ScoresTable.module.scss';

const ScoresTable = ({ patientId }) => {
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });
  const [filteredScores, setFilteredScores] = useState([]);
  const [scoreType, setScoreType] = useState(null);

  const patientScores = useCustomSelector((state) => getCognitivePatientScores(state, patientId));

  useEffect(() => {
    if (!scoreType) {
      setFilteredScores(patientScores);
      return;
    }

    setFilteredScores(patientScores.filter((ps) => ps.scoreType === scoreType));
  }, [scoreType, patientScores]);

  const getData = () =>
    filteredScores.map((score) => ({
      scoreType: (
        <TextItem
          isActive
          text={getPatientScoreType(score.scoreType)}
          isUnderlined={score.scoreType !== ScoreType.Eligibility.value}
          onClick={() => {
            if (!score.interactionId) return;
            window.open(
              `/dashboard-client/documentation/details/${patientId}/${score.interactionId}/read`,
              '',
              'width=1200,height=800,left=300,top=100',
            );
          }}
        />
      ),
      result: <TextItem isActive text={getPatientScoreResult(score)} />,
      createdBy: (
        <CreatorItem
          isActive
          creator={score?.createdBy}
          creatorName={score?.createdBy?.getName(false, true)}
          value={score?.createdBy?.getName(false, true)}
        />
      ),
      resultDate: (
        <TextItem isActive text={moment(score.resultDate).format('MM/DD/YYYY')} value={moment()} />
      ),
    }));

  const renderHeader = () => (
    <div className={['flex-row', 'vertically-centered', 'gap-14', 'm-l-40', 'm-b-14'].join(' ')}>
      <p className={[styles.header, 'font-s-16', 'font-w-700'].join(' ')}>Scores</p>
      <Select
        id="score-type-select"
        classNames={[styles.select]}
        options={scoreTypeOptions}
        onChange={(scoreType) => setScoreType(scoreType?.value ?? null)}
        value={scoreTypeOptions.find((op) => op.value === scoreType)}
        size="small"
        withError={false}
        placeholder="Filter Type"
        isClearable
      />
    </div>
  );

  return (
    <>
      {renderHeader()}
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="scores"
        classNames={[
          'medical-table-new',
          filteredScores?.length <= ResultsCount[25] ? 'no-pagination' : '',
        ]}
        emptyText="No scores"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'type',
          label: 'Type',
          width: '20%',
          sortBy: 'text',
        }}
        paginationData={paginationData}
      />
      <TablePagination onChangePageHandler={setPaginationData} results={filteredScores} />
    </>
  );
};

ScoresTable.propTypes = {
  patientId: PropTypes.number,
};

export default ScoresTable;
