export const PatientLabReadingTypes = Object.freeze({
  A1c: { id: 1, label: 'HbA1c' },
  Weight: { id: 2, label: 'Weight (lbs)' },
  LDL: { id: 3, label: 'LDL (mg/dL)' },
  Height: { id: 4, label: 'Height (in)' },
});

export const ALL_LAB_READING_TYPES = Object.freeze({
  id: -1,
  label: 'All Vital and Result Types',
});

export const LabReadingTypes = [
  ALL_LAB_READING_TYPES,
  ...Object.values(PatientLabReadingTypes).map((type) => ({
    id: type.id,
    label: type.label.split('(')[0],
  })),
];

export const LabReadingSort = Object.freeze({
  NEWEST_FIRST: 'Newest first',
  OLDEST_FIRST: 'Oldest first',
});

export const VitalsLabsTypes = Object.freeze({
  HbA1c: { value: 1, label: 'HbA1c', creatable: true, category: 'HbA1c', unit: '' },
  Weight: { value: 2, label: 'Weight', creatable: false, category: 'Weight', unit: 'Lbs' },
  LDL: {
    value: 3,
    label: 'LDL cholesterol (calculated)',
    creatable: true,
    category: 'Lipids',
    unit: 'mg/dl',
  },
  Height: { value: 4, label: 'Height', creatable: false, category: 'Height', unit: 'Inches' },
  Glucose: { value: 5, label: 'Glucose', creatable: true, category: 'CMP', unit: 'mg/dl' },
  BUN: { value: 6, label: 'BUN', creatable: false, category: 'CMP', unit: 'mg/dl' },
  Creatinine: { value: 7, label: 'Creatinine', creatable: true, category: 'eGFR', unit: 'mg/dl' },
  GFR: { value: 8, label: 'GFR', creatable: false, category: 'CMP', unit: 'mL/min/1.73' },
  EGFRNonAfricanAmerican: {
    value: 9,
    label: 'eGFR, non african american',
    creatable: false,
    category: 'CMP',
    unit: 'mL/min/1.73',
  },
  EGFRAfricanAmerican: {
    value: 10,
    label: 'eGFR, african american',
    creatable: false,
    category: 'CMP',
    unit: 'mL/min/1.73',
  },
  Sodium: { value: 11, label: 'Sodium', creatable: true, category: 'CMP', unit: 'mmol/l' },
  Potassium: { value: 12, label: 'Potassium', creatable: true, category: 'CMP', unit: 'mmol/l' },
  Chloride: { value: 13, label: 'Chloride', creatable: true, category: 'CMP', unit: 'mmol/l' },
  CarbonDioxide: {
    value: 14,
    label: 'Carbon Dioxide',
    creatable: true,
    category: 'CMP',
    unit: 'mmol/l',
  },
  Calcium: { value: 15, label: 'Calcium', creatable: true, category: 'CMP', unit: 'mg/dl' },
  Protein: { value: 16, label: 'Total Protein', creatable: true, category: 'CMP', unit: 'g/dl' },
  Albumin: { value: 17, label: 'Albumin', creatable: true, category: 'CMP', unit: 'g/dl' },
  Globulin: {
    value: 18,
    label: 'Globulin (calculated)',
    creatable: true,
    category: 'CMP',
    unit: 'g/dl',
  },
  BillrubinTotal: {
    value: 19,
    label: 'Total Bilirubin',
    creatable: true,
    category: 'CMP',
    unit: 'g/dl',
  },
  AlkalinePhosphatase: {
    value: 20,
    label: 'Alkaline Phosphatase',
    creatable: true,
    category: 'CMP',
    unit: 'IU/L',
  },
  ALT: { value: 21, label: 'ALT', creatable: true, category: 'CMP', unit: 'IU/L' },
  AST: { value: 22, label: 'AST', creatable: true, category: 'CMP', unit: 'IU/L' },
  CholesterolTotal: {
    value: 23,
    label: 'Cholesterol, Total',
    creatable: true,
    category: 'Lipids',
    unit: 'mg/dl',
  },
  HDLCholesterol: {
    value: 24,
    label: 'HDL cholesterol (calculated)',
    creatable: true,
    category: 'Lipids',
    unit: 'mg/dl',
  },
  Triglycerides: {
    value: 25,
    label: 'Triglycerides',
    creatable: true,
    category: 'Lipids',
    unit: 'mg/dl',
  },
  NonHDLCholesterol: {
    value: 26,
    label: 'Non-HDL Cholesterol (calculated)',
    creatable: true,
    category: 'Lipids',
    unit: 'mg/dl',
  },
  CHOLHDLCRatio: {
    value: 27,
    label: 'CHOL/HDLC ratio',
    creatable: false,
    category: 'Lipids',
    unit: 'mg/dl',
  },
  VLDL: { value: 28, label: 'VLDL', creatable: false, category: 'Lipids', unit: 'mg/dl' },
  WBC: { value: 29, label: 'WBC', creatable: true, category: 'CBC', unit: 'K/mm3' },
  RBC: { value: 30, label: 'RBC', creatable: true, category: 'CBC', unit: 'M/mm3' },
  Hgb: { value: 31, label: 'Hgb', creatable: false, category: 'CBC', unit: 'g/dl' },
  Hct: { value: 32, label: 'Hct', creatable: false, category: 'CBC', unit: '%' },
  MCH: { value: 33, label: 'MCH', creatable: true, category: 'CBC', unit: 'pg' },
  MCHC: { value: 34, label: 'MCHC', creatable: true, category: 'CBC', unit: 'g/dl' },
  MCV: { value: 35, label: 'MCV', creatable: true, category: 'CBC', unit: 'fl' },
  Platelets: {
    value: 36,
    label: 'Platelet Count',
    creatable: true,
    category: 'CBC',
    unit: 'K/mm3',
  },
  RDW: { value: 37, label: 'RDW', creatable: true, category: 'CBC', unit: '%' },
  NeutrophilPercent: {
    value: 38,
    label: 'Neutrophil',
    creatable: false,
    category: 'CBC',
    unit: '%',
  },
  Neutrophils: {
    value: 39,
    label: 'Neutrophils',
    creatable: false,
    category: 'CBC',
    unit: 'K/mm3',
  },
  NeutrophilsAbsolute: {
    value: 40,
    label: 'Neutrophils (absolute)',
    creatable: false,
    category: 'CBC',
    unit: 'K/mm3',
  },
  Lymphs: { value: 41, label: 'Lymphs', creatable: false, category: 'CBC', unit: '%' },
  LymphsAbsolute: {
    value: 42,
    label: 'Lymphs (absolute)',
    creatable: false,
    category: 'CBC',
    unit: 'K/mm3',
  },
  MonocytePercent: { value: 43, label: 'Monocyte', creatable: false, category: 'CBC', unit: '%' },
  Monocytes: { value: 44, label: 'Monocytes', creatable: false, category: 'CBC', unit: 'K/mm3' },
  MonocytesAbsolute: {
    value: 45,
    label: 'Monocytes (absolute)',
    creatable: false,
    category: 'CBC',
    unit: 'K/mm3',
  },
  EosinophilPercent: {
    value: 46,
    label: 'Eosinophil',
    creatable: false,
    category: 'CBC',
    unit: '%',
  },
  Eosinophils: {
    value: 47,
    label: 'Eosinophils',
    creatable: false,
    category: 'CBC',
    unit: 'K/mm3',
  },
  EosinophilsAbsolute: {
    value: 48,
    label: 'Eosinophils (absolute)',
    creatable: false,
    category: 'CBC',
    unit: 'K/mm3',
  },
  BasophilPercent: { value: 49, label: 'Basophil', creatable: false, category: 'CBC', unit: '%' },
  Basophils: { value: 50, label: 'Basophils', creatable: false, category: 'CBC', unit: 'K/mm3' },
  BasophilsAbsolute: {
    value: 51,
    label: 'Basophils (absolute)',
    creatable: false,
    category: 'CBC',
    unit: 'K/mm3',
  },
  uACR: {
    value: 52,
    label: 'uACR',
    creatable: true,
    category: 'uACR',
    unit: 'mg/g',
  },
  eGFR: {
    value: 53,
    label: 'eGFR',
    creatable: true,
    category: 'eGFR',
    unit: 'mL/min/1.73',
  },
  CystatinC: {
    value: 54,
    label: 'Cystatin C',
    creatable: true,
    category: 'eGFR',
    unit: 'mg/L',
  },
  AlbuminGlobulinRatio: {
    value: 55,
    label: 'Albumin/Globulin Ratio (calculated)',
    creatable: true,
    category: 'CMP',
    unit: '',
  },
  BUNCreatinineRatio: {
    value: 56,
    label: 'BUN/Creatinine Ratio (calculated)',
    creatable: true,
    category: 'CMP',
    unit: '',
  },
  CreatinineWithGFREstimated: {
    value: 57,
    label: 'Creatinine with GFR (estimated)',
    creatable: true,
    category: 'CMP',
    unit: 'mg/dL',
  },
  UreaNitrogen: {
    value: 58,
    label: 'Urea Nitrogen',
    creatable: true,
    category: 'CMP',
    unit: 'mg/dL',
  },
  Hemoglobin: {
    value: 59,
    label: 'Hemoglobin',
    creatable: true,
    category: 'CBC',
    unit: 'g/dL',
  },
  Hematocrit: {
    value: 60,
    label: 'Hematocrit',
    creatable: true,
    category: 'CBC',
    unit: '%',
  },
});

export const LabReadingStatus = Object.freeze({
  Active: 1,
  Inactive: 2,
});

export const LabReadingFilter = Object.freeze({
  HbA1c: { value: [VitalsLabsTypes.HbA1c.value], label: 'HbA1c' },
  CBC: {
    value: [
      VitalsLabsTypes.WBC.value,
      VitalsLabsTypes.RBC.value,
      VitalsLabsTypes.Hgb.value,
      VitalsLabsTypes.Hct.value,
      VitalsLabsTypes.MCH.value,
      VitalsLabsTypes.MCHC.value,
      VitalsLabsTypes.MCV.value,
      VitalsLabsTypes.Platelets.value,
      VitalsLabsTypes.RDW.value,
      VitalsLabsTypes.NeutrophilPercent.value,
      VitalsLabsTypes.Neutrophils.value,
      VitalsLabsTypes.NeutrophilsAbsolute.value,
      VitalsLabsTypes.Lymphs.value,
      VitalsLabsTypes.LymphsAbsolute.value,
      VitalsLabsTypes.MonocytePercent.value,
      VitalsLabsTypes.Monocytes.value,
      VitalsLabsTypes.MonocytesAbsolute.value,
      VitalsLabsTypes.EosinophilPercent.value,
      VitalsLabsTypes.Eosinophils.value,
      VitalsLabsTypes.EosinophilsAbsolute.value,
      VitalsLabsTypes.BasophilPercent.value,
      VitalsLabsTypes.Basophils.value,
      VitalsLabsTypes.BasophilsAbsolute.value,
      VitalsLabsTypes.Hemoglobin.value,
      VitalsLabsTypes.Hematocrit.value,
    ],
    label: 'CBC',
  },
  CMP: {
    value: [
      VitalsLabsTypes.Glucose.value,
      VitalsLabsTypes.BUN.value,
      VitalsLabsTypes.GFR.value,
      VitalsLabsTypes.EGFRNonAfricanAmerican.value,
      VitalsLabsTypes.EGFRAfricanAmerican.value,
      VitalsLabsTypes.Sodium.value,
      VitalsLabsTypes.Potassium.value,
      VitalsLabsTypes.Chloride.value,
      VitalsLabsTypes.CarbonDioxide.value,
      VitalsLabsTypes.Calcium.value,
      VitalsLabsTypes.Protein.value,
      VitalsLabsTypes.Albumin.value,
      VitalsLabsTypes.Globulin.value,
      VitalsLabsTypes.BillrubinTotal.value,
      VitalsLabsTypes.AlkalinePhosphatase.value,
      VitalsLabsTypes.ALT.value,
      VitalsLabsTypes.AST.value,
      VitalsLabsTypes.AlbuminGlobulinRatio.value,
      VitalsLabsTypes.BUNCreatinineRatio.value,
      VitalsLabsTypes.CreatinineWithGFREstimated.value,
      VitalsLabsTypes.UreaNitrogen.value,
    ],
    label: 'CMP',
  },
  Lipids: {
    value: [
      VitalsLabsTypes.LDL.value,
      VitalsLabsTypes.CholesterolTotal.value,
      VitalsLabsTypes.HDLCholesterol.value,
      VitalsLabsTypes.Triglycerides.value,
      VitalsLabsTypes.NonHDLCholesterol.value,
      VitalsLabsTypes.CHOLHDLCRatio.value,
      VitalsLabsTypes.VLDL.value,
    ],
    label: 'Lipids',
  },
  uACR: {
    value: [VitalsLabsTypes.uACR.value],
    label: 'uACR',
  },
  eGFR: {
    value: [
      VitalsLabsTypes.eGFR.value,
      VitalsLabsTypes.Creatinine.value,
      VitalsLabsTypes.CystatinC.value,
    ],
    label: 'eGFR',
  },
});

export const HedisExcludedLabReadingFilter = Object.freeze({
  HbA1c: { value: [VitalsLabsTypes.HbA1c.value], label: 'HbA1c' },
  CBC: {
    value: [
      VitalsLabsTypes.WBC.value,
      VitalsLabsTypes.RBC.value,
      VitalsLabsTypes.Hgb.value,
      VitalsLabsTypes.Hct.value,
      VitalsLabsTypes.MCH.value,
      VitalsLabsTypes.MCHC.value,
      VitalsLabsTypes.MCV.value,
      VitalsLabsTypes.Platelets.value,
      VitalsLabsTypes.RDW.value,
      VitalsLabsTypes.NeutrophilPercent.value,
      VitalsLabsTypes.Neutrophils.value,
      VitalsLabsTypes.NeutrophilsAbsolute.value,
      VitalsLabsTypes.Lymphs.value,
      VitalsLabsTypes.LymphsAbsolute.value,
      VitalsLabsTypes.MonocytePercent.value,
      VitalsLabsTypes.Monocytes.value,
      VitalsLabsTypes.MonocytesAbsolute.value,
      VitalsLabsTypes.EosinophilPercent.value,
      VitalsLabsTypes.Eosinophils.value,
      VitalsLabsTypes.EosinophilsAbsolute.value,
      VitalsLabsTypes.BasophilPercent.value,
      VitalsLabsTypes.Basophils.value,
      VitalsLabsTypes.BasophilsAbsolute.value,
      VitalsLabsTypes.Hemoglobin.value,
      VitalsLabsTypes.Hematocrit.value,
    ],
    label: 'CBC',
  },
  CMP: {
    value: [
      VitalsLabsTypes.Glucose.value,
      VitalsLabsTypes.BUN.value,
      VitalsLabsTypes.GFR.value,
      VitalsLabsTypes.EGFRNonAfricanAmerican.value,
      VitalsLabsTypes.EGFRAfricanAmerican.value,
      VitalsLabsTypes.Sodium.value,
      VitalsLabsTypes.Potassium.value,
      VitalsLabsTypes.Chloride.value,
      VitalsLabsTypes.CarbonDioxide.value,
      VitalsLabsTypes.Calcium.value,
      VitalsLabsTypes.Protein.value,
      VitalsLabsTypes.Albumin.value,
      VitalsLabsTypes.Globulin.value,
      VitalsLabsTypes.BillrubinTotal.value,
      VitalsLabsTypes.AlkalinePhosphatase.value,
      VitalsLabsTypes.ALT.value,
      VitalsLabsTypes.AST.value,
      VitalsLabsTypes.AlbuminGlobulinRatio.value,
      VitalsLabsTypes.BUNCreatinineRatio.value,
      VitalsLabsTypes.CreatinineWithGFREstimated.value,
      VitalsLabsTypes.UreaNitrogen.value,
    ],
    label: 'CMP',
  },
  Lipids: {
    value: [
      VitalsLabsTypes.LDL.value,
      VitalsLabsTypes.CholesterolTotal.value,
      VitalsLabsTypes.HDLCholesterol.value,
      VitalsLabsTypes.Triglycerides.value,
      VitalsLabsTypes.NonHDLCholesterol.value,
      VitalsLabsTypes.CHOLHDLCRatio.value,
      VitalsLabsTypes.VLDL.value,
    ],
    label: 'Lipids',
  },
});

export const LabResultsTypeIds = Object.values(VitalsLabsTypes)
  .filter(
    (labType) =>
      ![VitalsLabsTypes.Weight.value, VitalsLabsTypes.Height.value].includes(labType.value),
  )
  .map((labType) => labType.value);

export const HedisExcludedLabResultTypeIds = Object.values(VitalsLabsTypes)
  .filter(
    (labType) =>
      ![
        VitalsLabsTypes.Weight.value,
        VitalsLabsTypes.Height.value,
        VitalsLabsTypes.uACR.value,
        VitalsLabsTypes.eGFR.value,
        VitalsLabsTypes.CystatinC.value,
        VitalsLabsTypes.AlbuminGlobulinRatio.value,
        VitalsLabsTypes.BUNCreatinineRatio.value,
        VitalsLabsTypes.CreatinineWithGFREstimated.value,
        VitalsLabsTypes.UreaNitrogen.value,
        VitalsLabsTypes.Hemoglobin.value,
        VitalsLabsTypes.Hematocrit.value,
      ].includes(labType.value),
  )
  .map((labType) => labType.value);
