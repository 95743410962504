import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { RequestHandlerScreen } from 'common-src/components/base';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag/selectors';
import {
  getPatientLabReadingsRequest,
  getPatientLabResults,
  HedisExcludedLabResultTypeIds,
  LabResultsTypeIds,
  VitalsLabsTypes,
} from 'common-src/models/PatientLabReading';
import {
  getActiveLabResults,
  getFilteredVitalsAndLabs,
} from 'common-src/models/PatientLabReading/helpers';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { defaultFilters, LabsTabs } from './constants';
import { FiltersHeader, Header } from './headers';
import { ListTab, TimelineTab } from './tabs';

const Labs = ({ patientId }) => {
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(defaultFilters);
  const [labResultsConfig, setLabResultsConfig] = useState({
    all: [],
    allFiltered: [],
    activeOnly: [],
  });

  const labResults = useCustomSelector((state) => getPatientLabResults(state, patientId));
  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const isHedisEnabled = useCustomSelector((state) => isFeatureEnabled(state, 'hedis'));
  const labResultTypeIds = isHedisEnabled ? LabResultsTypeIds : HedisExcludedLabResultTypeIds;

  useInterval(() => {
    dispatch(
      getPatientLabReadingsRequest(
        { patientId, typeIds: labResultTypeIds, sort_order: 'desc', limit: 5 },
        {},
      ),
    );
  });

  const { isRequesting } = useRequestLoading([
    getPatientLabReadingsRequest({ patientId, typeIds: labResultTypeIds }),
  ]);

  useEffect(() => {
    const allFiltered = getFilteredVitalsAndLabs(labResults, filters, [
      VitalsLabsTypes.Height.value,
      VitalsLabsTypes.Weight.value,
    ]);
    setLabResultsConfig({
      all: getActiveLabResults(labResults),
      allFiltered,
      activeOnly: getActiveLabResults(allFiltered),
    });
  }, [filters, labResults]);

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling lab results..." />;
  }

  const renderTab = () => {
    switch (filters.tab) {
      case LabsTabs.Timeline.value:
        return <TimelineTab labResults={labResultsConfig.activeOnly} />;
      case LabsTabs.List.value:
        return <ListTab patient={patient} labResults={labResultsConfig.allFiltered} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Header patientId={patientId} labResults={labResultsConfig.all} />
      <FiltersHeader
        filters={filters}
        onChangeHandler={(field, value) => setFilters((prev) => ({ ...prev, [field]: value }))}
        isHedisEnabled={isHedisEnabled}
      />
      {renderTab()}
    </>
  );
};

Labs.propTypes = {
  patientId: PropTypes.number,
};

export default Labs;
