import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import PromptTemplate from 'common-src/models/PromptTemplate';
import { ColorsNew } from 'common-src/styles';

import styles from './TextArea.module.scss';

const LINE_HEIGHT = 20;
const DEVIATION = 7;

const TextArea = ({
  id,
  value,
  label,
  fixedRows,
  description,
  maxRows = 1,
  errorText = '',
  size = 'medium',
  classNames = [],
  readOnly = false,
  required = false,
  disabled = false,
  withError = true,
  placeholder = '',
  debounced = false,
  updateTimeout = 400,
  inputClassNames = [],
  onTextChange = () => {},
  onFinishEditing = () => {},
  descriptionColor = ColorsNew.darkGreen,
  requiredColor = ColorsNew.mediumDarkRed,
  type = 'default',
  templates = [],
}) => {
  const [textValue, setTextValue] = useState(null);
  const [contentHeight, setContentHeight] = useState(0);
  const [isTemplateModalVisible, setTemplateModalVisible] = useState(false);
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);

  const textAreaRef = useRef(null);
  const templateModalRef = useRef(null);
  const readOnlyRef = useRef(null);
  const templateRefs = useRef([]);

  const isSmall = size === 'small';

  useEffect(() => {
    if (!textAreaRef.current || fixedRows) return;

    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight - LINE_HEIGHT}px`;
  }, [textAreaRef.current, value, readOnly, textValue]);

  useEffect(() => {
    if (!readOnlyRef.current || fixedRows) return;

    setContentHeight(readOnlyRef.current.scrollHeight);
  }, [readOnlyRef.current]);

  useEffect(() => {
    if (!debounced) return;

    const debounceFn = setTimeout(() => {
      if (textValue !== null) {
        onFinishEditing(textValue);
      }
    }, updateTimeout);

    return () => clearTimeout(debounceFn);
  }, [textValue]);

  useEffect(() => {
    if (type !== 'template') return;

    const handleClickOutside = (event) => {
      if (templateModalRef.current && !templateModalRef.current.contains(event.target)) {
        setTemplateModalVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [type, templateModalRef]);

  const handleValueForTemplates = (value) => {
    if (type !== 'template') return null;

    if (value.includes('/')) {
      const searchText = value.split('/').pop(); // Extract the part after "/"
      const matchingTemplates = templates.filter(
        (template) =>
          template.trigger.toLowerCase().includes(searchText.toLowerCase()) ||
          template.displayName.toLowerCase().includes(searchText.toLowerCase()) ||
          template.templateText.toLowerCase().includes(searchText.toLowerCase()),
      );
      setFilteredTemplates(matchingTemplates);
      setTemplateModalVisible(true);
      setSelectedTemplateIndex(-1);
    } else {
      setTemplateModalVisible(false);
    }
  };

  const handleTemplateSelect = (template) => {
    const text = template.templateText;
    const lastSlashIndex = textValue.lastIndexOf('/');
    if (lastSlashIndex !== -1) {
      const newValue = `${textValue.substring(0, lastSlashIndex)}${text}`;
      setTextValue(newValue);
    }

    setTemplateModalVisible(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (type !== 'template' || !isTemplateModalVisible || !filteredTemplates?.length) return null;

      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          setSelectedTemplateIndex((prevIndex) =>
            prevIndex < filteredTemplates.length - 1 ? prevIndex + 1 : 0,
          );
          break;
        case 'ArrowUp':
          event.preventDefault();
          setSelectedTemplateIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : filteredTemplates.length - 1,
          );
          break;
        case 'Enter':
          event.preventDefault();
          if (selectedTemplateIndex >= 0 && selectedTemplateIndex < filteredTemplates.length) {
            handleTemplateSelect(filteredTemplates[selectedTemplateIndex]);
          }
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isTemplateModalVisible, filteredTemplates, selectedTemplateIndex]);

  useEffect(() => {
    if (selectedTemplateIndex !== -1 && templateRefs.current[selectedTemplateIndex]) {
      templateRefs.current[selectedTemplateIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedTemplateIndex]);

  const onChangeHandler = (value) => {
    handleValueForTemplates(value);

    if (debounced) {
      setTextValue(value);
      return;
    }

    onTextChange(value);
  };

  const renderTemplatesModal = () => {
    if (type !== 'template' || !isTemplateModalVisible || !filteredTemplates?.length) return null;

    return (
      <div className={styles.templateModal} ref={templateModalRef}>
        {filteredTemplates.map((template, index) => (
          <p
            key={template?.id ?? index}
            ref={(el) => (templateRefs.current[index] = el)}
            className={`${styles.option} ${index === selectedTemplateIndex ? styles.selected : ''}`}
            onClick={() => handleTemplateSelect(template)}
            role="presentation"
          >
            {template.displayName}
          </p>
        ))}
      </div>
    );
  };

  const renderContent = () => {
    if (readOnly) {
      return (
        <p
          ref={readOnlyRef}
          id={id}
          className={[styles.readOnly, isSmall ? 'font-s-14' : 'font-s-18', 'font-w-500'].join(' ')}
        >
          {value || '-'}
        </p>
      );
    }

    const maxHeight = maxRows * LINE_HEIGHT - DEVIATION;
    return (
      <div className={styles.textAreaContainer}>
        <textarea
          ref={textAreaRef}
          id={id}
          className={[
            styles.textArea,
            isSmall ? 'font-s-14' : 'font-s-16',
            errorText ? styles.withError : '',
            'font-w-500',
            'border-r-6',
            ...inputClassNames,
          ].join(' ')}
          style={{
            resize: 'none',
            maxHeight: fixedRows ? 'auto' : `${maxHeight}px`,
            height:
              !textAreaRef.current && !fixedRows
                ? `${Math.min(maxHeight, contentHeight)}px`
                : 'auto',
          }}
          placeholder={placeholder}
          value={textValue === null ? value : textValue}
          onChange={(e) => onChangeHandler(e.target.value)}
          rows={fixedRows || 1}
          required={required}
          disabled={disabled}
        />
        {renderTemplatesModal()}
      </div>
    );
  };

  const renderHeader = () => {
    if (!label) return null;

    return (
      <span
        className={[
          styles.label,
          isSmall ? 'font-s-12' : 'font-s-14',
          errorText ? styles.withError : '',
          'font-w-500',
        ].join(' ')}
      >
        {label}
        <span style={{ color: requiredColor }}>{required ? '*' : ''}</span>
      </span>
    );
  };

  const renderDescription = () => {
    if (!description) return null;

    return (
      <span className={['font-w-400', 'font-s-12'].join(' ')} style={{ color: descriptionColor }}>
        {description}
      </span>
    );
  };

  const renderError = () => {
    if (!withError) return null;

    return (
      <span className={[styles.error, isSmall ? 'font-s-10' : 'font-s-12', 'font-w-400'].join(' ')}>
        {errorText}
      </span>
    );
  };

  return (
    <div className={['gap-5', 'flex-column', ...classNames].join(' ')}>
      {renderHeader()}
      {renderContent()}
      {renderDescription()}
      {renderError()}
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxRows: PropTypes.number,
  fixedRows: PropTypes.number,
  errorText: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  onTextChange: PropTypes.func,
  placeholder: PropTypes.string,
  updateTimeout: PropTypes.number,
  onFinishEditing: PropTypes.func,
  debounced: PropTypes.bool,
  inputClassNames: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  withError: PropTypes.bool,
  descriptionColor: PropTypes.string,
  requiredColor: PropTypes.string,
  type: PropTypes.oneOf(['default', 'template']),
  templates: PropTypes.arrayOf(PropTypes.exact(PromptTemplate.schema)),
};

export default TextArea;
