import PropTypes from 'prop-types';
import React from 'react';

import { VisitType } from 'common-src/models/PatientOncehubBooking';

import { ColumnFilter } from 'src/components/table';

const Filter = ({ types, setTypes }) => {
  const getOptions = () =>
    Object.values(VisitType)
      .sort((a, b) => b.order - a.order)
      .map((type) => ({
        id: type.label,
        text: type.label,
        isChecked: types.includes(type.value),
        onClick: () => {
          if (types.includes(type.value)) {
            setTypes((prev) => prev.filter((x) => x !== type.value));
            return;
          }

          setTypes((prev) => [...prev, type.value]);
        },
      }));

  return (
    <ColumnFilter
      domain="scheduled-call"
      options={getOptions()}
      selectedItemsCount={types?.length ?? 0}
      onClearSelection={() => setTypes([])}
    />
  );
};

Filter.propTypes = {
  types: PropTypes.arrayOf(PropTypes.string),
  setTypes: PropTypes.func,
};

export default Filter;
