export const LabOrderType = Object.freeze({
  HbA1c: 'HbA1c',
  eGFR: 'eGFR',
  uACR: 'uACR',
  LipidPanel: 'Lipid Panel',
  CMP: 'CMP',
  CBC: 'CBC',
});

export const LabOrderStatus = Object.freeze({
  Ordered: 'Ordered',
  Resulted: 'Resulted',
  Canceled: 'Canceled',
});

export const LabOrderLocation = Object.freeze({
  Quest: 'Quest',
});

export const LabOrderResultRange = Object.freeze({
  Normal: 'Normal',
  Abnormal: 'Abnormal',
  Critical: 'Critical',
});

export const LabOrderCancelReason = Object.freeze({
  AlreadyGotLabs: 'Already got labs done this year',
  CostConcerns: 'Cost concerns',
  ProximityToLab: 'Proximity to lab',
  TransportationConcerns: 'Transportation concerns',
  LabsResulted: 'Labs resulted',
  Other: 'Other',
});

export const LabOrderTypeOptions = Object.values(LabOrderType).map((type) => ({
  value: type,
  label: type,
}));

export const LabOrderStatusOptions = Object.values(LabOrderStatus).map((status) => ({
  value: status,
  label: status,
}));

export const LabOrderLocationOptions = Object.values(LabOrderLocation).map((location) => ({
  value: location,
  label: location,
}));

export const LabOrderResultRangeOptions = Object.values(LabOrderResultRange).map((resultRange) => ({
  value: resultRange,
  label: resultRange,
}));

export const LabOrderCancelReasonOptions = Object.values(LabOrderCancelReason).map(
  (cancelReason) => ({
    value: cancelReason,
    label: cancelReason,
  }),
);
