import _ from 'lodash';
import moment from 'moment-timezone';

import { ReferralStatus, ReferralType } from './constants';
import { validatePatientReferral } from './validators';

export const extractPatientReferral = (referral) => ({
  id: referral.id,
  referralType: referral.referralType,
  status: referral.status,
  dateSent: referral.dateSent,
  patientId: referral.patientId,
});

export const getInitialReferralData = (patientId) => ({
  patientId,
  referralType: ReferralType.EyeExam,
  status: ReferralStatus.Sent,
  dateSent: moment().format('YYYY-MM-DD'),
});

export const catchReferralErrors = (formData) => {
  const errors = {};
  formData.forEach((referral, i) => {
    if (!referral.patientId) return;
    Object.entries(referral).forEach(([field, value]) => {
      const err = validatePatientReferral(field, value, referral);
      if (err[field]) {
        Object.assign(errors, { [`${i}.${field}`]: err[field] });
      }
    });
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};
