import moment from 'moment-timezone';

export const generatePDFName = (data, patientId) => {
  const type = data.referralType
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('_');

  return `${patientId}_Referral_${type}__${moment().utc().format('YYYYMMDD')}`;
};
