import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, Select } from 'common-src/components/base';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import {
  createLabReading,
  getLabReadingUnit,
  getPatientLabResults,
  updateLabReading,
  validateLabReadingUniqueness,
  validatePatientLabReading,
} from 'common-src/models/PatientLabReading';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState, SourceOptions, TypeOptions } from './constants';
import { extractLabResult, getIsButtonEnabled } from './helpers';
import styles from './LabPopup.module.scss';

const LabPopup = ({ onClose, patientId, labReadingId }) => {
  const dispatch = useDispatch();
  const [labResult, setLabResult] = useState(initialState);
  const [hasChanges, setHasChanges] = useState(false);

  const labResults = useCustomSelector((state) => getPatientLabResults(state, patientId));
  const labResultToEdit = useCustomSelector((state) =>
    singleModelSelector(state, 'PatientLabReading', labReadingId),
  );
  const isHedisEnabled = useCustomSelector((state) => isFeatureEnabled(state, 'hedis'));

  const { errors, setErrors, renderButtons, hasAlert } = usePopup();

  // check for lab record uniqueness
  const isUniqueRecord = useMemo(() => {
    if (hasAlert || !hasChanges) return true;
    return validateLabReadingUniqueness(labResults, labResult);
  }, [labResults, labResult, hasAlert, hasChanges]);

  const uniqErrorMessage = isUniqueRecord ? '' : 'The lab result already exists for this date';

  const handleErrors = (field, value) => {
    setErrors((prev) => ({
      ...prev,
      ...validatePatientLabReading(field, value),
    }));
  };

  useEffect(() => {
    if (_.isEmpty(labResultToEdit)) return;
    setLabResult(extractLabResult(labResultToEdit));
  }, [labResultToEdit]);

  const onChangeHandler = (field, value) => {
    if (!hasChanges) setHasChanges(true);
    handleErrors(field, value);
    setLabResult((prev) => ({ ...prev, [field]: value }));
  };

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    if (!isUniqueRecord) return;

    loadingCallback();

    if (!_.isEmpty(labResultToEdit)) {
      dispatch(
        updateLabReading(
          {
            ...labResult,
            id: labResultToEdit.id,
            patientId,
            readingDate: labResult.readingDate,
            readingValue: labResult.readingValue,
          },
          {
            successBlock: () => {
              successCallback('Lab Result edited!');
              onClose(true);
            },
            errorBlock: (err) => errorCallback(err),
          },
        ),
      );
      return;
    }

    dispatch(
      createLabReading(
        {
          ...labResult,
          patientId,
          readingDate: labResult.readingDate,
          readingValue: labResult.readingValue,
        },
        {
          successBlock: () => {
            successCallback('Lab Result added!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  return (
    <BasePopup
      id="lab-result"
      open
      onClose={onClose}
      title={`${labReadingId ? 'Edit' : 'Add'} Result`}
    >
      <div className={styles.container}>
        <Select
          id="type-select"
          required
          label="Result Type"
          placeholder="Select"
          options={TypeOptions}
          onChange={(op) => onChangeHandler('typeId', op.value)}
          value={TypeOptions.find((op) => op.value === labResult.typeId) || null}
          size="small"
          disabled={!isHedisEnabled}
        />
        <Select
          id="patientReported"
          label="Source"
          options={SourceOptions}
          onChange={(op) => onChangeHandler('patientReported', op.value)}
          value={SourceOptions.find((op) => op.value === labResult.patientReported)}
          required
          placeholder="Select source"
          errorText={errors.patientReported}
          size="small"
        />
        <Input
          id="value-input"
          placeholder={`Enter ${getLabReadingUnit(labResult.typeId) || 'value'}`}
          label={getLabReadingUnit(labResult.typeId) || 'Value'}
          required
          value={labResult.readingValue}
          onTextChange={(value) => onChangeHandler('readingValue', value.replace(',', '.'))}
          onBlur={() => {
            if (errors.readingValue || !labResult.readingValue) return;
            setLabResult((prev) => ({ ...prev, readingValue: Number(labResult.readingValue) }));
          }}
          errorText={errors.readingValue || uniqErrorMessage}
          size="small"
        />
        <DatePickerNew
          id="date-input"
          placeholder="Select Date"
          header="Result Date"
          required
          maxDate={new Date()}
          errorText={errors.readingDate}
          value={labResult.readingDate}
          onDateSelected={(date) =>
            onChangeHandler('readingDate', date ? moment(date).format('YYYY-MM-DD') : null)
          }
          onBlur={(date) =>
            setErrors((prev) => ({ ...prev, ...validatePatientLabReading('readingDate', date) }))
          }
          size="small"
        />
        {renderButtons({
          containerClassName: styles.buttonsContainer,
          onClose,
          onSubmit: onSubmitHandler,
          isSubmitEnabled: getIsButtonEnabled(labResult, labResultToEdit, errors) && isUniqueRecord,
          submitButtonText: 'Save',
        })}
      </div>
    </BasePopup>
  );
};

LabPopup.propTypes = {
  onClose: PropTypes.func,
  patientId: PropTypes.number,
  labReadingId: PropTypes.number,
};

export default LabPopup;
