import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientCognitiveJourney extends Model {
  static modelName = 'PatientCognitiveJourney';

  static fields = {
    id: attr(),
    status: attr(),
    eligibilityCompletedAt: attr(),
    clinicalInterviewCompletedAt: attr(),
    consentToScreeningCompletedAt: attr(),
    screeningCompletedAt: attr(),
    preAssessmentCompletedAt: attr(),
    assessmentCompletedAt: attr(),
    optedOutOfScreening: attr(),
    unableToCompleteScreening: attr(),
    updatedAt: attr(),
    createdAt: attr(),
    updatedBy: fk('Client', 'cognitiveJourneyChanger'),
    createdBy: fk('Client', 'cognitiveJourneyCreator'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'cognitiveJourney',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      patientId: PropTypes.number,
      status: PropTypes.string,
      eligibilityCompletedAt: PropTypes.string,
      clinicalInterviewCompletedAt: PropTypes.string,
      consentToScreeningCompletedAt: PropTypes.string,
      screeningCompletedAt: PropTypes.string,
      preAssessmentCompletedAt: PropTypes.string,
      assessmentCompletedAt: PropTypes.string,
      optedOutOfScreening: PropTypes.bool,
      unableToCompleteScreening: PropTypes.bool,
      createdBy: PropTypes.number,
      updatedBy: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientCognitiveJourney;
