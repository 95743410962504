import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { restRequestMultiple } from 'common-src/features/rest/actions';
import {
  catchRoutineExamErrors,
  extractRoutineExam,
  getPatientRoutineExams,
} from 'common-src/models/PatientRoutineExam';

import { RoutineExams as RoutineExamsForm } from 'src/components/forms';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import { getRoutineExamRequests } from './helpers';

const RoutineExams = ({ patientId }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState([]);

  const routineExams = useCustomSelector((state) => getPatientRoutineExams(state, patientId));

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchRoutineExamErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    dispatch(
      restRequestMultiple({
        restRequests: getRoutineExamRequests(formData, routineExams),
        successBlock: () => {
          setIsEditMode(false);
          setIsLoading(false);
          showAlert(AlertType.Success, '', 'You have successfully updated routine diabetes exams.');
        },
        errorBlock: (err) => {
          setIsLoading(false);
          showAlert(AlertType.Error, '', err);
        },
      }),
    );
  };

  const onEditHandler = () => {
    setTimeout(() => {
      const element = document.getElementById('routine-exams-buttons');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    setErrors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'routineExams',
  );

  useEffect(() => {
    const data = !_.isEmpty(routineExams)
      ? routineExams.map((routineExam) => extractRoutineExam(routineExam))
      : [];

    setInitialData(data);
    setFormData(data);
  }, [routineExams]);

  return (
    <>
      <RoutineExamsForm
        patientId={patientId}
        isEditMode={isEditMode}
        formData={formData}
        setFormData={setFormData}
        errors={errors}
        setErrors={setErrors}
        addButtonHandler={onEditHandler}
      />
      <div id="routine-exams-buttons" className="scroll-m-b-20">
        {renderContent(false, false, onEditHandler)}
      </div>
    </>
  );
};

RoutineExams.propTypes = {
  patientId: PropTypes.number,
};

export default RoutineExams;
