import _ from 'lodash';

export const getIsButtonEnabled = (isEdit, data, initialData, errors) => {
  const hasErrors = Object.values(errors).some((e) => e);
  if (hasErrors) {
    return false;
  }

  if (isEdit && _.isEqual(data, initialData)) {
    return false;
  }

  return data.id && data.copyText;
};
