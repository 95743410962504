import { allModelsSelector } from 'common-src/utils/selectorUtils';

export const getPatientRoutineExams = (state, patientId) =>
  allModelsSelector(
    state,
    'PatientRoutineExam',
    (pre) => pre.patientId === patientId && !pre.deletedAt,
  ).sort((a, b) => new Date(b.dateCompleted) - new Date(a.dateCompleted));

export const getMostRecentPatientRoutineExams = (state, patientId) => {
  const exams = allModelsSelector(
    state,
    'PatientRoutineExam',
    (pre) => pre.patientId === patientId && !pre.deletedAt,
  );

  const latestExamsByTypeAndDetail = Object.values(
    exams.reduce((acc, exam) => {
      const { type, detail, dateCompleted } = exam;
      const key = `${type}-${detail}`;

      if (!acc[key] || new Date(dateCompleted) > new Date(acc[key].dateCompleted)) {
        acc[key] = exam;
      }

      return acc;
    }, {}),
  );

  return latestExamsByTypeAndDetail;
};
