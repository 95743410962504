import { ScoreType } from 'common-src/models/PatientScore';

export const TableHeaders = [
  { column: 'scoreType', label: 'Type', width: '20%', sortBy: 'text' },
  { column: 'result', label: 'Result', width: '10%', sortBy: 'text' },
  { column: 'createdBy', label: 'Created By', width: '15%', sortBy: 'value' },
  { column: 'resultDate', label: 'Date Resulted', width: '15%', sortBy: 'text' },
];

export const scoreTypeOptions = Object.values(ScoreType).map((type) => ({
  value: type.value,
  label: type.label,
}));
