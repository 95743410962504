import _ from 'lodash';

import { deleteOrmItem } from 'common-src/features/rest';
import PatientLabOrder, {
  createPatientLabOrder,
  deletePatientLabOrder,
  extractLabOrder,
  updatePatientLabOrder,
} from 'common-src/models/PatientLabOrder';

export const getLabOrderRequests = (formData, labOrders) => {
  const requests = [];

  if (!Array.isArray(labOrders) || !Array.isArray(formData)) return requests;

  const oldLabOrders = labOrders.map((labOrder) => extractLabOrder(labOrder));

  if (_.isEqual(formData, oldLabOrders)) {
    return requests;
  }

  formData.forEach((labOrder) => {
    const labOrderExists = oldLabOrders.find(({ id }) => id === labOrder.id);

    if (!labOrderExists) {
      requests.push(createPatientLabOrder(labOrder));
      return;
    }

    if (!_.isEqual(labOrder, labOrderExists)) {
      requests.push(updatePatientLabOrder(labOrder.id, labOrder));
    }
  });

  oldLabOrders.forEach(({ id }) => {
    const labOrderExists = formData.find((labOrder) => labOrder.id === id);

    if (!labOrderExists) {
      requests.push(
        deletePatientLabOrder(id, {
          successBlock: () => {
            deleteOrmItem(PatientLabOrder.modelName, id);
          },
        }),
      );
    }
  });

  return requests;
};
