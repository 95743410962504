export default {
  white: '#FFFFFF',
  black: '#000000',

  darkBlue: '#17335B',
  avenirBlue: '#0091FF',

  lightGray: '#E6E7E8',
  gray: '#d3d3d3',
  mediumDarkGray: '#A3A3A3',
  darkGray: '#6D7278',

  badgeRed: '#E11B1A',
};
