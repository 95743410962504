import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input } from 'common-src/components/base';
import Patient, { updatePatient, validatePatient } from 'common-src/models/Patient';

import useAlert from 'src/hooks/useAlert';
import useFormButtons from 'src/hooks/useFormButtons';

import styles from './AlertSettings.module.scss';

const AlertSettings = ({ patient, isViewOnly }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode) => {
    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        {
          bgmHyperglycemicThreshold: +formData.bgmHyperglycemicThreshold,
          bgmHypoglycemicThreshold: +formData.bgmHypoglycemicThreshold,
        },
        {
          successBlock: () => {
            setIsLoading(false);
            setIsEditMode(false);
            showAlert(AlertType.Success, '', 'You have successfully updated alert settings');
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const onEditHandler = () => {
    setTimeout(() => {
      const element = document.getElementById('alert-settings-buttons');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);
  };

  const {
    isEditMode,
    renderContent,
    setInitialData,
    setIsLoading,
    setIsEditMode,
    errors,
    handleErrors,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode),
    formData,
    true,
  );

  useEffect(() => {
    const data = _.pick(patient, ['bgmHyperglycemicThreshold', 'bgmHypoglycemicThreshold']);
    setInitialData(data);
    setFormData(data);
  }, [patient.bgmHyperglycemicThreshold, patient.bgmHypoglycemicThreshold]);

  const handleInputChange = (field, value) => {
    handleErrors(validatePatient(field, value));
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const renderForm = () => (
    <div className={[styles.form, 'flex-row', 'gap-12', 'm-b-16'].join(' ')}>
      <Input
        id="bgm-hyperglycemic-threshold"
        type="number"
        label="BGM High (mg/dL)"
        placeholder="Enter threshold"
        readOnly={!isEditMode}
        value={formData.bgmHyperglycemicThreshold}
        onTextChange={(value) => handleInputChange('bgmHyperglycemicThreshold', value)}
        size="small"
        errorText={errors.bgmHyperglycemicThreshold}
      />
      <Input
        id="bgm-hypoglycemic-threshold"
        type="number"
        label="BGM Low (mg/dL)"
        placeholder="Enter threshold"
        readOnly={!isEditMode}
        value={formData.bgmHypoglycemicThreshold}
        onTextChange={(value) => handleInputChange('bgmHypoglycemicThreshold', value)}
        size="small"
        errorText={errors.bgmHypoglycemicThreshold}
      />
    </div>
  );

  return (
    <>
      {renderForm()}
      <div id="alert-settings-buttons" className="scroll-m-b-20">
        {renderContent(false, isViewOnly, onEditHandler)}
      </div>
    </>
  );
};

AlertSettings.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  isViewOnly: PropTypes.bool,
};

export default AlertSettings;
