import PropTypes from 'prop-types';
import React from 'react';

import { CustomCheckbox } from 'common-src/components/base';
import {
  CognitiveJourneyStatus,
  getJourneyStatusByLabel,
  getPatientCognitiveJourney,
} from 'common-src/models/PatientCognitiveJourney';

import Icons from 'src/assets/Icons';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './CognitiveJourney.module.scss';

const CognitiveJourney = ({ patientId }) => {
  const patientCognitiveJourney = useCustomSelector((state) =>
    getPatientCognitiveJourney(state, patientId),
  );
  const journeyStatus = getJourneyStatusByLabel(patientCognitiveJourney?.status);

  return (
    <div className={['m-t-32', 'm-b-32', 'p-l-20', 'p-r-20'].join(' ')}>
      <div
        className={[
          'flex-row',
          'vertically-centered',
          'gap-4',
          'm-b-20',
          'primary-border-b',
          'p-b-5',
        ].join(' ')}
      >
        <p className={[styles.title, 'font-s-22', 'font-w-700'].join(' ')}>Cognitive Journey</p>
        <img className={styles.icon} src={Icons.chevron} alt="chevron-icon" />
      </div>
      <div className={['flex-row', 'flex-wrap', 'gap-20'].join(' ')}>
        {Object.values(CognitiveJourneyStatus)
          .filter((cjs) => cjs.isVisible)
          .map((status) => (
            <CustomCheckbox
              key={status.label}
              id={status.label.toLowerCase().split(' ').join('-')}
              classNames={[styles.checkbox]}
              header={status.label}
              checked={journeyStatus ? status.order <= journeyStatus.order : false}
              onChange={() => {}}
            />
          ))}
      </div>
      <div className={['flex-row', 'gap-20', 'm-t-20'].join(' ')}>
        <CustomCheckbox
          id="opted-out-of-screening"
          classNames={[styles.checkbox]}
          header="Opted out of screening"
          checked={patientCognitiveJourney?.optedOutOfScreening}
          onChange={() => {}}
        />
        <CustomCheckbox
          id="unable-to-complete-screening"
          classNames={[styles.checkbox]}
          header="Unable to complete screening"
          checked={patientCognitiveJourney?.unableToCompleteScreening}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};

CognitiveJourney.propTypes = {
  patientId: PropTypes.number,
};

export default CognitiveJourney;
