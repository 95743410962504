import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, TextArea } from 'common-src/components/base';
import {
  createCorrespondenceCopy,
  updateCorrespondenceCopy,
  validateCorrespondenceCopy,
} from 'common-src/models/CorrespondenceCopy';
import { ColorsNew } from 'common-src/styles';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './CorrespondenceCopyPopup.module.scss';
import { getIsButtonEnabled } from './helpers';

const CorrespondenceCopyPopup = ({ onClose, copy, isEdit }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    id: '',
    copyText: '',
    description: '',
  });

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (!isEdit || !copy) return;
    setData({
      id: copy.id,
      copyText: copy.copyText,
      description: copy.description,
    });
  }, [isEdit, copy]);

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validateCorrespondenceCopy(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();

    if (!isEdit) {
      dispatch(
        createCorrespondenceCopy(
          {
            id: data.id,
            copyText: data.copyText,
            description: data.description ?? null,
          },
          {
            successBlock: () => {
              successCallback('Copy added!');
              onClose(true);
            },
            errorBlock: (err) => errorCallback(err),
          },
        ),
      );
      return;
    }

    dispatch(
      updateCorrespondenceCopy(
        copy.id,
        {
          copyText: data.copyText,
          description: data.description ?? null,
        },
        {
          successBlock: () => {
            successCallback('Copy edited!');
            onClose(true);
          },
          errorBlock: (err) => errorCallback(err),
        },
      ),
    );
  };

  return (
    <BasePopup
      id="correspondence-copy"
      open
      onClose={onClose}
      title={isEdit ? 'Edit copy' : 'Add copy'}
      customStyle={{ minWidth: '50vw' }}
    >
      <div className={styles.grid}>
        <Input
          id="id"
          classNames={[styles.width100]}
          placeholder="ID_IN_THIS_FORMAT"
          label="Id"
          value={data.id}
          errorText={errors.id}
          onTextChange={(value) => onChangeHandler('id', value)}
          readOnly={isEdit}
          requiredColor={ColorsNew.mediumDarkRed}
          required
        />
        <TextArea
          id="copyText"
          classNames={[styles.width100]}
          placeholder="Enter copy text"
          value={data.copyText}
          onTextChange={(value) => onChangeHandler('copyText', value)}
          errorText={errors.copyText}
          fixedRows={4}
          label="Copy text"
          required
        />
        <Input
          id="description"
          classNames={[styles.width100]}
          placeholder="Description"
          label="Description"
          value={data.description}
          onTextChange={(value) => onChangeHandler('description', value)}
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(isEdit, data, copy, errors),
        submitButtonText: 'Save and Close',
      })}
    </BasePopup>
  );
};

CorrespondenceCopyPopup.propTypes = {
  onClose: PropTypes.func,
  copy: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default CorrespondenceCopyPopup;
