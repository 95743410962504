/* eslint-disable no-unused-expressions */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CircleLoader } from 'common-src/components/base';
import { apiRequest, getRestErrorMessage } from 'common-src/features/rest';
import { moduleTypePriority } from 'common-src/models/PatientInteraction';
import { getInitialTemplateValues } from 'common-src/models/Template';
import { ColorsNew } from 'common-src/styles';

import { saveNoteData, saveNoteScroll, savePatientNote } from 'src/features/tabsState';
import { clearTimer } from 'src/features/timeTracker';
import useDocumentationConfig from 'src/hooks/useDocumentationConfig';
import useDocumentationDynamicResult from 'src/hooks/useDocumentationDynamicResult';
import useDocumentationErrors from 'src/hooks/useDocumentationErrors';
import useForceSubmitNote from 'src/hooks/useForceSubmitNote';
import useNoteScroll from 'src/hooks/useNoteScroll';
import useSaveDocumentation from 'src/hooks/useSaveDocumentation';
import {
  DocumentForm,
  NoteHeader,
  SectionAside,
  SectionFooter,
} from 'src/pages/DocumentationIndex/components';
import { CommonModules } from 'src/pages/DocumentationIndex/modules';

import styles from '../commonStyles.module.scss';

const DocumentationForm = ({ patientId, documentationId, isRequestRunning }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClose = () => {
    navigate(`/dashboard-client/patients/details/${patientId}/documentation`);
    dispatch(savePatientNote(patientId, null));
    dispatch(saveNoteData(patientId, {}));
    dispatch(clearTimer(patientId));
    setTimeout(() => dispatch(saveNoteScroll(patientId, 0)), 100);
  };

  const { scrollRef, scrollLoading, onScrollHanlder } = useNoteScroll(patientId);
  const { errors, handleError } = useDocumentationErrors();
  const {
    patient,
    documentationToEdit,
    templates,
    setTemplates,
    primaryModuleTypeId,
    setPrimaryModuleTypeId,
    timeLogs,
    onTimeLogChange,
    serviceAt,
    setServiceAt,
    awscCallIds,
    setAwscCallIds,
    hasRelevantConversations,
    setHasRelevantConversations,
    isEncounterNotScheduled,
    setIsEncounterNotScheduled,
    linkedVisitId,
    setLinkedVisitId,
    sdoh,
    setSdoh,
    vitalsAndLabs,
    setVitalsAndLabs,
    wellnessPlan,
    setWellnessPlan,
    cgmAlerts,
    setCgmAlerts,
    cgmEvaluation,
    setCgmEvaluation,
    routineExams,
    setRoutineExams,
  } = useDocumentationConfig(patientId, documentationId);
  const {
    timer,
    savedAt,
    isDraftCompleting,
    isDraftSaving,
    hasDetectedChanges,
    setHasDetectedChanges,
    handleButtonClick,
  } = useSaveDocumentation({
    handleError,
    templates,
    documentationToEdit,
    patientId,
    primaryModuleTypeId,
    serviceAt,
    timeLogs,
    awscCallIds,
    hasRelevantConversations,
    isEncounterNotScheduled,
    onClose,
    isEmbedded: true,
    linkedVisitId,
    sdoh,
    vitalsAndLabs,
    wellnessPlan,
    cgmAlerts,
    cgmEvaluation,
    routineExams,
  });
  useDocumentationDynamicResult(templates, setTemplates, hasDetectedChanges);
  useForceSubmitNote(isRequestRunning);

  const updatePrimaryModule = (updatedTemplates, templateType) => {
    updatedTemplates.sort(
      (a, b) => moduleTypePriority[b.templateType] - moduleTypePriority[a.templateType],
    );

    const selectedTemplates = updatedTemplates.filter((t) => t.isSelected);
    const isTemplateSelected = selectedTemplates.find((t) => t.templateType === templateType);

    if (!primaryModuleTypeId && isTemplateSelected) {
      setPrimaryModuleTypeId(templateType);
    }

    if (!isTemplateSelected) {
      !_.isEmpty(selectedTemplates)
        ? setPrimaryModuleTypeId(selectedTemplates[0].templateType)
        : setPrimaryModuleTypeId(undefined);
    }

    setTemplates(updatedTemplates);
    setHasDetectedChanges(new Date());
  };

  const onTemplateSelect = (templateType) => {
    const isTemplateFetched = templates.find((template) => template.templateType === templateType);
    if (!isTemplateFetched) {
      apiRequest({
        endpoint: 'templates/buildDraft',
        queryParams: {
          templateTypes: [templateType],
          patientId,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const errMsg = getRestErrorMessage(data);
          if (errMsg) {
            handleError({ generalError: errMsg, fieldErrors: new Map() });
            return;
          }

          if (data.Draft?.[0]) {
            const updatedTemplates = [...templates, getInitialTemplateValues(data.Draft[0])];
            updatePrimaryModule(updatedTemplates, templateType);
          }
        });

      return;
    }

    const updatedTemplates = templates.map((t) => {
      if (t.templateType !== templateType) return t;

      return {
        ...t,
        isSelected: !t.isSelected,
        isExpanded: !t.isExpanded,
      };
    });
    updatePrimaryModule(updatedTemplates, templateType);
  };

  const renderContent = () => (
    <>
      <CircleLoader
        classNames={['absolute', scrollLoading ? 'visible' : 'invisible']}
        strokeWidth={4}
        circleRadius={24}
        color={ColorsNew.darkGreen}
        style={{ bottom: '50%', left: '50%' }}
      />
      <NoteHeader
        documentation={documentationToEdit}
        patient={patient}
        serviceAt={serviceAt}
        setServiceAt={setServiceAt}
        classNames={[scrollLoading ? 'invisible' : 'visible']}
      />
      <DocumentForm
        patientId={patientId}
        templates={templates}
        setTemplates={setTemplates}
        showPrimaryModuleCheckbox
        primaryModuleTypeId={primaryModuleTypeId}
        setPrimaryModuleTypeId={setPrimaryModuleTypeId}
        setHasDetectedChanges={setHasDetectedChanges}
        errors={errors}
        classNames={[scrollLoading ? 'invisible' : 'visible']}
        handleError={handleError}
        vitalsAndLabs={vitalsAndLabs}
        setVitalsAndLabs={setVitalsAndLabs}
        wellnessPlan={wellnessPlan}
        setWellnessPlan={setWellnessPlan}
        cgmAlerts={cgmAlerts}
        setCgmAlerts={setCgmAlerts}
        cgmEvaluation={cgmEvaluation}
        setCgmEvaluation={setCgmEvaluation}
        sdoh={sdoh}
        setSdoh={setSdoh}
        routineExams={routineExams}
        setRoutineExams={setRoutineExams}
        documentationToEdit={documentationToEdit}
      />
      <CommonModules
        templates={templates}
        patientId={patientId}
        timeLogs={timeLogs}
        onTimeLogChange={onTimeLogChange}
        errors={errors}
        hasRelevantConversations={hasRelevantConversations}
        setHasRelevantConversations={setHasRelevantConversations}
        awscCallIds={awscCallIds}
        setAwscCallIds={setAwscCallIds}
        classNames={[scrollLoading ? 'invisible' : 'visible']}
        isEncounterNotScheduled={isEncounterNotScheduled}
        setIsEncounterNotScheduled={setIsEncounterNotScheduled}
        linkedVisitId={linkedVisitId}
        setLinkedVisitId={setLinkedVisitId}
        documentationToEdit={documentationToEdit}
        setHasDetectedChanges={setHasDetectedChanges}
      />
    </>
  );

  return (
    <>
      <section className="flex-row">
        <SectionAside
          onTemplateSelect={(template) => onTemplateSelect(template)}
          patient={patient}
          templates={templates}
        />
        <div className={[styles.section, styles.isEmbedded].join(' ')}>
          <div ref={scrollRef} className={styles.formWrapper} onScroll={onScrollHanlder}>
            {renderContent()}
          </div>
        </div>
      </section>
      <SectionFooter
        isLoading={isDraftCompleting}
        isDraftSaving={isDraftSaving}
        draftSavedAt={savedAt}
        isEmbedded
        handleButtonClick={handleButtonClick}
        onClose={onClose}
        handleError={handleError}
        patientId={patientId}
        timer={timer}
      />
    </>
  );
};

DocumentationForm.propTypes = {
  patientId: PropTypes.number,
  documentationId: PropTypes.string,
  isRequestRunning: PropTypes.bool,
};

export default DocumentationForm;
