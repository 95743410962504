/* eslint-disable no-unused-expressions */
import * as Sentry from '@sentry/react';
import _ from 'lodash';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import { ormSelector, setOrm } from 'common-src/features/orm';
import orm, { ormModelClasses } from 'common-src/orm';

import { store } from 'src/store';

import { restErrored, restResponseReceived } from './actions';
import {
  errorActionTest,
  requestActionTest,
  requestMultipleActionTest,
  responseReceivedActionTest,
} from './actionTypes';
import { HttpMethods } from './constants';
import { apiRequest, getRestErrorMessage, RestApiError } from './helpers';

// Function returns a promise.
async function performRequest(requestAction) {
  const { payload } = requestAction;
  const { uri, body, query, method, errorBlock, successBlock } = payload;

  return apiRequest({ endpoint: uri, method, queryParams: query, body })
    .then((res) => res.json().then((body) => ({ res, body })))
    .then(({ res, body }) => {
      if (!res.ok) {
        let errorMessage = `Request ${method} to ${uri} failed with status ${res.status}`;
        let errorBody = body;

        // insufficient permissions case
        if (res?.status === 403) {
          errorMessage = 'Action not permitted for user role';
          errorBody = { detail: 'Action not permitted for user role' };
        }

        throw new RestApiError(errorMessage, errorBody, res.status);
      }

      store.dispatch(restResponseReceived(uri, method, body, successBlock));
      return body;
    })
    .catch((err) => {
      if (err.name !== 'AbortError') {
        store.dispatch(restErrored(uri, err.body, err.message, err.status, errorBlock));

        // Don't log an error in case of a session timed out while the user logs out
        // Don't log an error in case of insufficient permissions
        // Don't log an error in case of Internal Server error
        if (![401, 403, 500].includes(err.status)) {
          Sentry.captureException(err);
        }

        return err;
      }
    });
}

function* commitToRoot(formattedData, body, successBlock) {
  const rootOrm = yield select(ormSelector);
  const session = orm.session(rootOrm);

  formattedData.forEach((m) => {
    const modelClass = session[m.modelName];

    // save the new element with the exact model schema
    modelClass.upsert(_.pick(m.data, Object.keys(modelClass.fields)));
  });

  successBlock?.(body);

  if (_.isEqual(rootOrm, session.state)) {
    return;
  }

  yield put(setOrm(session.state));
}

function* handleResponse(dataReceived) {
  const { body, method, successBlock } = dataReceived.payload;
  const formattedData = [];

  Object.entries(body || {}).map(([modelName, modelData]) => {
    // Store only existing model names
    if (!ormModelClasses.map((m) => m.modelName).includes(modelName)) return;

    if (!Array.isArray(modelData)) {
      formattedData.push({
        modelName,
        data: modelData,
      });
      return;
    }

    return modelData.forEach((el) => {
      formattedData.push({
        modelName,
        data: el,
      });
    });
  });
  if (method === HttpMethods.Delete) {
    successBlock?.(body);
    return;
  }
  yield fork(commitToRoot, formattedData, body, successBlock);
}

function handleError(dataReceived) {
  const { payload, type } = dataReceived;
  const { errorBlock, body, errMsg, errStatus } = payload;
  errorBlock?.(getRestErrorMessage(body), errStatus);

  // Don't log an error in case of a session timed out while the user logs out
  // Don't log an error in case of insufficient permissions
  // Don't log an error in case of Internal Server error
  if (![401, 403, 500].includes(errStatus)) {
    Sentry.captureException(new RestApiError(`(errType: ${type}): ${errMsg}`));
  }
}

function* dispatchJoinedRequests(dataReceived) {
  const { payload } = dataReceived;
  const { restRequests, successBlock, errorBlock } = payload;
  const responses = yield all(restRequests.map((restRequest) => call(performRequest, restRequest)));
  // eslint-disable-next-line
  for (const response of responses) {
    if (response instanceof Error) {
      errorBlock?.(getRestErrorMessage(response));
      return;
    }
  }
  successBlock?.(responses);
}

function* restRequestWatcher() {
  yield takeEvery(requestActionTest('.*'), performRequest);
}

function* restProcessResponseWatcher() {
  yield takeEvery(responseReceivedActionTest('.*'), handleResponse);
}

function* restErrorWatcher() {
  yield takeEvery(errorActionTest('.*'), handleError);
}

function* restRequestMultipleWatcher() {
  yield takeEvery(requestMultipleActionTest('.*'), dispatchJoinedRequests);
}

export function* restWatcher() {
  yield all([
    fork(restRequestWatcher),
    fork(restProcessResponseWatcher),
    fork(restErrorWatcher),
    fork(restRequestMultipleWatcher),
  ]);
}
