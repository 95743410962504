import moment from 'moment-timezone';
import React from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { Button } from 'common-src/components/base';
import { deleteOrmItem } from 'common-src/features/rest';
import CorrespondenceCopy, {
  deleteCorrespondenceCopy,
  getCorrespondenceCopies,
} from 'common-src/models/CorrespondenceCopy';
import { Colors, ColorsNew } from 'common-src/styles';

import { ModalType } from 'src/components/base/ModalGroup';
import { ActionsRow, BaseTable, MultilineTextItem, TextItem } from 'src/components/table';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';

import { TableHeaders } from './constants';
import styles from './CorrespondenceCopyScreen.module.scss';

const CorrespondenceCopyScreen = () => {
  const dispatch = useDispatch();

  const correspondenceCopies = useCustomSelector(getCorrespondenceCopies);

  const getData = () =>
    correspondenceCopies.map((copy) => ({
      id: (
        <TextItem
          isActive
          text={copy.id}
          isUnderlined
          onClick={() => {
            dispatch(openModal(ModalType.CORRESPONDENCE_COPY, { copy, isEdit: true }));
          }}
        />
      ),
      copyText: <MultilineTextItem isActive text={copy.copyText || '-'} maxLines={8} />,
      updatedAt: (
        <TextItem
          isActive
          text={moment(copy.updatedAt).format('MM/DD/YYYY')}
          value={copy.updatedAt}
        />
      ),
      actions: (
        <ActionsRow
          options={[
            {
              id: 'edit-button',
              text: 'Edit',
              backgroundColor: '#555671B2',
              color: 'white',
              disabled: false,
              onClick: () => {
                dispatch(openModal(ModalType.CORRESPONDENCE_COPY, { copy, isEdit: true }));
              },
            },
            {
              id: 'delete-button',
              text: 'Delete',
              backgroundColor: '#F01F51B2',
              color: 'white',
              onClick: () => {
                dispatch(
                  deleteCorrespondenceCopy(copy.id, {
                    successBlock: () => {
                      deleteOrmItem(CorrespondenceCopy.modelName, copy.id);
                    },
                  }),
                );
              },
              showWarning: true,
            },
          ]}
        />
      ),
    }));

  return (
    <div>
      <Button
        id="add-button"
        text="Add Correspondence Copy"
        onClick={() => dispatch(openModal(ModalType.CORRESPONDENCE_COPY, { isEdit: false }))}
        backgroundColor={Colors.white}
        textColor={ColorsNew.darkGreen}
        iconSrc={CommonIcons.plusIcon}
        classNames={[styles.button]}
      />
      <BaseTable
        headers={TableHeaders}
        data={getData()}
        name="correspondence-copy"
        classNames={['medical-table-new']}
        emptyText="No data"
        emptyClassNames={['medical-table-empty']}
        initialSortColumn={{
          column: 'updatedAt',
          label: 'Updated',
          width: '10%',
          sortBy: 'value',
        }}
      />
    </div>
  );
};

export default CorrespondenceCopyScreen;
