import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientLabOrder extends Model {
  static modelName = 'PatientLabOrder';

  static fields = {
    id: attr(),
    labType: attr(),
    status: attr(),
    location: attr(),
    dateOrdered: attr(),
    dateResulted: attr(),
    dateCanceled: attr(),
    cancelReason: attr(),
    resultRange: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'patientLabOrderCreator'),
    updatedBy: fk('Client', 'patientLabOrderChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientLabOrders',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      labType: PropTypes.string,
      status: PropTypes.string,
      location: PropTypes.string,
      dateOrdered: PropTypes.string,
      dateResulted: PropTypes.string,
      dateCanceled: PropTypes.string,
      cancelReason: PropTypes.string,
      resultRange: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      createdBy: PropTypes.number,
      updatedBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientLabOrder;
