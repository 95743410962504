import { ScoreType } from './constants';

export const getPatientScoreResult = (patientScore) => {
  let result = '-';

  if (typeof patientScore.boolResult === 'boolean') {
    result = patientScore.boolResult ? 'Yes' : 'No';
  }

  if (typeof patientScore.intResult === 'number') {
    result = `${patientScore.intResult}`;

    if (patientScore.maxIntResult) {
      result += `/${patientScore.maxIntResult}`;
    }
  }

  return result;
};

export const getPatientScoreType = (scoreType) =>
  Object.values(ScoreType).find((ps) => ps.value === scoreType)?.label ?? '';
