import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Button } from 'common-src/components/base';
import Config from 'common-src/config';
import { apiRequest, HttpMethods } from 'common-src/features/rest';
import { getPrimaryPatientPhoneNumber } from 'common-src/models/PatientPhoneNumber';

import { InfoItem } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useOpenWindow from 'src/hooks/useOpenWindow';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './VideoCallInformationPopup.module.scss';

const VideoCallInformationPopup = ({ patientId, patientToken, clientToken, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const primaryPhoneNumber = useCustomSelector((state) =>
    getPrimaryPatientPhoneNumber(state, patientId),
  );

  const patientLink = `https://${Config.VIDEO_CALL_URL}/${patientToken}`;
  const clientLink = `https://${Config.VIDEO_CALL_URL}/${clientToken}`;
  const patientMessage = `Please, join the video call with Perry Health by opening the link: ${patientLink}`;

  const { renderButtons } = usePopup();
  const { showAlert, AlertType } = useAlert();
  const { openWindow } = useOpenWindow();

  const copyLinkHandler = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      showAlert(AlertType.Success, '', 'Link copied to clipboard.');
    });
  };

  const openInBrowserHandler = () => {
    openWindow({
      url: clientLink,
      target: '_blank',
    });
  };

  const sendToMemberHandler = () => {
    setIsLoading(true);
    apiRequest({
      endpoint: 'awscChatMessage',
      method: HttpMethods.Post,
      body: {
        patientId,
        outboundPhoneNumber: primaryPhoneNumber?.phoneNumber,
        message: patientMessage,
      },
    })
      .then(async (res) => {
        if (res.status !== 200) {
          const errorBody = await res.json();
          throw new Error(
            errorBody?.user_message ?? errorBody?.detail ?? 'Error while sending SMS.',
          );
        }
        return res.json();
      })
      .then(() => showAlert(AlertType.Success, '', 'Successfully sent link to member'))
      .catch((error) => {
        showAlert(AlertType.Error, '', error.message);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <BasePopup
      id="video-call-information"
      open
      onClose={onClose}
      title="Video Call Information"
      customStyle={{ minWidth: '40vw' }}
    >
      <div className={styles.content}>
        <div className={[styles.row, 'vertically-centered', 'gap-16', 'm-b-16'].join(' ')}>
          <InfoItem
            classNames={['flex-1']}
            title="Link to join the video call"
            content={clientLink}
            textId="client-video-call-link"
          />
          <div className={['flex-row', 'gap-8'].join(' ')}>
            <Button
              id="copy-client-link-button"
              classNames={[styles.button]}
              text="Copy link"
              onClick={() => copyLinkHandler(clientLink)}
            />
            <Button
              id="open-in-browser-button"
              classNames={[styles.button]}
              text="Open in browser"
              onClick={openInBrowserHandler}
            />
          </div>
        </div>
        <div className={[styles.row, 'vertically-centered', 'gap-16'].join(' ')}>
          <InfoItem
            classNames={['flex-1']}
            title="Link for member to join the video call"
            content={patientLink}
            textId="video-call-link"
          />
          <div className={['flex-row', 'gap-8'].join(' ')}>
            <Button
              id="copy-member-link-button"
              classNames={[styles.button]}
              text="Copy link"
              onClick={() => copyLinkHandler(patientLink)}
            />
            <Button
              id="send-to-member-button"
              classNames={[styles.button]}
              text="Send to member"
              onClick={sendToMemberHandler}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
      })}
    </BasePopup>
  );
};

VideoCallInformationPopup.propTypes = {
  patientId: PropTypes.number,
  patientToken: PropTypes.string,
  clientToken: PropTypes.string,
  onClose: PropTypes.func,
};

export default VideoCallInformationPopup;
