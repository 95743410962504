import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { DatePickerNew, Input, Select } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { getPatientCoverages, PlanStatus } from 'common-src/models/PatientCoverage';
import PreAuthorization, {
  addPreAuth,
  PreAuthServiceType,
  PreAuthStatus,
  updatePreAuth,
  validatePreAuthorization,
} from 'common-src/models/PreAuthorization';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import {
  catchErrors,
  extractPreAuthorization,
  getIsButtonEnabled,
  getRequestBody,
} from './helpers';
import styles from './PreAuthPopup.module.scss';

const PreAuthPopup = ({ patientId, auth, onClose, open }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const coverages = useCustomSelector((state) => getPatientCoverages(state, patientId));
  const coverageOptions = useMemo(
    () =>
      coverages
        .filter((c) =>
          [PlanStatus.Verified.value, PlanStatus.NeedsVerification.value].includes(c?.status),
        )
        .map((c) => ({
          value: c.id,
          label: c.name,
        })),
    [coverages],
  );

  const { errors, setErrors, autoFocus, setAutoFocus, renderButtons } = usePopup();

  useEffect(() => {
    if (!auth) return;
    setData(extractPreAuthorization(auth));
  }, [auth]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    const requests = [];
    requests.push(
      auth?.id
        ? updatePreAuth(auth.id, getRequestBody(data, auth.id))
        : addPreAuth(getRequestBody(data)),
    );

    loadingCallback();
    dispatch(
      restRequestMultiple({
        restRequests: requests,
        successBlock: () => {
          successCallback(`Pre authorization ${auth?.id ? 'edited' : 'added'}!`);
          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onClearHandler = () => {
    setErrors({});
    setData(auth ? extractPreAuthorization(auth) : initialState);
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validatePreAuthorization(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const onDateChangeHandler = (key, keyToCompare, isBefore, value) => {
    const newData = { ...data, [key]: value };
    const err = { ...validatePreAuthorization(key, value, newData) };

    const compareCondition = isBefore
      ? moment(value).isAfter(moment(newData?.[keyToCompare]))
      : moment(value).isBefore(moment(newData?.[keyToCompare]));

    if (!compareCondition) {
      if (newData[key]) {
        Object.assign(err, { ...validatePreAuthorization(key, value, newData) });
      }
      if (newData[keyToCompare]) {
        Object.assign(err, {
          ...validatePreAuthorization(keyToCompare, newData?.[keyToCompare], newData),
        });
      }
    }

    setErrors((prev) => ({ ...prev, ...err }));
    setData((prev) => ({ ...prev, [key]: value }));
  };

  const onStatusChangeHandler = (option) => {
    setErrors((prev) => ({ ...prev, ...validatePreAuthorization('status', option) }));
    setData((prev) => ({
      ...prev,
      status: option,
      endDate: [
        PreAuthStatus.Canceled.value,
        PreAuthStatus.Expired.value,
        PreAuthStatus.Denied.value,
      ].includes(option.value)
        ? moment().format('YYYY-MM-DD')
        : null,
    }));
  };

  return (
    <BasePopup
      id="pre-authorization"
      open={open}
      onClose={onClose}
      title={`${auth ? 'Edit' : 'Add'} Auth`}
    >
      <div className={styles.grid}>
        <Select
          id="plan-name"
          classNames={[styles.width75]}
          label="Plan Name"
          options={coverageOptions}
          onChange={(coverage) => onChangeHandler('patientCoverage', coverage)}
          value={data.patientCoverage}
          required
          placeholder="Select plan"
          errorText={errors.patientCoverage}
          isSearchable
          readOnly={!!auth?.id}
        />
        <Input
          id="auth-code"
          placeholder="Enter auth code"
          label="Pre Auth Code"
          required
          value={data.authCode}
          errorText={errors.authCode}
          onTextChange={(value) => onChangeHandler('authCode', value)}
          autoFocus={autoFocus}
          onBlur={() => setAutoFocus(false)}
          readOnly={!!auth?.id}
        />
        <DatePickerNew
          id="start-date"
          header="Start Date"
          value={data.startDate}
          errorText={errors.startDate}
          onDateSelected={(date) =>
            onDateChangeHandler(
              'startDate',
              'endDate',
              true,
              date ? moment(date).format('YYYY-MM-DD') : null,
            )
          }
          onBlur={(date) =>
            setErrors((prev) => ({ ...prev, ...validatePreAuthorization('startDate', date, data) }))
          }
          required
          readOnly={!!auth?.id}
        />
        <DatePickerNew
          id="end-date"
          header="End Date"
          value={data.endDate}
          errorText={errors.endDate}
          onDateSelected={(date) =>
            onDateChangeHandler(
              'endDate',
              'startDate',
              false,
              date ? moment(date).format('YYYY-MM-DD') : null,
            )
          }
          onBlur={(date) =>
            setErrors((prev) => ({ ...prev, ...validatePreAuthorization('endDate', date, data) }))
          }
        />
        <Select
          id="status"
          label="Status"
          options={Object.values(PreAuthStatus)}
          onChange={(op) => onStatusChangeHandler(op)}
          value={data.status}
          required
          placeholder="Select status"
          errorText={errors.status}
        />
        <Select
          id="service-type"
          label="Service Type"
          options={Object.values(PreAuthServiceType).sort((a, b) => a.sortOrder - b.sortOrder)}
          onChange={(op) => onChangeHandler('serviceType', op)}
          value={data.serviceType}
          required
          placeholder="Select service type"
          errorText={errors.serviceType}
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors, auth),
        submitButtonText: 'Save and Close',
        onClear: onClearHandler,
      })}
    </BasePopup>
  );
};

PreAuthPopup.propTypes = {
  patientId: PropTypes.number,
  auth: PropTypes.exact(PreAuthorization.schema),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default PreAuthPopup;
