export const ScoreType = Object.freeze({
  Eligibility: {
    value: 'Eligibility',
    label: 'Eligibility',
  },
  MemoryImpairmentScreen: {
    value: 'MemoryImpairmentScreen',
    label: 'Memory Impairment Screen',
  },
  MoCABlind: {
    value: 'MoCABlind',
    label: 'MoCA-Blind',
  },
  CognitiveClinicalInterview: {
    value: 'CognitiveClinicalInterview',
    label: 'Risk Level',
  },
});
