import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew, Select } from 'common-src/components/base';
import {
  catchRoutineExamErrors,
  ExamDetailOptions,
  ExamStatus,
  ExamStatusOptions,
  ExamType,
  ExamTypeOptions,
  getInitialRoutineExamData,
  getPatientRoutineExams,
  validatePatientRoutineExam,
} from 'common-src/models/PatientRoutineExam';
import { ColorsNew } from 'common-src/styles';

import { AddButton, RemoveButton } from 'src/components/buttons';
import { SeparatorLine } from 'src/components/elements';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './RoutineExams.module.scss';

const RoutineExams = ({
  patientId,
  isEditMode,
  formData,
  setFormData,
  errors,
  setErrors,
  isEmbedded,
  addButtonHandler = () => {},
}) => {
  const routineExams = useCustomSelector((state) => getPatientRoutineExams(state, patientId));

  const onChange = (field, value, data) => {
    const validationField = field.split('.')[1];
    const err = validatePatientRoutineExam(validationField, value, data);

    setErrors((prev) => ({ ...prev, [field]: err[validationField] }));
    setFormData((prev) => {
      const newFormData = _.cloneDeep(prev);
      _.set(newFormData, field, value);
      return newFormData;
    });
  };

  const onDateChange = (field, date, data) => {
    const dateToApply = date ? moment(date).format('YYYY-MM-DD') : null;
    onChange(field, dateToApply, data);
  };

  const renderAddButton = () => {
    if (!isEditMode) return null;

    return (
      <AddButton
        id="add-routine-exam"
        classNames={[styles.button]}
        label="Add exam"
        onClick={() => {
          setFormData((prev) => [...prev, getInitialRoutineExamData(patientId)]);
          addButtonHandler();
        }}
      />
    );
  };

  const renderRemoveButton = (routineExam) => {
    if (!isEditMode) return <div />;

    return (
      <RemoveButton
        id="remove-routine-exam"
        onClick={() => {
          const { errors } = catchRoutineExamErrors(_.without(formData, routineExam));

          setErrors(errors);
          setFormData((prev) => _.without(prev, routineExam));
        }}
      />
    );
  };

  const renderCreatedAt = (routineExam, index) => {
    const existingRoutineExam = routineExams.find((exam) => exam.id === routineExam.id);
    const createdAt = moment(existingRoutineExam?.createdAt).format('MM/DD/YYYY');

    return (
      <DatePickerNew
        containerId={`${index}.createdAt`}
        id={`routine-date-created-${index}`}
        header="Date Created"
        value={createdAt}
        iconSrc={CommonIcons.calendarCheckBoldIcon}
        size="small"
        readOnly={!isEditMode}
        disabled={isEditMode}
      />
    );
  };

  return (
    <div style={{ width: isEmbedded ? '70%' : '100%' }}>
      {formData.map((routineExam, index) => (
        <div key={`routine-exam-${index}`}>
          <div className={styles.grid}>
            <Select
              containerId={`${index}.type`}
              id={`routine-exam-type-${index}`}
              placeholder="Select Exam/Screening"
              options={ExamTypeOptions}
              value={ExamTypeOptions.find((op) => op.value === routineExam.type)}
              label="Exam/Screening"
              required
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.type`, option.value, routineExam);

                if (option.value !== ExamType.Kidney) {
                  onChange(`${index}.detail`, null, routineExam);
                  setErrors((prev) => ({ ...prev, [`${index}.detail`]: '' }));
                }
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.type`]}
              requiredColor={isEmbedded ? ColorsNew.mediumDarkRed : ColorsNew.mediumDarkGreen}
            />
            <Select
              containerId={`${index}.detail`}
              id={`routine-exam-detail-${index}`}
              placeholder="Select Exam Detail"
              options={ExamDetailOptions}
              value={ExamDetailOptions.find((op) => op.value === routineExam.detail)}
              label="Exam Detail"
              required={routineExam.type === ExamType.Kidney}
              readOnly={!isEditMode}
              onChange={(option) => onChange(`${index}.detail`, option.value, routineExam)}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.detail`]}
              requiredColor={isEmbedded ? ColorsNew.mediumDarkRed : ColorsNew.mediumDarkGreen}
              disabled={isEditMode && routineExam.type !== ExamType.Kidney}
            />
            <Select
              containerId={`${index}.status`}
              id={`routine-exam-status-${index}`}
              placeholder="Select Status"
              options={ExamStatusOptions}
              value={ExamStatusOptions.find((op) => op.value === routineExam.status)}
              label="Status"
              required
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.status`, option.value, routineExam);

                if (option.value !== ExamStatus.Yes) {
                  onChange(`${index}.dateCompleted`, null, routineExam);
                  setErrors((prev) => ({ ...prev, [`${index}.dateCompleted`]: '' }));
                }
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.status`]}
              requiredColor={isEmbedded ? ColorsNew.mediumDarkRed : ColorsNew.mediumDarkGreen}
            />
            <DatePickerNew
              containerId={`${index}.dateCompleted`}
              id={`routine-exam-date-${index}`}
              header="Date Completed"
              placeholder="MM/YYYY"
              maxDate={new Date()}
              value={routineExam.dateCompleted}
              iconSrc={CommonIcons.calendarCheckBoldIcon}
              onDateSelected={(date) => onDateChange(`${index}.dateCompleted`, date, routineExam)}
              onBlur={(date) => onDateChange(`${index}.dateCompleted`, date, routineExam)}
              errorText={errors[`${index}.dateCompleted`]}
              size="small"
              isMonthly
              required
              readOnly={!isEditMode}
              requiredColor={isEmbedded ? ColorsNew.mediumDarkRed : ColorsNew.mediumDarkGreen}
              disabled={isEditMode && routineExam.status !== ExamStatus.Yes}
            />
            {renderCreatedAt(routineExam, index)}
            {renderRemoveButton(routineExam)}
          </div>
          <SeparatorLine classNames={['m-b-20']} />
        </div>
      ))}
      <div className={styles.grid}>{renderAddButton()}</div>
    </div>
  );
};

RoutineExams.propTypes = {
  patientId: PropTypes.number,
  isEditMode: PropTypes.bool,
  formData: PropTypes.array,
  setFormData: PropTypes.func,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
  isEmbedded: PropTypes.bool,
  addButtonHandler: PropTypes.func,
};

export default RoutineExams;
