import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import { Badge, Menu } from 'common-src/components/base';
import { Colors } from 'common-src/styles';

import styles from './ColumnFilter.module.scss';

const ColumnFilter = ({ domain, options, selectedItemsCount, onClearSelection }) => (
  <div id={`${domain}-filter`} className={styles.container}>
    <div className={styles.type}>
      <Menu
        classNames={['m-l-8']}
        button={
          <img
            id={`${domain}-filter-type`}
            src={
              selectedItemsCount === 0
                ? CommonIcons.tableFilterUnselected
                : CommonIcons.tableFilterSelected
            }
            alt="filter-icon"
          />
        }
        options={options}
        multiple
        onClearSelection={onClearSelection}
      />
      <Badge
        id={`${domain}-badge-counter`}
        classNames={[styles.badge]}
        count={selectedItemsCount}
        backgroundColor={Colors.badgeRed}
        showZero={false}
        height={18}
      />
    </div>
  </div>
);

ColumnFilter.propTypes = {
  domain: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      text: PropTypes.string,
      isChecked: PropTypes.bool,
      onClick: PropTypes.func,
    }),
  ),
  selectedItemsCount: PropTypes.number,
  onClearSelection: PropTypes.func,
};

export default ColumnFilter;
