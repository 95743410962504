import _ from 'lodash';

import { validatePatientRoutineExam } from './validators';

export const getInitialRoutineExamData = (patientId) => ({
  patientId,
  type: null,
  status: null,
  detail: null,
  dateCompleted: null,
});

export const extractRoutineExam = (routineExam) => ({
  id: routineExam.id,
  type: routineExam.type,
  status: routineExam.status,
  detail: routineExam.detail,
  dateCompleted: routineExam.dateCompleted,
  patientId: routineExam.patientId,
});

export const catchRoutineExamErrors = (formData) => {
  const errors = {};
  formData.forEach((exam, i) => {
    if (!exam.patientId) return;
    Object.entries(exam).forEach(([field, value]) => {
      const err = validatePatientRoutineExam(field, value, exam);
      if (err[field]) {
        Object.assign(errors, { [`${i}.${field}`]: err[field] });
      }
    });
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getRoutineExamsModuleData = (formData, exams) => {
  const requests = {
    creations: [],
    updates: [],
    deletions: [],
  };

  if (!Array.isArray(exams) || !Array.isArray(formData)) return requests;

  const oldExams = exams.map((exam) => extractRoutineExam(exam));

  if (_.isEqual(formData, oldExams)) {
    return requests;
  }

  formData.forEach((exam) => {
    const examExists = oldExams.find(({ id }) => id === exam.id);

    if (!examExists) {
      requests.creations.push(exam);
      return;
    }

    if (!_.isEqual(exam, examExists)) {
      if (exam.dateCompleted !== examExists.dateCompleted) {
        requests.creations.push(_.omit(exam, ['id']));
      } else {
        requests.updates.push(exam);
      }
    }
  });

  oldExams.forEach(({ id }) => {
    const examExists = formData.find((exam) => exam.id === id);

    if (!examExists) {
      requests.deletions.push(id);
    }
  });

  return requests;
};
