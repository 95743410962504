import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { getRoleConfig } from 'common-src/features/auth';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';

import { ModalType } from 'src/components/base/ModalGroup';
import { CLIENT_ROOT } from 'src/constants/paths';
import { openModal, resetModalState } from 'src/features/modals';
import {
  closePatientDetails,
  getActiveTabs,
  getPatientsPaths,
  getSettingsPath,
  resetDataOnClose,
  resetTabInfo,
  resetTabsInfo,
  setActiveTabs,
} from 'src/features/tabsState';
import { clearTimer } from 'src/features/timeTracker';
import {
  getAllowedTabs,
  getPathByLabel,
  getTab,
  getTabStateByLabel,
} from 'src/pages/Dashboard/helpers';

import useCustomSelector from './useCustomSelector';

const TAB_LIMIT = 5;

const useTabs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const patientsPaths = useCustomSelector((state) => getPatientsPaths(state));
  const settingsPath = useCustomSelector((state) => getSettingsPath(state));
  const tabs = useCustomSelector((state) => getActiveTabs(state)) || [];
  const permissions = useCustomSelector(getRoleConfig);
  const isPromptTemplatesEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'promptTemplates'),
  );

  const allowedTabs = getAllowedTabs(permissions);

  const getAllowedActiveTabs = (tabs) =>
    tabs.filter((tab) => allowedTabs.some((x) => tab.startsWith(x)));

  const resetTabsSession = (tabs) => {
    const currentTab = getTab(location?.pathname, isPromptTemplatesEnabled).getLabel();
    const payload = {};

    tabs.forEach((tab) => {
      const tabState = getTabStateByLabel(tab, isPromptTemplatesEnabled);
      Object.assign(payload, {
        [tabState.name]: { ...tabState.initialState, shouldResetData: !!currentTab },
      });
    });

    dispatch(resetTabsInfo(payload));
  };

  const showTabLimitPopup = (requestedTab, path) =>
    dispatch(
      openModal(ModalType.MAX_TAB_LIMIT, {
        requestedTab,
        onSubmit: (selectedTabs, closedTabs) => {
          dispatch(
            setActiveTabs(getAllowedActiveTabs([..._.xor(tabs, closedTabs), selectedTabs.pop()])),
          );
          resetTabsSession(closedTabs);
          dispatch(resetModalState());
          navigate(
            path ||
              getPathByLabel(requestedTab, patientsPaths, settingsPath, isPromptTemplatesEnabled),
          );

          closedTabs.forEach((tab) => {
            if (tab.startsWith('Member Details')) {
              const patientId = Number(tab.split(' ')?.[2]);
              dispatch(closePatientDetails(patientId));
              dispatch(clearTimer(patientId));
            }
          });
        },
        tabs,
      }),
    );

  const onSelectTab = ({ path, label }) => {
    const generatedPath = label
      ? getPathByLabel(label, patientsPaths, settingsPath, isPromptTemplatesEnabled)
      : path;
    const tabLabel = label || getTab(generatedPath, isPromptTemplatesEnabled).getLabel();

    if (!tabs.includes(tabLabel) && tabs.length === TAB_LIMIT) {
      showTabLimitPopup(tabLabel, path);
      return;
    }

    navigate(generatedPath);
    dispatch(setActiveTabs(getAllowedActiveTabs(_.uniq([...tabs, tabLabel]))));
  };

  const onCloseTab = (closedTab) => {
    const activeTabs = getAllowedActiveTabs(tabs.filter((tab) => tab !== closedTab));
    dispatch(setActiveTabs(activeTabs));

    const tabState = getTabStateByLabel(closedTab, isPromptTemplatesEnabled);
    if (!_.isEmpty(tabState)) {
      const currentTab = getTab(location?.pathname, isPromptTemplatesEnabled).getLabel();
      if (closedTab === currentTab) {
        dispatch(resetDataOnClose(tabState));
      } else {
        dispatch(resetTabInfo(tabState));
      }
    }

    if (closedTab.startsWith('Member Details')) {
      const patientId = Number(closedTab.split(' ')?.[2]);
      dispatch(closePatientDetails(patientId));
      dispatch(clearTimer(patientId));
    }

    if (!_.isEmpty(activeTabs)) {
      const lastActiveTab = [...activeTabs].pop();
      const path = getPathByLabel(
        lastActiveTab,
        patientsPaths,
        settingsPath,
        isPromptTemplatesEnabled,
      );
      navigate(path);
    }

    if (_.isEmpty(activeTabs)) {
      navigate(CLIENT_ROOT);
    }
  };

  return {
    tabs,
    onCloseTab,
    onSelectTab,
  };
};

export default useTabs;
