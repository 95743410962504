import { ALL_TASK_TYPE_REASONS, ALL_TASK_TYPES } from 'common-src/models/PatientTask';

import Icons from 'src/assets/Icons';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { CLIENT_ROOT } from 'src/constants/paths';

// Tasks
export const TasksTabs = Object.freeze({
  NEW: {
    text: 'New',
  },
  IN_PROGRESS: {
    text: 'In Progress',
  },
  SCHEDULED: {
    text: 'Scheduled',
  },
  RESOLVED: {
    text: 'Resolved',
  },
  ALL_STATUSES: {
    text: 'All Statuses',
  },
});

export const TaskAssignee = Object.freeze({
  MY_TASKS: {
    value: 1,
    label: 'My Tasks',
  },
  ALL_TASKS: {
    value: 2,
    label: 'All Tasks',
  },
});

export const DefaultDashboardFilters = Object.freeze({
  tab: Object.keys(TasksTabs)[0],
  type: ALL_TASK_TYPES,
  reason: ALL_TASK_TYPE_REASONS,
  taskAssignee: Object.values(TaskAssignee)[0],
  from: 0,
  to: ResultsCount[25],
});

// Common
export const TabState = Object.freeze({
  PATIENT_DETAILS: {
    name: 'patientDetails',
    initialState: {},
  },
  SETTINGS: {
    name: 'settings',
    initialState: {
      lastPath: `${CLIENT_ROOT}/settings`,
      shouldResetData: false,
    },
  },
  MANAGE_CLIENTS: {},
  MANAGE_PROMPT_TEMPLATES: {},
  DASHBOARD: {
    name: 'dashboard',
    initialState: {
      filters: DefaultDashboardFilters,
    },
  },
});

export const PatientTabs = Object.freeze({
  Chart: {
    text: 'Chart',
    showCloseButton: false,
    showSideNav: true,
    showAlways: true,
    routes: [
      'medications',
      'medical-history',
      'allergies',
      'family-history',
      'social-history',
      'supplements',
      'encounters',
      'surgical-history',
      'vitals',
      'smart-goals',
      'documentation',
      'lab-results',
      'alerts',
      'conditions',
      'team',
      'sdoh',
      'tasks',
      'cognitive',
    ],
  },
  Profile: {
    text: 'Profile',
    showCloseButton: false,
    showSideNav: true,
    showAlways: true,
    routes: [
      'info',
      'contacts',
      'devices',
      'cgmOrders',
      'known-plans',
      'payor-priority',
      'pre-auths',
      'exemptions',
    ],
  },
  Biometrics: {
    text: 'Biometrics',
    showCloseButton: false,
    showSideNav: false,
    showAlways: true,
    routes: ['glucose-readings'],
  },
  Visits: {
    text: 'Visits',
    showCloseButton: false,
    showSideNav: true,
    showAlways: true,
    routes: ['scheduled-calls', 'conversation-history'],
  },
  Note: {
    text: 'Note',
    showCloseButton: true,
    showSideNav: false,
    showAlways: false,
    routes: [],
  },
});

export const NavigationData = Object.freeze({
  1: {
    routes: ['info', 'contacts'],
    label: 'Info',
    mainTab: PatientTabs.Profile.text,
    title: 'Member Info',
    iconAlt: 'member-icon',
    iconSrc: Icons.memberIcon,
    permissionFields: ['memberInformation', 'contacts'],
    isEligibleForActionItems: false,
    items: [
      {
        name: 'Profile',
        route: 'info',
        permissionField: 'memberInformation',
        isEligibleForActionItems: false,
      },
      {
        name: 'Contacts',
        route: 'contacts',
        permissionField: 'contacts',
        isEligibleForActionItems: false,
      },
    ],
  },
  2: {
    routes: [
      'medications',
      'medical-history',
      'allergies',
      'family-history',
      'social-history',
      'supplements',
      'encounters',
      'surgical-history',
      'conditions',
    ],
    label: 'Medical',
    mainTab: PatientTabs.Chart.text,
    title: 'Medical',
    iconAlt: 'medical-icon',
    iconSrc: Icons.medicalIcon,
    permissionFields: [
      'medicationsList',
      'allergyList',
      'medicalHistory',
      'familyHistory',
      'socialHistory',
      'supplementsList',
      'encountersList',
      'surgicalHistory',
    ],
    isEligibleForActionItems: true,
    items: [
      {
        name: 'Medications',
        route: 'medications',
        permissionField: 'medicationsList',
        isEligibleForActionItems: true,
      },
      {
        name: 'Allergies',
        route: 'allergies',
        permissionField: 'allergyList',
        isEligibleForActionItems: false,
      },
      {
        name: 'Conditions',
        route: 'conditions',
        permissionField: 'medicalHistory',
        isEligibleForActionItems: false,
      },
      {
        name: 'Family',
        route: 'family-history',
        permissionField: 'familyHistory',
        isEligibleForActionItems: false,
      },
      {
        name: 'Social',
        route: 'social-history',
        permissionField: 'socialHistory',
        isEligibleForActionItems: false,
      },
      {
        name: 'Supplements',
        route: 'supplements',
        permissionField: 'supplementsList',
        isEligibleForActionItems: false,
      },
      {
        name: 'Encounters',
        route: 'encounters',
        permissionField: 'encountersList',
        isEligibleForActionItems: false,
      },
      {
        name: 'Surgeries',
        route: 'surgical-history',
        permissionField: 'surgicalHistory',
        isEligibleForActionItems: false,
      },
    ],
  },
  3: {
    routes: ['glucose-readings'],
    label: 'Readings',
    mainTab: PatientTabs.Biometrics.text,
    title: 'Readings',
    iconAlt: 'glucose-icon',
    iconSrc: Icons.glucoseIcon,
    permissionFields: ['glucoseReadings'],
    isEligibleForActionItems: false,
  },
  4: {
    routes: ['vitals'],
    label: 'Vitals',
    mainTab: PatientTabs.Chart.text,
    title: 'Vitals',
    iconAlt: 'vitals-icon',
    iconSrc: Icons.vitalsIcon,
    permissionFields: ['vitalsAndLabResults'],
    isEligibleForActionItems: false,
  },
  5: {
    routes: ['lab-results'],
    label: 'Labs',
    mainTab: PatientTabs.Chart.text,
    title: 'Vitals and Lab Results',
    iconAlt: 'lab-results-icon',
    iconSrc: Icons.labsIcon,
    permissionFields: ['vitalsAndLabResults'],
    isEligibleForActionItems: false,
  },
  6: {
    routes: ['smart-goals'],
    label: 'Goals',
    mainTab: PatientTabs.Chart.text,
    title: 'Smart Goals',
    iconAlt: 'smart-goals-icon',
    iconSrc: Icons.smartIcon,
    permissionFields: ['smartGoals'],
    isEligibleForActionItems: false,
  },
  7: {
    routes: ['conversation-history'],
    label: 'Timeline',
    title: 'Timeline',
    iconAlt: 'timeline-icon',
    iconSrc: Icons.timeLineIcon,
    permissionFields: ['conversationHistory'],
    isEligibleForActionItems: false,
  },
  8: {
    routes: ['scheduled-calls'],
    label: 'Visits',
    mainTab: PatientTabs.Visits.text,
    title: 'Scheduled Visits',
    iconAlt: 'scheduled-calls-icon',
    iconSrc: Icons.calendarCheckIcon,
    permissionFields: ['scheduledCalls'],
    isEligibleForActionItems: false,
  },
  9: {
    routes: ['documentation'],
    label: 'Notes',
    mainTab: PatientTabs.Chart.text,
    title: 'Notes',
    iconAlt: 'documentation-icon',
    iconSrc: Icons.docIcon,
    permissionFields: ['documentation'],
    isEligibleForActionItems: false,
  },
  10: {
    routes: ['known-plans', 'payor-priority', 'pre-auths', 'exemptions'],
    label: 'Billing',
    mainTab: PatientTabs.Profile.text,
    title: 'Billing',
    iconAlt: 'billing-icon',
    iconSrc: Icons.billingIcon,
    permissionFields: ['knownPlans', 'payorPriority', 'preAuths', 'exemptions'],
    isEligibleForActionItems: false,
    items: [
      {
        name: 'Known Plans',
        route: 'known-plans',
        permissionField: 'knownPlans',
        isEligibleForActionItems: false,
      },
      {
        name: 'Payor Priority',
        route: 'payor-priority',
        permissionField: 'payorPriority',
        isEligibleForActionItems: false,
      },
      {
        name: 'Pre Auths',
        route: 'pre-auths',
        permissionField: 'preAuths',
        isEligibleForActionItems: false,
      },
      {
        name: 'Exemptions',
        route: 'exemptions',
        permissionField: 'exemptions',
        isEligibleForActionItems: false,
      },
    ],
  },
  11: {
    routes: ['sdoh'],
    label: 'SDOH',
    mainTab: PatientTabs.Chart.text,
    title: 'SDOH',
    iconAlt: 'sdoh-icon',
    iconSrc: Icons.sdohIcon,
    permissionFields: ['sdohList'],
    isEligibleForActionItems: false,
  },
  12: {
    routes: ['alerts'],
    label: 'Orders',
    mainTab: PatientTabs.Chart.text,
    title: 'Diagnosis & Orders',
    iconAlt: 'wellness-icon',
    iconSrc: Icons.wellnessIcon,
    permissionFields: ['physicianOrders'],
    isEligibleForActionItems: false,
  },
  13: {
    routes: ['devices', 'cgmOrders'],
    label: 'Devices',
    mainTab: PatientTabs.Profile.text,
    title: 'Devices',
    iconAlt: 'devices-icon',
    iconSrc: Icons.devicesIcon,
    permissionFields: ['devicesAndOrders', 'cgmOrders'],
    isEligibleForActionItems: false,
    items: [
      {
        name: 'BGM',
        route: 'devices',
        permissionField: 'devicesAndOrders',
        isEligibleForActionItems: false,
      },
      {
        name: 'CGM',
        route: 'cgmOrders',
        permissionField: 'cgmOrders',
        isEligibleForActionItems: false,
      },
    ],
  },
  14: {
    routes: ['conversation-history'],
    label: 'Timeline',
    mainTab: PatientTabs.Visits.text,
    title: 'Timeline',
    iconAlt: 'timeline-icon',
    iconSrc: Icons.timeLineIcon,
    permissionFields: ['conversationHistory'],
    isEligibleForActionItems: false,
  },
  15: {
    routes: ['team'],
    label: 'Team',
    mainTab: PatientTabs.Chart.text,
    title: 'Team',
    iconAlt: 'team-icon',
    iconSrc: Icons.teamIcon,
    permissionFields: ['team'],
    isEligibleForActionItems: false,
  },
  16: {
    routes: ['tasks'],
    label: 'Tasks',
    mainTab: PatientTabs.Chart.text,
    title: 'Tasks',
    iconAlt: 'tasks-icon',
    iconSrc: Icons.timeLineIcon,
    permissionFields: ['team'],
    isEligibleForActionItems: false,
  },
  17: {
    routes: ['cognitive'],
    label: 'Cognitive',
    mainTab: PatientTabs.Chart.text,
    title: 'Cognitive',
    iconAlt: 'cognitive-icon',
    iconSrc: Icons.cognitiveIcon,
    permissionFields: ['cognitive'],
    isEligibleForActionItems: false,
  },
});

export const DashboardNavigationData = Object.freeze({
  1: {
    routes: ['tasks'],
    label: 'Tasks',
    iconAlt: 'team-icon',
    iconSrc: Icons.timeLineIcon,
  },
});

export const RightSidebarName = Object.freeze({
  ADMIN_NOTES: 'adminNotes',
  CURIE_AI: 'curieAI',
});
