import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag/selectors';
import Patient from 'common-src/models/Patient';
import { Colors, ColorsNew } from 'common-src/styles';

import Icons from 'src/assets/Icons';
import {
  getIsPatientSidebarExtended,
  getPatientMainTab,
  NavigationData,
  saveIsPatientSidebarExtended,
} from 'src/features/tabsState';
import useCustomSelector from 'src/hooks/useCustomSelector';

import { getActionItemsRoutes, getIsExtended } from './helpers';
import { ExtendedItem } from './items';
import styles from './PatientDetailsSidebar.module.scss';

const SIDEBAR_WIDTH = '60px';
const EXTENDED_SIDEBAR_WIDTH = '270px';

const PatientDetailsSidebar = ({ patient, routesWithActionItems, fragment }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const permissions = useCustomSelector(getRoleConfig)?.patientChart;
  const selectedPatientTab = useCustomSelector((state) => getPatientMainTab(state, patient.id));
  const isPatientSidebarExtended = useCustomSelector((state) =>
    getIsPatientSidebarExtended(state, patient.id),
  );
  const isCognitiveScreeningsEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'cognitiveScreenings'),
  );

  const sectionPermissions = permissions?.sections;
  const asideWidth =
    isPatientSidebarExtended && getIsExtended(fragment) ? EXTENDED_SIDEBAR_WIDTH : SIDEBAR_WIDTH;
  const actionItemsRoutes = getActionItemsRoutes();

  useEffect(() => {
    if (!fragment) {
      navigate(`/dashboard-client/patients/details/${patient.id}/info`);
    }

    if (isPatientSidebarExtended === undefined) {
      dispatch(saveIsPatientSidebarExtended(patient.id, getIsExtended(fragment)));
    }
  }, []);

  const getTabConfig = (tab) => {
    const permissions = _.filter(
      tab.permissionFields,
      (permission) => !!sectionPermissions?.[permission],
    );

    return {
      isAllowed: !_.isEmpty(permissions),
      initialTabIndex: tab.permissionFields.indexOf(permissions?.[0]),
    };
  };

  const shouldReloadTabContent = (tab) => {
    const routes = _.filter(tab.routes, (route) => location?.pathname?.includes(route));
    return _.isEmpty(routes);
  };

  const renderNavigation = () => (
    <div className={[styles.navigation, 'gap-8', 'flex-column'].join(' ')}>
      {Object.values(NavigationData)
        .filter((item) => item.mainTab === selectedPatientTab?.text)
        .map((item) => {
          if (item.label === 'Cognitive' && !isCognitiveScreeningsEnabled) return null;

          const tabConfig = getTabConfig(item);

          if (!tabConfig.isAllowed) return null;

          const intersection = _.intersection(item.routes, routesWithActionItems);

          return (
            <div
              key={item.routes[0].toString()}
              className={[styles.wrapper, 'horizontally-centered'].join(' ')}
            >
              {item.routes.includes(fragment) && <span className={styles.line} />}
              <Button
                id={`${item.routes[0].toString()}-button`}
                iconSrc={item.iconSrc}
                text={item.label}
                classNames={[styles.navButton, 'flex-column']}
                onClick={() => {
                  if (shouldReloadTabContent(item)) {
                    navigate(
                      `/dashboard-client/patients/details/${patient.id}/${
                        item.routes[tabConfig.initialTabIndex]
                      }`,
                    );
                  }

                  dispatch(saveIsPatientSidebarExtended(patient.id, !_.isEmpty(item?.items)));
                }}
                backgroundColor="transparent"
                borderColor="transparent"
                textColor={ColorsNew.darkGreen}
              />
              {!_.isEmpty(intersection) && (
                <span id="menu-red-dot-indicator" className={styles.dot} />
              )}
            </div>
          );
        })}
    </div>
  );

  const renderExtendedSectionHeader = (title) => (
    <div
      className={[
        styles.header,
        'vertically-centered',
        'p-r-20',
        'm-l-20',
        'primary-border-b',
      ].join(' ')}
    >
      <p className={[styles.title, 'font-w-700', 'font-s-16'].join(' ')}>{title}</p>
      <Button
        id="collapse-button"
        classNames={[styles.collapseButton]}
        iconSrc={Icons.trendUpIcon}
        onClick={() => dispatch(saveIsPatientSidebarExtended(patient.id, false))}
        backgroundColor="transparent"
        textColor={ColorsNew.darkGreen}
        borderColor={Colors.lightGray}
      />
    </div>
  );

  const renderExtendedItems = (selectedItem) => {
    if (_.isEmpty(selectedItem?.items)) return null;
    return (
      <div className="flex-column">
        {selectedItem.items.map((item) => {
          if (!sectionPermissions?.[item.permissionField]) {
            return null;
          }

          const intersection = _.intersection(actionItemsRoutes, routesWithActionItems);
          const hasActionItem = intersection.includes(item.route);

          return (
            <ExtendedItem
              key={item.name}
              name={item.name}
              isSelected={location?.pathname?.includes(item.route)}
              hasActionItem={hasActionItem}
              onClick={() => {
                navigate(`/dashboard-client/patients/details/${patient.id}/${item.route}`);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderExtendedSection = () => {
    if (!isPatientSidebarExtended) return null;

    const selectedItem = Object.values(NavigationData).find((item) =>
      item.routes.includes(fragment),
    );

    if (!selectedItem) return null;

    return (
      <div className={[styles.extendedSection, 'primary-border-l'].join(' ')}>
        {renderExtendedSectionHeader(selectedItem.title)}
        {renderExtendedItems(selectedItem)}
      </div>
    );
  };

  const asideClasses = [styles.aside];
  if (isPatientSidebarExtended && getIsExtended(fragment)) {
    asideClasses.push(styles.extended);
  }

  if (!selectedPatientTab?.showSideNav) {
    return null;
  }

  return (
    <aside style={{ width: asideWidth, minWidth: asideWidth }} className={asideClasses.join(' ')}>
      <div
        style={{ minWidth: SIDEBAR_WIDTH }}
        className={['vertically-centered', 'flex-column', 'gap-40', 'm-t-10'].join(' ')}
      >
        {renderNavigation()}
      </div>
      {renderExtendedSection()}
    </aside>
  );
};

PatientDetailsSidebar.propTypes = {
  patient: PropTypes.exact(Patient.schema),
  routesWithActionItems: PropTypes.arrayOf(PropTypes.string),
  fragment: PropTypes.string,
};

export default PatientDetailsSidebar;
