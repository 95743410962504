import _ from 'lodash';
import moment from 'moment-timezone';

import { LabOrderStatus } from './constants';
import { validatePatientLabOrder } from './validators';

export const extractLabOrder = (labOrder) => ({
  id: labOrder.id,
  labType: labOrder.labType,
  status: labOrder.status,
  location: labOrder.location,
  dateOrdered: labOrder.dateOrdered ?? null,
  dateResulted: labOrder.dateResulted ?? null,
  dateCanceled: labOrder.dateCanceled ?? null,
  cancelReason: labOrder.cancelReason,
  resultRange: labOrder.resultRange,
  patientId: labOrder.patientId,
});

export const getInitialLabOrderData = (patientId) => ({
  patientId,
  labType: null,
  status: LabOrderStatus.Ordered,
  location: null,
  dateOrdered: moment().format('YYYY-MM-DD'),
  dateResulted: null,
  dateCanceled: null,
  cancelReason: null,
  resultRange: null,
});

export const catchLabOrderErrors = (formData) => {
  const errors = {};
  formData.forEach((labOrder, i) => {
    if (!labOrder.patientId) return;
    Object.entries(labOrder).forEach(([field, value]) => {
      const err = validatePatientLabOrder(field, value, labOrder);
      if (err[field]) {
        Object.assign(errors, { [`${i}.${field}`]: err[field] });
      }
    });
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};
