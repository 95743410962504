import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getPatientLabOrdersRequest = (query, params) =>
  restRequest({
    uri: 'patientLabOrders',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientLabOrder = (body, params) =>
  restRequest({
    uri: 'patientLabOrders',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientLabOrder = (id, body, params) =>
  restRequest({
    uri: `patientLabOrders/${id}`,
    method: HttpMethods.Patch,
    body,
    ...params,
  });

export const deletePatientLabOrder = (id, params) =>
  restRequest({
    uri: `patientLabOrders/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
