export const WellnessPlanFormFields = [
  'valuesMotivators',
  'strengthsSuccesses',
  'barriersChallenges',
  'supportAccountability',
  'longTermGoals',
  'shortTermGoals',
  'acProgressOnGoals',
  'rdProgressOnGoals',
  'cdcesProgressOnGoals',
  'providerInstructions',
  'generalNotes',
];

export const WellnessPlanDataByField = Object.freeze({
  valuesMotivators: {
    label: 'Values / Motivators',
    description: 'Verify that the values and motivators for the member are updated.',
  },
  strengthsSuccesses: {
    label: 'Strengths / Successes',
    description: 'Verify that the strengths and successes for the member are updated.',
  },
  barriersChallenges: {
    label: 'Barriers / Challenges',
    description: 'Verify that barriers/challenges that the member faces are updated.',
  },
  supportAccountability: {
    label: 'Support / Accountability',
    description: 'Verify that the support the member needs is updated.',
  },
  longTermGoals: {
    label: 'Long-Term Goals',
    description: 'Update any of the member’s long-term goals.',
  },
  shortTermGoals: {
    label: 'Short-Term Goals',
    description: 'Update any of the member’s short-term goals.',
  },
  acProgressOnGoals: {
    label: 'AC Monthly Goals',
    description: 'AC: add/update member’s goals since their last visit.',
  },
  rdProgressOnGoals: {
    label: 'RD Monthly Goals',
    description: 'RD: add/update member’s goals since their last visit.',
  },
  cdcesProgressOnGoals: {
    label: 'CDCES Monthly Goals',
    description: 'CDCES: add/update member’s goals since their last visit.',
  },
  providerInstructions: {
    label: 'Provider Instructions',
    description: 'Provider: add any instructions provided to the member.',
  },
  generalNotes: {
    label: 'General Notes',
    description: '',
  },
});
