import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Select, TextArea } from 'common-src/components/base';
import { getRoleConfig, isViewOnly } from 'common-src/features/auth';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag/selectors';
import Patient, {
  hasInsulinPumpPresenter,
  readingFrequencyPresenter,
  riskLevelPresenter,
  updatePatient,
} from 'common-src/models/Patient';
import {
  getDiagnosisOptionByCode,
  getInsulinUse,
  getPrimaryDiagnosis,
  InsulinUseOptions,
} from 'common-src/models/PatientMedicalProblem';

import { InfoItem } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import {
  hasInsulinPumpOptions,
  readingFrequencyOptions,
  riskLevelOptions,
  testingOrdersOptions,
} from './constants';
import styles from './DiabeticMonitoring.module.scss';

const DiabeticMonitoring = ({ patient }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  const primaryDiagnosis = useCustomSelector((state) => getPrimaryDiagnosis(state, patient?.id));

  const diagnosisValue = useMemo(() => {
    if (!primaryDiagnosis) return '-';

    const diagnosis = getDiagnosisOptionByCode(primaryDiagnosis?.icd10Code);
    return `${diagnosis.value}: ${diagnosis.label}`;
  }, [primaryDiagnosis]);

  const {
    id: patientId,
    hasInsulinPump,
    riskLevel,
    isBGM,
    readingFrequency,
    testingOrders,
    additionalTestingInfo,
  } = patient;

  const insulinUse = useCustomSelector((state) => getInsulinUse(state, patientId));
  const actions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.physicianOrders,
  );
  const isReadingFrequencyEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'readingFrequency'),
  );

  const { showAlert, AlertType } = useAlert();

  const insulinUseValue = useMemo(
    () =>
      InsulinUseOptions.find((op) => op.value === insulinUse?.icd10Code)?.label ||
      'N/A (default value)',
    [insulinUse],
  );

  const onSubmitHandler = (setIsLoading, setIsEditMode) => {
    setIsLoading(true);
    dispatch(
      updatePatient(
        patient.id,
        {
          hasInsulinPump: formData?.insulinPump?.value,
          riskLevel: formData?.riskLevel?.value || null,
          readingFrequency: formData?.readingFrequency?.value,
          testingOrders: formData?.testingOrders?.value || null,
          additionalTestingInfo: formData?.additionalTestingInfo || null,
        },
        {
          successBlock: () => {
            setIsEditMode(false);
            setIsLoading(false);
            showAlert(AlertType.Success, '', 'You have successfully updated diabetic monitoring.');
          },
          errorBlock: (err) => {
            setIsLoading(false);
            showAlert(AlertType.Error, '', err);
          },
        },
      ),
    );
  };

  const onEditHandler = () => {
    setTimeout(() => {
      const element = document.getElementById('diabetic-monitoring-buttons');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);
  };

  const { isEditMode, renderContent, setInitialData, setIsLoading, setIsEditMode } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode),
    formData,
    true,
  );

  useEffect(() => {
    const data = {
      insulinPump: { value: hasInsulinPump, label: hasInsulinPumpPresenter(hasInsulinPump) },
      riskLevel: riskLevel ? { value: riskLevel, label: riskLevelPresenter(riskLevel) } : null,
      readingFrequency: readingFrequency
        ? { value: readingFrequency, label: readingFrequencyPresenter(readingFrequency) }
        : null,
      testingOrders: testingOrders ? { value: testingOrders, label: testingOrders } : null,
      additionalTestingInfo,
    };
    setFormData(data);
    setInitialData(data);
  }, [hasInsulinPump, riskLevel, readingFrequency, testingOrders, additionalTestingInfo]);

  const onChangeHandler = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const renderReadingFrequency = () => {
    if (!isReadingFrequencyEnabled || !isBGM) return <span />;

    return (
      <Select
        id="reading-frequency-select"
        label="Reading Frequency"
        placeholder="No reading frequency selected"
        options={readingFrequencyOptions}
        onChange={(op) => onChangeHandler('readingFrequency', op)}
        value={formData.readingFrequency}
        readOnly={!isEditMode}
        disabled={!actions?.setStripOrders}
        position="absolute"
        size="small"
      />
    );
  };

  const renderTestingOrders = () => {
    if (!isBGM) return null;

    return (
      <>
        <Select
          id="testing-orders-select"
          classNames={[styles.width75]}
          label="Testing Orders"
          placeholder="No testing orders selected"
          options={testingOrdersOptions}
          onChange={(op) => onChangeHandler('testingOrders', op)}
          value={formData.testingOrders}
          readOnly={!isEditMode}
          isClearable
          disabled={!actions?.setTestingOrders}
          position="absolute"
          size="small"
        />
        <span />
      </>
    );
  };

  const renderAdditionalTestingInfo = () => {
    if (!isBGM) return null;

    return (
      <TextArea
        id="additional-testing-info"
        classNames={[styles.width100]}
        label="Additional Testing Information"
        placeholder="Enter Additional Testing Information"
        readOnly={!isEditMode}
        value={formData.additionalTestingInfo || ''}
        onTextChange={(value) => onChangeHandler('additionalTestingInfo', value)}
        disabled={!actions?.addAdditionalTestingInfo}
        maxRows={4}
        size="small"
      />
    );
  };

  const renderForm = () => (
    <div className={styles.form}>
      <InfoItem
        title="Insulin Use"
        content={insulinUseValue}
        textId="insulin-use-select"
        isSmall
        classNames={['m-b-20']}
      />
      <InfoItem
        title="Primary Diagnosis"
        content={diagnosisValue}
        textId="primary-diagnosis-value"
        isSmall
        classNames={['m-b-20']}
      />
      <span />
      <Select
        id="insulin-pump-select"
        label="Insulin Pump"
        options={hasInsulinPumpOptions}
        onChange={(op) => onChangeHandler('insulinPump', op)}
        value={formData.insulinPump}
        readOnly={!isEditMode}
        disabled={!actions?.setInsulinPump}
        position="absolute"
        size="small"
      />
      <Select
        id="risk-level-select"
        label="Risk Level"
        placeholder="No risk level selected"
        options={riskLevelOptions}
        onChange={(op) => onChangeHandler('riskLevel', op)}
        value={formData.riskLevel}
        readOnly={!isEditMode}
        isClearable
        disabled={!actions?.setRiskLevel}
        position="absolute"
        size="small"
      />
      {renderReadingFrequency()}
      {renderTestingOrders()}
      {renderAdditionalTestingInfo()}
    </div>
  );

  return (
    <>
      {renderForm()}
      <div id="diabetic-monitoring-buttons" className="scroll-m-b-20">
        {renderContent(false, isViewOnly(actions), onEditHandler)}
      </div>
    </>
  );
};

DiabeticMonitoring.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default DiabeticMonitoring;
