import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import { apiRequest } from 'common-src/features/rest';
import { parseCGMEvaluationDraftData } from 'common-src/models/PatientInteraction/helpers';
import { getInitialTemplateValues } from 'common-src/models/Template';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import useCustomSelector from 'src/hooks/useCustomSelector';

import useWellnessPlan from './useWellnessPlan';

const useDocumentationConfig = (patientId, documentationId, templateType) => {
  const [templates, setTemplates] = useState([]);
  const [primaryModuleTypeId, setPrimaryModuleTypeId] = useState();
  const [timeLogs, setTimeLogs] = useState([]);
  const [serviceAt, setServiceAt] = useState(moment().format('YYYY/MM/DD HH:mm:ss'));
  const [awscCallIds, setAwscCallIds] = useState([]);
  const [hasRelevantConversations, setHasRelevantConversations] = useState(false);
  const [isEncounterNotScheduled, setIsEncounterNotScheduled] = useState(false);
  const [linkedVisitId, setLinkedVisitId] = useState(null);
  const [sdoh, setSdoh] = useState({});
  const [vitalsAndLabs, setVitalsAndLabs] = useState({});
  const [wellnessPlan, setWellnessPlan] = useState({});
  const [cgmAlerts, setCgmAlerts] = useState({});
  const [cgmEvaluation, setCgmEvaluation] = useState({});
  const [routineExams, setRoutineExams] = useState([]);

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const documentationToEdit = useCustomSelector((state) =>
    singleModelSelector(state, 'PatientInteraction', documentationId),
  );

  const { wellnessPlan: patientWellnessPlan } = useWellnessPlan(patientId);

  useEffect(() => {
    if (!_.isEmpty(timeLogs)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.timeLogs)) return;

    setTimeLogs(documentationToEdit?.draftData?.timeLogs);
  }, [JSON.stringify(documentationToEdit?.draftData?.timeLogs)]);

  useEffect(() => {
    if (!_.isEmpty(sdoh)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.sdoh)) return;

    setSdoh(documentationToEdit?.draftData?.sdoh);
  }, [JSON.stringify(documentationToEdit?.draftData?.sdoh)]);

  useEffect(() => {
    if (!_.isEmpty(vitalsAndLabs)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.vitalsAndLabs)) return;

    setVitalsAndLabs(documentationToEdit?.draftData?.vitalsAndLabs);
  }, [JSON.stringify(documentationToEdit?.draftData?.vitalsAndLabs)]);

  useEffect(() => {
    if (!_.isEmpty(wellnessPlan)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.wellnessPlan)) {
      setWellnessPlan(patientWellnessPlan);
      return;
    }

    setWellnessPlan(documentationToEdit?.draftData?.wellnessPlan);
  }, [JSON.stringify(documentationToEdit?.draftData?.wellnessPlan), patientWellnessPlan]);

  useEffect(() => {
    if (!_.isEmpty(cgmAlerts)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.cgmAlerts)) return;

    setCgmAlerts(documentationToEdit?.draftData?.cgmAlerts);
  }, [JSON.stringify(documentationToEdit?.draftData?.cgmAlerts)]);

  useEffect(() => {
    if (!_.isEmpty(cgmEvaluation)) return;
    if (_.isEmpty(documentationToEdit?.draftData?.cgmEvaluation)) return;

    // TODO: Remove parsing when all interactions with incorrect data are finalized
    setCgmEvaluation(parseCGMEvaluationDraftData(documentationToEdit?.draftData?.cgmEvaluation));
  }, [JSON.stringify(documentationToEdit?.draftData?.cgmEvaluation)]);

  const onTimeLogChange = (index, field, value) => {
    if (field) {
      const timeLogsCopy = JSON.parse(JSON.stringify(timeLogs));
      timeLogsCopy[index][field] = value;
      setTimeLogs(timeLogsCopy);
      return;
    }

    if (index !== undefined) {
      setTimeLogs((prev) => prev.filter((_, indx) => indx !== index));
      return;
    }

    setTimeLogs((prev) => [...prev, { timeSpent: undefined, category: null }]);
  };

  useEffect(() => {
    if (_.isEmpty(documentationToEdit) || !_.isEmpty(templates)) return;
    setTemplates(documentationToEdit.draftData.drafts ?? []);

    const selectedTemplateType = templateType || documentationToEdit.moduleTypes[0];
    if (selectedTemplateType && _.isEmpty(documentationToEdit.draftData.drafts)) {
      apiRequest({
        endpoint: 'templates/buildDraft',
        queryParams: {
          templateTypes: [selectedTemplateType],
          patientId,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.Draft?.[0]) {
            setTemplates((prev) => [...prev, getInitialTemplateValues(data.Draft[0])]);
          }
        });
    }

    if (primaryModuleTypeId) return;
    setPrimaryModuleTypeId(
      documentationToEdit.primaryModuleTypeId || documentationToEdit.moduleTypes[0],
    );
  }, [documentationToEdit]);

  return {
    patient,
    documentationToEdit,
    templates,
    setTemplates,
    primaryModuleTypeId,
    setPrimaryModuleTypeId,
    timeLogs,
    onTimeLogChange,
    serviceAt,
    setServiceAt,
    awscCallIds,
    setAwscCallIds,
    hasRelevantConversations,
    setHasRelevantConversations,
    isEncounterNotScheduled,
    setIsEncounterNotScheduled,
    linkedVisitId,
    setLinkedVisitId,
    sdoh,
    setSdoh,
    vitalsAndLabs,
    setVitalsAndLabs,
    wellnessPlan,
    setWellnessPlan,
    cgmAlerts,
    setCgmAlerts,
    cgmEvaluation,
    setCgmEvaluation,
    routineExams,
    setRoutineExams,
  };
};

export default useDocumentationConfig;
