export const downloadPDF = async (res, pdfName) => {
  const blob = await res.blob();
  const blobObj = new Blob([blob], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blobObj);
  const downloadLink = document.createElement('a');

  downloadLink.href = url;
  downloadLink.download = pdfName;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(downloadLink);
};
