import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { RequestHandlerScreen } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import { getPatientCognitiveJourneyRequest } from 'common-src/models/PatientCognitiveJourney';
import { getPatientScoreRequest } from 'common-src/models/PatientScore';

import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import CognitiveJourney from './CognitiveJourney';
import ScoresTable from './ScoresTable';

const Cognitive = ({ patientId }) => {
  const dispatch = useDispatch();

  const { isRequesting } = useRequestLoading([
    getPatientScoreRequest({ patientId }),
    getPatientCognitiveJourneyRequest({ patientId }),
  ]);

  useInterval(() => {
    dispatch(
      restRequestMultiple({
        restRequests: [
          getPatientScoreRequest({ patientId, sort_order: 'desc', limit: 5 }, {}),
          getPatientCognitiveJourneyRequest({ patientId, sort_order: 'desc', limit: 5 }, {}),
        ],
      }),
    );
  });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling scores..." />;
  }

  return (
    <>
      <CognitiveJourney patientId={patientId} />
      <ScoresTable patientId={patientId} />
    </>
  );
};

Cognitive.propTypes = {
  patientId: PropTypes.number,
};

export default Cognitive;
