export const ExamType = Object.freeze({
  Eye: 'Eye',
  Kidney: 'Kidney',
  Foot: 'Foot',
  DentalExam: 'Dental Exam',
});

export const ExamStatus = Object.freeze({
  Yes: 'Yes, completed in the past year',
  NoScheduled: 'No, exam is scheduled',
  NoNotScheduled: 'No, exam is not yet scheduled',
});

export const ExamDetail = Object.freeze({
  eGFR: 'eGFR',
  uACR: 'uACR',
});

export const ExamTypeOptions = Object.values(ExamType).map((type) => ({
  value: type,
  label: type,
}));

export const ExamStatusOptions = Object.values(ExamStatus).map((status) => ({
  value: status,
  label: status,
}));

export const ExamDetailOptions = Object.values(ExamDetail).map((detail) => ({
  value: detail,
  label: detail,
}));
