import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getPatientReferralsRequest = (query, params) =>
  restRequest({
    uri: 'patientReferrals',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPatientReferral = (body, params) =>
  restRequest({
    uri: 'patientReferrals',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePatientReferral = (id, body, params) =>
  restRequest({
    uri: `patientReferrals/${id}`,
    method: HttpMethods.Patch,
    body,
    ...params,
  });

export const deletePatientReferral = (id, params) =>
  restRequest({
    uri: `patientReferrals/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
