export const validatePatientReferral = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'referralType':
      if (!value) {
        errMsg = 'Referral type should not be empty';
      }
      break;
    case 'status':
      if (!value) {
        errMsg = 'Status should not be empty';
      }
      break;
    case 'dateSent':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
