export const HistoryEventTypes = Object.freeze({
  OutgoingMessages: {
    value: 1,
    label: 'Outgoing Messages',
  },
  IncomingMessages: {
    value: 2,
    label: 'Incoming Messages',
  },
  OutgoingCalls: {
    value: 3,
    label: 'Outgoing Calls',
  },
  IncomingCalls: {
    value: 4,
    label: 'Incoming Calls',
  },
  Reminders: {
    value: 5,
    label: 'Reminders',
  },
  Alerts: {
    value: 6,
    label: 'Alerts',
  },
  ScheduledVisits: {
    value: 7,
    label: 'Scheduled Visits',
  },
  NewDocumentation: {
    value: 8,
    label: 'New Documentation',
  },
  StatusUpdates: {
    value: 9,
    label: 'Status Updates',
  },
  NewGoals: {
    value: 10,
    label: 'New Goals',
  },
  ShippingUpdates: {
    value: 11,
    label: 'Shipping Updates',
  },
  CgmOrders: {
    value: 12,
    label: 'CGM Orders',
  },
  VideoCalls: {
    value: 13,
    label: 'Video Calls',
  },
});

export const MessageTypes = {
  Documentation: 1,
  TextMessage: 2,
  MissedCall: 3,
  CompletedCall: 4,
  PlatformStatus: 5,
  Appointment: 6,
  DeviceOrdered: 7,
  DeviceShipped: 8,
  DeviceDelivered: 9,
  ClinicalAlert: 10,
  HoldRequest: 11,
  PotentialMissedCall: 12,
  Reminder: 13,
  CgmUsageAlert: 14,
  SmartGoalCreated: 15,
  SmartGoalAccomplished: 16,
  CgmOrder: 17,
  AwscCall: 18,
  AwscChatMessage: 19,
  VideoCall: 21,
};
