import { LabOrderStatus } from './constants';

export const validatePatientLabOrder = (field, value, data) => {
  let errMsg = '';
  switch (field) {
    case 'labType':
      if (!value) {
        errMsg = 'Lab type should not be empty';
      }
      break;
    case 'status':
      if (!value) {
        errMsg = 'Status should not be empty';
      }
      break;
    case 'dateOrdered':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      }
      break;
    case 'dateResulted':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      }
      break;
    case 'dateCanceled':
      if (value === undefined) {
        errMsg = 'Invalid date format';
      }
      break;
    case 'resultRange':
      if (data.status === LabOrderStatus.Resulted && !value) {
        errMsg = 'Result range should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
