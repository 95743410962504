import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Input, Select } from 'common-src/components/base';
import { createClientRequest, roleOptions, validateClient } from 'common-src/models/Client';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import styles from './AddClientPopup.module.scss';
import { initialState, superAdminOptions } from './constants';
import { catchErrors, getIsButtonEnabled, getIsSuperAdminValue, getRequestBody } from './helpers';

const AddClientPopup = ({ onClose }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);

  const { errors, setErrors, renderButtons } = usePopup();

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    loadingCallback();
    dispatch(
      createClientRequest(getRequestBody(data), {
        successBlock: () => {
          successCallback('Client created!');
          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({ ...prev, ...validateClient(field, value) }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <BasePopup id="add-client" open onClose={onClose} title="Create Client Account">
      <div className={[styles.grid, 'gap-16'].join(' ')}>
        <Input
          id="first-name"
          placeholder="Enter first name"
          label="First Name"
          required
          value={data.firstName}
          errorText={errors.firstName}
          onTextChange={(value) => onChangeHandler('firstName', value)}
        />
        <Input
          id="last-name"
          placeholder="Enter last name"
          label="Last Name"
          required
          value={data.lastName}
          errorText={errors.lastName}
          onTextChange={(value) => onChangeHandler('lastName', value)}
        />
        <Select
          id="role"
          label="Role"
          options={roleOptions}
          onChange={(op) => onChangeHandler('roleId', op)}
          value={data.roleId}
          required
          placeholder="Select role"
          errorText={errors.roleId}
          paperHeight={270}
        />
        <Select
          id="is-super"
          label="Is super admin?"
          options={superAdminOptions}
          onChange={(op) => onChangeHandler('isSuper', op)}
          value={getIsSuperAdminValue(data.isSuper)}
          required
          placeholder="Is super admin?"
        />
        <Input
          id="email"
          placeholder="Enter email"
          label="Email"
          required
          value={data.email}
          errorText={errors.email}
          onTextChange={(value) => onChangeHandler('email', value)}
        />
        <Input
          id="npi"
          placeholder="Enter NPI"
          label="NPI"
          value={data.npi}
          errorText={errors.npi}
          onTextChange={(value) => onChangeHandler('npi', value)}
        />
        <Input
          id="credentials"
          placeholder="Enter Credentials"
          label="Credentials"
          value={data.credentials}
          errorText={errors.credentials}
          onTextChange={(value) => onChangeHandler('credentials', value)}
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, errors),
        submitButtonText: 'Save and close',
      })}
    </BasePopup>
  );
};

AddClientPopup.propTypes = {
  onClose: PropTypes.func,
};

export default AddClientPopup;
