import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPatientCognitiveJourneyRequest = (query, params) =>
  restRequest({
    uri: 'patientCognitiveJourney',
    query,
    method: HttpMethods.Get,
    ...params,
  });
