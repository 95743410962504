export const PreAuthStatus = Object.freeze({
  EnteredInError: { value: 0, label: 'Entered in Error', color: '#F06C67' },
  Active: { value: 1, label: 'Active', color: '#637768' },
  Expired: { value: 2, label: 'Expired', color: '#F06C67' },
  Canceled: { value: 3, label: 'Canceled', color: '#F06C67' },
  Submitted: { value: 4, label: 'Submitted', color: '#F1BF72' },
  Denied: { value: 5, label: 'Denied', color: '#F06C67' },
});

export const PreAuthServiceType = Object.freeze({
  EmNew: { value: 1, label: 'EM - New', sortOrder: 1 },
  RpmCgm: { value: 2, label: 'RPM/CGM', sortOrder: 3 },
  EmExisting: { value: 3, label: 'EM - Existing', sortOrder: 2 },
});

export const ACTIVE_STATUSES = [PreAuthStatus.Active.value, PreAuthStatus.Submitted.value];
