import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientReferral extends Model {
  static modelName = 'PatientReferral';

  static fields = {
    id: attr(),
    referralType: attr(),
    status: attr(),
    dateSent: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'patientReferralCreator'),
    updatedBy: fk('Client', 'patientReferralChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'patientReferrals',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      referralType: PropTypes.string,
      status: PropTypes.string,
      dateSent: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      createdBy: PropTypes.number,
      updatedBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientReferral;
