import React, { useState } from 'react';

import { Button } from 'common-src/components/base';
import { Colors, ColorsNew } from 'common-src/styles';

import useAlert from 'src/hooks/useAlert';

const initialLoadingState = { submit: false, save: false };

const usePopup = () => {
  const [loading, setLoading] = useState(initialLoadingState);
  const [errors, setErrors] = useState({});
  const [autoFocus, setAutoFocus] = useState(true);

  const { showAlert, AlertType, hasAlert } = useAlert();

  const successHandler = (message) => {
    setLoading(initialLoadingState);
    showAlert(AlertType.Success, '', message);
  };

  const errorHandler = (message) => {
    setLoading(initialLoadingState);
    showAlert(AlertType.Error, '', message);
  };

  const submitLoadingHandler = () => setLoading((prev) => ({ ...prev, submit: true }));

  const saveLoadingHandler = () => setLoading((prev) => ({ ...prev, save: true }));

  const renderButtons = ({
    buttonClassName,
    containerClassName,
    isSubmitEnabled,
    onSubmit,
    onClose,
    cancelButtonText,
    submitButtonText,
    onClear,
    renderSaveButton,
  }) => {
    const isButtonEnabled = !hasAlert && isSubmitEnabled;

    return (
      <div className={containerClassName}>
        {onClear && (
          <span className="popup-clear-btn" onClick={onClear} role="presentation">
            clear
          </span>
        )}
        {onClose && (
          <Button
            id="cancel-button"
            classNames={[buttonClassName]}
            text={cancelButtonText || 'Cancel'}
            textColor={ColorsNew.darkGreen}
            backgroundColor={Colors.white}
            borderColor={ColorsNew.darkGreen}
            onClick={onClose}
          />
        )}
        {onSubmit && (
          <Button
            id="submit-button"
            classNames={[buttonClassName]}
            isLoading={loading.submit}
            disabled={!isButtonEnabled || loading.save}
            text={submitButtonText || 'Submit'}
            textColor={!isButtonEnabled || loading.save ? Colors.black : Colors.white}
            backgroundColor={ColorsNew.darkGreen}
            borderColor={ColorsNew.darkGreen}
            onClick={() => onSubmit(submitLoadingHandler, successHandler, errorHandler, true)}
            width={140}
          />
        )}
        {renderSaveButton && (
          <Button
            id="save-and-add-button"
            isLoading={loading.save}
            disabled={!isButtonEnabled || loading.submit}
            text="Save and Add"
            textColor={!isButtonEnabled || loading.submit ? Colors.black : Colors.white}
            backgroundColor={ColorsNew.darkOrange}
            borderColor={ColorsNew.darkOrange}
            onClick={() => onSubmit(saveLoadingHandler, successHandler, errorHandler, false)}
            width={130}
          />
        )}
      </div>
    );
  };

  return {
    errors,
    setErrors,
    renderButtons: (props) => renderButtons(props),
    autoFocus,
    setAutoFocus,
    hasAlert,
  };
};

export default usePopup;
