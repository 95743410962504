// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Referrals-module__grid___ZzzpJ {
  width: 100%;
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(4, minmax(0, 220px));
}
.Referrals-module__grid___ZzzpJ .Referrals-module__button___wnaBo {
  margin-bottom: 8px;
  grid-column: 1 span;
}
.Referrals-module__grid___ZzzpJ .Referrals-module__button___wnaBo > img {
  width: 18px;
  height: 18px;
}
.Referrals-module__grid___ZzzpJ .Referrals-module__button___wnaBo > span {
  font-size: 14px !important;
}
.Referrals-module__grid___ZzzpJ .Referrals-module__separator___Z9KPO {
  grid-column: 4 span;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Orders/forms/Referrals/Referrals.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,kDAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEI;EACE,0BAAA;AAAN;AAIE;EACE,mBAAA;AAFJ","sourcesContent":[".grid {\n  width: 100%;\n  display: grid;\n  column-gap: 16px;\n  grid-template-columns: repeat(4, minmax(0, 220px));\n\n  .button {\n    margin-bottom: 8px;\n    grid-column: 1 span;\n\n    & > img {\n      width: 18px;\n      height: 18px;\n    }\n\n    & > span {\n      font-size: 14px !important;\n    }\n  }\n\n  .separator {\n    grid-column: 4 span;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `Referrals-module__grid___ZzzpJ`,
	"button": `Referrals-module__button___wnaBo`,
	"separator": `Referrals-module__separator___Z9KPO`
};
module.exports = ___CSS_LOADER_EXPORT___;
