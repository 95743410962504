import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PromptTemplate extends Model {
  static modelName = 'PromptTemplate';

  static fields = {
    id: attr(),
    trigger: attr(),
    displayName: attr(),
    templateText: attr(),
    roleId: attr(),
    clientId: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    updatedBy: fk('Client', 'promptTemplateChanger'),
    createdBy: fk('Client', 'promptTemplateCreator'),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      trigger: PropTypes.string,
      displayName: PropTypes.string,
      templateText: PropTypes.string,
      roleId: PropTypes.number,
      clientId: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
    }),
  };
}

export default PromptTemplate;
