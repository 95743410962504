import moment from 'moment-timezone';

import { ExamStatus, ExamType } from './constants';

export const validatePatientRoutineExam = (field, value, data) => {
  let errMsg = '';
  switch (field) {
    case 'type':
      if (!value) {
        errMsg = 'Exam should not be empty';
      }
      break;
    case 'status':
      if (!value) {
        errMsg = 'Status should not be empty';
      }
      break;
    case 'dateCompleted':
      if (data.status !== ExamStatus.Yes) {
        errMsg = '';
      } else if (value === undefined) {
        errMsg = 'Invalid date format';
      } else if (!value) {
        errMsg = 'Date should not be empty';
      } else if (value && moment(value).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
        errMsg = 'Date should not be in the future';
      }
      break;
    case 'detail':
      if (data.type !== ExamType.Kidney) {
        errMsg = '';
      } else if (!value) {
        errMsg = 'Detail should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
