import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Select } from 'common-src/components/base';
import { apiRequest, getRestErrorMessage, HttpMethods } from 'common-src/features/rest';
import { ReferralTypeOptions } from 'common-src/models/PatientReferral';
import { downloadPDF } from 'common-src/utils/pdfUtils';

import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { generatePDFName } from './helpers';
import styles from './SendReferralPopup.module.scss';

const SendReferralPopup = ({ patientId, onClose }) => {
  const [data, setData] = useState({ referralType: null });

  const { renderButtons } = usePopup();

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    loadingCallback();
    apiRequest({
      endpoint: `members/${patientId}/generateReferral`,
      queryParams: {},
      body: data,
      method: HttpMethods.Post,
    }).then(async (res) => {
      if (!res.ok) {
        const err = await res.json();
        const errMsg = getRestErrorMessage(err) || '';
        errorCallback(errMsg);
        return;
      }

      downloadPDF(res, generatePDFName(data, patientId));

      successCallback('Referral generated!');
      onClose(true);
    });
  };

  const onChange = (field, value) => setData((prev) => ({ ...prev, [field]: value }));

  return (
    <BasePopup id="generate-referral" open onClose={onClose} title="Generate Referral">
      <div className={styles.content}>
        <Select
          id="referral-type"
          placeholder="Select type"
          options={ReferralTypeOptions}
          value={ReferralTypeOptions.find((op) => op.value === data.referralType)}
          label="Referral Type"
          required
          onChange={(option) => {
            onChange('referralType', option.value);
          }}
          position="absolute"
          paperHeight={250}
          size="small"
        />
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        submitButtonText: 'Download and Close',
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: !Object.values(data).some((v) => !v),
      })}
    </BasePopup>
  );
};

SendReferralPopup.propTypes = {
  onClose: PropTypes.func,
  patientId: PropTypes.number,
};

export default SendReferralPopup;
