import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CommonIcons } from 'common-src/assets/Icons';
import {
  Checkbox,
  DatePickerNew,
  FormSelect,
  Input,
  RadioGroup,
  TextArea,
} from 'common-src/components/base';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import PatientInteraction from 'common-src/models/PatientInteraction';
import { getUniqueClientPromptTemplates } from 'common-src/models/PromptTemplate/selectors';
import { TemplateItemType, TemplateQuestionType } from 'common-src/models/Template';
import { ColorsNew } from 'common-src/styles';

import useCustomSelector from 'src/hooks/useCustomSelector';
import commonStyles from 'src/pages/DocumentationIndex/commonStyles.module.scss';
import {
  calculateIsRequired,
  isFalsyValue,
  shouldDisplayTemplateItem,
  textWithMarkdown,
} from 'src/pages/DocumentationIndex/helpers';
import { DocumentModule } from 'src/pages/DocumentationIndex/modules';

import FormHeader from '../FormHeader';
import styles from './DocumentForm.module.scss';

const QUESTIONS_WITH_MIN_DATE = [
  'Please enter starting date for hold status',
  'Please enter expiration date for hold status',
];

const DocumentForm = ({
  patientId,
  templates,
  setTemplates,
  primaryModuleTypeId,
  setHasDetectedChanges,
  setPrimaryModuleTypeId,
  errors,
  handleError,
  vitalsAndLabs,
  setVitalsAndLabs,
  wellnessPlan,
  setWellnessPlan,
  cgmAlerts,
  setCgmAlerts,
  cgmEvaluation,
  setCgmEvaluation,
  sdoh,
  setSdoh,
  routineExams,
  setRoutineExams,
  documentationToEdit,
  classNames = [],
  showPrimaryModuleCheckbox = false,
}) => {
  const promptTemplates = useCustomSelector((state) => getUniqueClientPromptTemplates(state));
  const isPromptTemplatesEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'promptTemplates'),
  );

  const getFieldErrors = (key) => {
    const questionKey = key.replace(/\.responses$/, '');
    const question = _.get(templates, questionKey);

    if (!question) return null;

    const hasError = errors?.has(question.questionId);
    if (!hasError) return null;

    errors.delete(question.questionId);

    return errors;
  };

  const updateTemplateHandler = (key, value) => {
    const fieldErrors = getFieldErrors(key);
    if (fieldErrors) {
      handleError({ generalError: null, fieldErrors });
    }

    const templatesCopy = JSON.parse(JSON.stringify(templates));
    const newTemplates = _.set(templatesCopy, key, value);
    setTemplates(newTemplates);
    setHasDetectedChanges(new Date());
  };

  const renderHeading = ({ size, text }, id) => (
    <p
      key={id}
      id={id}
      className={[styles.header, size === 16 ? 'font-w-700' : 'font-w-500', 'm-t-8'].join(' ')}
      style={{ fontSize: `${size}px` }}
    >
      {textWithMarkdown(text, '', styles.linkedText)}
    </p>
  );

  const renderLabel = (label, errorText, isRequired) => {
    if (!label) return null;

    return (
      <p
        className={[
          styles.label,
          'font-s-14',
          errorText ? styles.withError : '',
          'font-w-500',
          'm-b-16',
        ].join(' ')}
      >
        {label}
        <span className={styles.asterisk}>{isRequired ? '*' : ''}</span>
      </p>
    );
  };

  const renderQuestion = (question, key, templateItems) => {
    const id = `${question.questionId}-value`;
    const errorText = errors?.get(question.questionId);
    const isRequired = calculateIsRequired(question.isRequired, templateItems);

    switch (question.questionType) {
      case TemplateQuestionType.Text:
      case TemplateQuestionType.TextArea: {
        const response = _.get(templates, key)[0] ?? {};

        let maxRows;
        let fixedRows;
        switch (question.maxChars) {
          case 255:
            maxRows = 8;
            fixedRows = 3;
            break;
          case 512:
            maxRows = 16;
            fixedRows = 6;
            break;
          case 10000:
            maxRows = 30;
            fixedRows = 10;
            break;
          default:
            maxRows = 8;
            fixedRows = null;
            break;
        }

        return (
          <TextArea
            id={id}
            type={isPromptTemplatesEnabled ? 'template' : 'default'}
            classNames={[styles.input]}
            inputClassNames={[commonStyles.inputText]}
            label={question.label}
            description={question.description}
            placeholder={question.placeholder}
            required={isRequired}
            value={response.strValue}
            maxRows={maxRows}
            fixedRows={fixedRows}
            onFinishEditing={(value) => {
              const responses = value ? [{ strValue: value }] : [];
              updateTemplateHandler(key, responses);
            }}
            debounced
            errorText={errorText}
            templates={promptTemplates}
            withError
          />
        );
      }
      case TemplateQuestionType.Integer:
      case TemplateQuestionType.Currency:
      case TemplateQuestionType.Number: {
        const response = _.get(templates, key)[0] ?? {};
        const responseField =
          question.questionType === TemplateQuestionType.Integer ? 'intValue' : 'floatValue';

        return (
          <Input
            id={id}
            classNames={[styles.number]}
            inputClassNames={[commonStyles.inputNumber]}
            type="number"
            label={question.label}
            description={question.description}
            placeholder={question.placeholder}
            required={isRequired}
            requiredColor={ColorsNew.mediumDarkRed}
            value={response[responseField]}
            onFinishEditing={(value) =>
              updateTemplateHandler(key, isFalsyValue(value) ? [] : [{ [responseField]: value }])
            }
            debounced
            isFloat={question.questionType === TemplateQuestionType.Number}
            withError
            errorText={errorText}
            prefix={question.questionType === TemplateQuestionType.Currency ? '$' : null}
          />
        );
      }
      case TemplateQuestionType.Checkbox: {
        const responses = _.get(templates, key) || [];
        const options = question.options || [];
        const checkboxClassNames = [commonStyles.checkbox];
        const containerClassNames = ['m-b-14'];
        if (question.orientation === 'row') {
          containerClassNames.push('flex-row gap-16 vertically-centered');
        } else {
          checkboxClassNames.push('m-b-14');
          containerClassNames.push('flex-column vertically-start');
        }

        return (
          <div className={containerClassNames.join(' ')}>
            {renderLabel(question.label, errorText, isRequired)}
            {options.map((option, index) => {
              const isChecked = !!responses.find((r) => r.optionId === option.id);
              return (
                <Checkbox
                  key={`${key}.${index}`}
                  id={`${id}.${index}`}
                  classNames={checkboxClassNames}
                  label={option.label}
                  checked={isChecked}
                  onChange={() => {
                    updateTemplateHandler(
                      key,
                      isChecked
                        ? responses.filter((r) => r.optionId !== option.id)
                        : [...responses, { optionId: option.id }],
                    );
                  }}
                />
              );
            })}
          </div>
        );
      }
      case TemplateQuestionType.Radio: {
        const response = _.get(templates, key)[0] || {};
        const options = question.options || [];
        const radioClassNames = [styles.radio];
        const containerClassNames = ['m-b-14'];
        if (question.orientation === 'row') {
          radioClassNames.push(styles.row);
          containerClassNames.push('flex-row gap-16 vertically-centered');
        } else {
          containerClassNames.push('flex-column vertically-start');
        }

        return (
          <>
            {renderLabel(question.label, errorText, isRequired)}
            <RadioGroup
              id={id}
              containerClassNames={containerClassNames}
              classNames={radioClassNames}
              options={options.map((op) => op.label)}
              required={isRequired}
              label={question.label}
              errorText={errorText}
              value={options.find((op) => op.id === response.optionId)?.label}
              onChange={(value) => {
                const option = options.find((op) => op.label === value);
                updateTemplateHandler(key, [{ optionId: option.id }]);
              }}
              onClear={() => updateTemplateHandler(key, [])}
            />
          </>
        );
      }
      case TemplateQuestionType.Date: {
        const response = _.get(templates, key)[0] ?? {};
        const value = response.dateValue ?? null;

        return (
          <DatePickerNew
            id={id}
            placeholder={question.placeholder || 'Select date'}
            value={value}
            header={question.label}
            minDate={QUESTIONS_WITH_MIN_DATE.includes(question) ? new Date() : undefined}
            required={isRequired}
            requiredColor={ColorsNew.mediumDarkRed}
            iconSrc={CommonIcons.calendarCheckBoldIcon}
            onDateSelected={(date) => {
              const responses = date ? [{ dateValue: moment(date).format('YYYY-MM-DD') }] : [];
              return updateTemplateHandler(key, responses);
            }}
            classNames={[styles.date]}
            inputClassNames={[commonStyles.inputDateContainer]}
            textClassNames={[commonStyles.dateInput]}
            errorText={errorText}
          />
        );
      }
      case TemplateQuestionType.MultiSelect: {
        const options = question.options?.map((option) => ({ ...option, value: option.label }));
        const value = options.filter((option) =>
          question.responses.find((r) => r.optionId === option.id),
        );

        return (
          <FormSelect
            id={id}
            classNames={[styles.multiSelect]}
            label={question.label}
            placeholder={question.placeholder}
            description={question.description}
            options={options}
            value={value}
            onChange={(selectedOptions) =>
              updateTemplateHandler(
                key,
                selectedOptions.map((option) => ({ optionId: option.id })),
              )
            }
            required={isRequired}
            withError
            errorText={errorText}
            multiple
          />
        );
      }
      default:
        return <p>RESPONSE TYPE {question.questionType} NOT IMPLEMENTED</p>;
    }
  };

  const renderContent = (template, templateIdx) => {
    const { isExpanded, items, templateType } = template;
    if (!isExpanded || _.isEmpty(items)) return null;

    return (
      <div
        id={`content-${templateType}`}
        className={[styles.contentWrapper, 'flex-column', 'gap-14'].join(' ')}
      >
        {items.map((item, i) => {
          if (!shouldDisplayTemplateItem(item.displayCondition, items)) return null;

          switch (item.itemType) {
            case TemplateItemType.Heading:
              return renderHeading(item, `heading-${templateType}-${item.text.toLowerCase()}`);
            case TemplateItemType.Question:
              return (
                <div
                  key={item.questionId}
                  id={`question-${templateType}-${item.questionId}`}
                  className={styles.questionWrapper}
                >
                  {renderQuestion(item, `${templateIdx}.items.${i}.responses`, items)}
                </div>
              );
            case TemplateItemType.Module:
              if (!item.isEmbedded || !template.isExpanded) return null;
              return (
                <div key={`module-${templateType}-${item.moduleType}`} className="m-b-22">
                  <DocumentModule
                    moduleType={item.moduleType}
                    patientId={patientId}
                    sdoh={sdoh}
                    setSdoh={setSdoh}
                    setHasDetectedChanges={setHasDetectedChanges}
                    cgmAlerts={cgmAlerts}
                    setCgmAlerts={setCgmAlerts}
                    cgmEvaluation={cgmEvaluation}
                    setCgmEvaluation={setCgmEvaluation}
                    errors={errors}
                    vitalsAndLabs={vitalsAndLabs}
                    setVitalsAndLabs={setVitalsAndLabs}
                    wellnessPlan={wellnessPlan}
                    setWellnessPlan={setWellnessPlan}
                    routineExams={routineExams}
                    setRoutineExams={setRoutineExams}
                    documentationToEdit={documentationToEdit}
                  />
                </div>
              );
            default:
              return null;
          }
        })}
      </div>
    );
  };

  const renderHeader = (template) => {
    const { title, isExpanded, templateType, instructions } = template;
    const index = _.findIndex(templates, ['templateType', templateType]);

    return (
      <>
        <FormHeader
          title={title}
          isExpanded={!!isExpanded}
          onClick={() => {
            updateTemplateHandler(`${index}.isExpanded`, !isExpanded);
            setHasDetectedChanges(new Date());
          }}
          showPrimaryModuleCheckbox={showPrimaryModuleCheckbox}
          onChange={() => {
            setPrimaryModuleTypeId(templateType);
            setHasDetectedChanges(new Date());
          }}
          moduleType={templateType}
          primaryModuleTypeId={primaryModuleTypeId}
        />
        {instructions && isExpanded && (
          <div className={[styles.templateInstructions, 'font-s-14', 'p-l-30', 'm-b-20'].join(' ')}>
            {instructions}
          </div>
        )}
      </>
    );
  };

  const renderModules = () => {
    const moduleTypes = [];
    templates.forEach(
      (template) =>
        template.isSelected &&
        template.items.forEach(
          (item) =>
            item.itemType === 'Module' && !item.isEmbedded && moduleTypes.push(item.moduleType),
        ),
    );
    const uniqueModuleTypes = _.uniq(moduleTypes);

    return uniqueModuleTypes.map((moduleType) => (
      <DocumentModule
        key={moduleType}
        moduleType={moduleType}
        patientId={patientId}
        sdoh={sdoh}
        setSdoh={setSdoh}
        setHasDetectedChanges={setHasDetectedChanges}
        cgmAlerts={cgmAlerts}
        setCgmAlerts={setCgmAlerts}
        cgmEvaluation={cgmEvaluation}
        setCgmEvaluation={setCgmEvaluation}
        errors={errors}
        vitalsAndLabs={vitalsAndLabs}
        setVitalsAndLabs={setVitalsAndLabs}
        wellnessPlan={wellnessPlan}
        setWellnessPlan={setWellnessPlan}
        routineExams={routineExams}
        setRoutineExams={setRoutineExams}
        documentationToEdit={documentationToEdit}
      />
    ));
  };

  const form = templates.map(
    (template, templateIdx) =>
      template.isSelected && (
        <div id={`module-${template.templateType}`} key={template.title}>
          {renderHeader(template)}
          {renderContent(template, templateIdx)}
        </div>
      ),
  );

  return (
    <div className={[...classNames].join(' ')}>
      {form}
      {renderModules()}
    </div>
  );
};

DocumentForm.propTypes = {
  patientId: PropTypes.number,
  templates: PropTypes.arrayOf(PropTypes.object),
  setTemplates: PropTypes.func,
  primaryModuleTypeId: PropTypes.number,
  setPrimaryModuleTypeId: PropTypes.func,
  showPrimaryModuleCheckbox: PropTypes.bool,
  errors: PropTypes.object,
  classNames: PropTypes.arrayOf(PropTypes.string),
  handleError: PropTypes.func,
  vitalsAndLabs: PropTypes.object,
  setVitalsAndLabs: PropTypes.func,
  cgmAlerts: PropTypes.object,
  setCgmAlerts: PropTypes.func,
  cgmEvaluation: PropTypes.object,
  setCgmEvaluation: PropTypes.func,
  setHasDetectedChanges: PropTypes.func,
  wellnessPlan: PropTypes.object,
  setWellnessPlan: PropTypes.func,
  sdoh: PropTypes.object,
  setSdoh: PropTypes.func,
  routineExams: PropTypes.array,
  setRoutineExams: PropTypes.func,
  documentationToEdit: PropTypes.exact(PatientInteraction.schema),
};

export default DocumentForm;
