import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';

import { CustomCheckbox } from 'common-src/components/base';
import PatientOncehubBooking from 'common-src/models/PatientOncehubBooking';

import Icons from 'src/assets/Icons';
import { getScheduledCallTypeOrEventName } from 'src/pages/PatientDetails/fragments/ScheduledCalls/helpers';

import styles from './ScheduledVisitItem.module.scss';

const ScheduledVisitItem = ({ visit, isChecked, checkboxHandler, timeZone, disabled }) => {
  const dateWithTimeZone = moment(visit.scheduledAt).tz(timeZone);

  return (
    <div
      className={[
        styles.container,
        isChecked ? styles.checked : '',
        disabled ? styles.disabled : '',
        'flex-row',
        'p-12',
        'm-t-16',
        'primary-border',
        'border-r-12',
        'gap-10',
      ].join(' ')}
      role="presentation"
      onClick={checkboxHandler}
    >
      <img className={styles.icon} src={Icons.calendarCheckIcon} alt="calendar-check-icon" />
      <div className="flex-column flex-1 gap-4">
        <span id="client-name" className={[styles.title, 'font-s-12', 'font-w-600'].join(' ')}>
          {visit.client.getName()}
        </span>
        <span id="scheduled-visit-type" className={[styles.type, 'font-w-700'].join(' ')}>
          {getScheduledCallTypeOrEventName(visit)}
        </span>
        <span id="scheduled-visit-time" className={[styles.time, 'font-s-10'].join(' ')}>
          {`${dateWithTimeZone.format('MM/DD/YYYY')} at ${dateWithTimeZone.format('h:mm z')}`}
        </span>
      </div>
      <CustomCheckbox
        classNames={[styles.checkbox, 'm-r-20']}
        header={isChecked ? 'Visit linked' : 'Link visit'}
        onChange={checkboxHandler}
        checked={isChecked}
        disabled={disabled}
      />
    </div>
  );
};

ScheduledVisitItem.propTypes = {
  isChecked: PropTypes.bool,
  checkboxHandler: PropTypes.func,
  timeZone: PropTypes.string,
  visit: PropTypes.exact(PatientOncehubBooking.schema),
  disabled: PropTypes.bool,
};

export default ScheduledVisitItem;
