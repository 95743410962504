export const ReferralType = Object.freeze({
  EyeExam: 'Eye exam',
});

export const ReferralStatus = Object.freeze({
  Sent: 'Sent',
});

export const ReferralTypeOptions = Object.values(ReferralType).map((type) => ({
  value: type,
  label: type,
}));

export const ReferralStatusOptions = Object.values(ReferralStatus).map((status) => ({
  value: status,
  label: status,
}));
