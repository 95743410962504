import PropTypes from 'prop-types';
import { attr, Model } from 'redux-orm';

class CorrespondenceCopy extends Model {
  static modelName = 'CorrespondenceCopy';

  static fields = {
    id: attr(),
    copyText: attr(),
    description: attr(),
    deletedAt: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: attr(),
    updatedBy: attr(),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      copyText: PropTypes.string,
      description: PropTypes.string,
      deletedAt: PropTypes.string,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
    }),
  };
}

export default CorrespondenceCopy;
