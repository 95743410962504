export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  roleId: null,
  npi: '',
  credentials: '',
  isSuper: false,
};

export const superAdminOptions = Object.values(['No', 'Yes']).map((option) => ({
  value: option === 'Yes',
  label: option,
}));
