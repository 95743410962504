export const validatePromptTemplate = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'trigger':
      if (!value) {
        errMsg = 'Trigger should not be empty';
      } else if (value.startsWith('/')) {
        errMsg = 'Trigger can not start with "/"';
      }
      break;
    case 'displayName':
      if (!value) {
        errMsg = 'Display name should not be empty';
      }
      break;
    case 'templateText':
      if (!value) {
        errMsg = 'Template text should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
