import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './SettingsNav.module.scss';

const SettingsNav = ({ canEditPlatformSettings }) => (
  <nav id="settings-nav" className={[styles.nav, 'flex-column', 'p-l-40'].join(' ')}>
    <NavLink
      to="/dashboard-client/settings/my-account"
      className={({ isActive }) => (isActive ? styles.active : '')}
    >
      Profile
    </NavLink>
    {canEditPlatformSettings && (
      <span className={styles.text}>
        Platform Settings
        <NavLink
          to="/dashboard-client/settings/feature-flags"
          className={({ isActive }) => (isActive ? styles.active : '')}
        >
          Feature flags
        </NavLink>
        <NavLink
          to="/dashboard-client/settings/correspondence-copy"
          className={({ isActive }) => (isActive ? styles.active : '')}
        >
          Correspondence Copy
        </NavLink>
      </span>
    )}
    <NavLink
      to="/dashboard-client/settings/calls"
      className={({ isActive }) => (isActive ? styles.active : '')}
    >
      Calls
    </NavLink>
  </nav>
);

SettingsNav.propTypes = {
  canEditPlatformSettings: PropTypes.bool,
};

export default SettingsNav;
