import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Menu } from 'common-src/components/base';
import { getRoleConfig, logOutRequest } from 'common-src/features/auth';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import {
  InteractionStatus,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';

import { CLIENT_ROOT } from 'src/constants/paths';
import { getNoteData, getPatientNote } from 'src/features/tabsState';
import { getTimer } from 'src/features/timeTracker';
import useCustomSelector from 'src/hooks/useCustomSelector';

const MENU_OPTION_HEIGHT = 44;
const MARGIN_TOP = 60;

const AccountMenuButton = ({ button, classNames, onClickCreateClient, onSelectTab, patientId }) => {
  const dispatch = useDispatch();

  const permission = useCustomSelector((state) => getRoleConfig(state));
  const noteId = useCustomSelector((state) => getPatientNote(state, patientId));
  const noteData = useCustomSelector((state) => getNoteData(state, patientId));
  const timer = useCustomSelector((state) => getTimer(state, patientId));
  const isPromptTemplatesEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'promptTemplates'),
  );

  const canEditPlatformSettings = permission?.canEditPlatformSettings;
  const canCreateClient = permission?.canCreateClient;
  const canManageClients = permission?.canManageClients;

  const menuItems = _.compact([
    {
      text: 'My Account',
      onClick: async () => {
        onSelectTab({ path: '/dashboard-client/settings/my-account' });
      },
    },
    canCreateClient && {
      text: 'Create user',
      onClick: async () => onClickCreateClient(),
    },
    canManageClients && {
      text: 'Manage users',
      onClick: async () => {
        onSelectTab({ path: '/dashboard-client/manage-clients' });
      },
    },
    isPromptTemplatesEnabled && {
      text: 'Manage Prompt Templates',
      onClick: async () => {
        onSelectTab({ path: '/dashboard-client/manage-prompt-templates' });
      },
    },
    canEditPlatformSettings && {
      text: 'Platform Settings',
      onClick: async () => {
        onSelectTab({ path: '/dashboard-client/settings/feature-flags' });
      },
    },
    {
      text: 'Dashboard',
      onClick: async () => {
        onSelectTab({ path: '/dashboard-client/dashboard' });
      },
    },
    {
      text: 'Logout',
      onClick: async () => {
        if (noteId) {
          dispatch(
            updatePatientDraftApiRequest(
              noteId,
              {
                ...noteData,
                status: InteractionStatus.Paused,
                duration: timer,
              },
              {},
            ),
          );
        }

        dispatch(
          logOutRequest({
            successBlock: () => onSelectTab({ path: `${CLIENT_ROOT}/dashboard` }),
          }),
        );
      },
    },
  ]);

  return (
    <Menu
      classNames={classNames}
      button={button}
      options={menuItems}
      paperWidth={225}
      useButtonHeight
      paperStyles={{ marginTop: `${menuItems.length * MENU_OPTION_HEIGHT + MARGIN_TOP}px` }}
    />
  );
};

AccountMenuButton.propTypes = {
  button: PropTypes.object,
  classNames: PropTypes.arrayOf(PropTypes.string),
  onClickCreateClient: PropTypes.func,
  onSelectTab: PropTypes.func,
  patientId: PropTypes.number,
};

export default AccountMenuButton;
