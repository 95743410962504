import _ from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { CommonIcons } from 'common-src/assets/Icons';
import { CommonImages } from 'common-src/assets/Images';
import { Autocomplete } from 'common-src/components/base';
import { getRoleConfig } from 'common-src/features/auth';
import { setClientsCached, setOrganizationsCached } from 'common-src/features/cache';
import { apiRequest } from 'common-src/features/rest';
import { getClientsRequest } from 'common-src/models/Client';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import { getOrganizationsRequest } from 'common-src/models/Organization';
import {
  InteractionStatus,
  updatePatientDraftApiRequest,
} from 'common-src/models/PatientInteraction';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { ModalType } from 'src/components/base/ModalGroup';
import { NavItem } from 'src/components/navigation';
import { CLIENT_ROOT, PATIENT_ROOT } from 'src/constants/paths';
import { openModal } from 'src/features/modals';
import {
  getNoteData,
  getPatientMainTab,
  getPatientNote,
  getPatientsPaths,
  getTabByText,
  getTabPath,
  PatientTabs,
  saveNoteData,
  saveNoteScroll,
  savePatientNote,
  setActiveTabs,
} from 'src/features/tabsState';
import { clearTimer, getTimer } from 'src/features/timeTracker';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useTabs from 'src/hooks/useTabs';
import { getTab } from 'src/pages/Dashboard/helpers';

import { AccountMenuButton, AutoCompleteOption, NewNoteMenu, Tabs } from './components';
import styles from './MainNavigation.module.scss';

const MainNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const permissions = useCustomSelector((state) => getRoleConfig(state));
  const patientsPaths = useCustomSelector((state) => getPatientsPaths(state));
  const isPromptTemplatesEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'promptTemplates'),
  );

  const getCurrentTab = () => getTab(location?.pathname, isPromptTemplatesEnabled).getLabel();

  const tab = useMemo(() => getCurrentTab(), [location?.pathname, isPromptTemplatesEnabled]);

  const patientId = tab?.replace(/^\D+/g, '');
  const mainTab = useCustomSelector((state) =>
    patientId ? getPatientMainTab(state, Number(patientId)) : {},
  );

  const canAddNote = permissions?.patientChart?.actions?.canAddDocumentation;

  const { onSelectTab } = useTabs();
  const { showAlert, AlertType } = useAlert();

  const patient = useCustomSelector((state) =>
    patientId ? singleModelSelector(state, 'Patient', Number(patientId)) : null,
  );
  const selectedPatientTab = useCustomSelector((state) => getPatientMainTab(state, patientId));
  const noteId = useCustomSelector((state) => getPatientNote(state, patientId));
  const timer = useCustomSelector((state) => getTimer(state, patientId));
  const noteData = useCustomSelector((state) => getNoteData(state, patientId));

  const patientName = useMemo(() => {
    if (_.isEmpty(patient)) return '';
    return patient.getName();
  }, [patient]);

  const tabs = useMemo(
    () =>
      Object.values(PatientTabs).filter(
        (tab) => tab.showAlways || (tab.text === 'Note' && !!noteId),
      ),
    [noteId],
  );

  useEffect(() => {
    const defaultTab = getCurrentTab();

    if (defaultTab) {
      dispatch(setActiveTabs([defaultTab]));
    }

    dispatch(
      getOrganizationsRequest({
        successBlock: () =>
          dispatch(
            getClientsRequest({
              successBlock: () => {
                dispatch(setOrganizationsCached(true));
                dispatch(setClientsCached(true));
              },
            }),
          ),
      }),
    );
  }, [isPromptTemplatesEnabled]);

  // initial loading of saved note tab
  useEffect(() => {
    if (
      !tab ||
      !tab.startsWith('Member Details') ||
      !patientId ||
      !noteId ||
      selectedPatientTab !== PatientTabs.Note ||
      location?.pathname?.includes('edit')
    ) {
      return;
    }

    navigate(`/dashboard-client/patients/details/${patientId}/note/${noteId}/edit`);
  }, [tab, selectedPatientTab]);

  const onSelectPatientTab = (tabText) => {
    const tab = getTabByText(tabText);

    if (!tab) return;

    navigate(getTabPath(tab, patientId, noteId));
  };

  const onCloseNote = () => {
    dispatch(
      updatePatientDraftApiRequest(
        noteId,
        { ...noteData, duration: timer, status: InteractionStatus.Paused },
        {
          successBlock: () => {
            dispatch(savePatientNote(patientId, null));
            dispatch(saveNoteData(patientId, {}));
            dispatch(clearTimer(Number(patientId)));
            setTimeout(() => dispatch(saveNoteScroll(patientId, 0)), 100);

            if (mainTab.text === PatientTabs.Note.text) {
              navigate(`/dashboard-client/patients/details/${patientId}/info`);
            }
          },
        },
      ),
    );
  };

  const showAddClientPopup = () => dispatch(openModal(ModalType.ADD_CLIENT));

  const copyLinkHandler = () => {
    const patientChartLink = `${window.location.origin}${PATIENT_ROOT}/patients/details/${patient.id}/info`;

    navigator.clipboard.writeText(patientChartLink).then(() => {
      showAlert(AlertType.Success, '', 'Chart link copied to clipboard!');
    });
  };

  const renderInfo = () => {
    switch (true) {
      case !!patientId:
        return (
          <div className="flex-row vertically-centered height-100">
            <span id="patient-name" className={styles.text}>
              {patientName}
            </span>
            <NavItem
              title="MRN"
              value={patientId || '-'}
              classNames={[styles.mrn, 'm-l-30']}
              withSeparator
              onClick={copyLinkHandler}
            />
            <Tabs
              classNames={[styles.patientTabs]}
              tabs={tabs}
              selectedTab={selectedPatientTab?.text}
              onTabSelect={onSelectPatientTab}
              onTabClose={onCloseNote}
            />
          </div>
        );
      default:
        return (
          <span id="tab-name" className={styles.text}>
            {tab}
          </span>
        );
    }
  };

  const renderRightContent = () => (
    <>
      {patientId && canAddNote && (
        <NewNoteMenu
          patientId={Number(patientId)}
          isNoteTabOpened={!!tabs.find((tab) => tab.text === 'Note')}
          permissions={permissions}
          mainTab={mainTab}
          noteData={noteData}
          noteId={noteId}
          patient={patient}
        />
      )}
      <Autocomplete
        classNames={[styles.autocomplete, 'm-l-24']}
        key="search-members-input"
        id="search-members"
        initialOptionsCount={20}
        renderOption={(patient) => <AutoCompleteOption patient={patient} />}
        onOptionSelect={(patient) => {
          const path = patientsPaths[patient?.id]?.path;
          onSelectTab({
            path: path || `/dashboard-client/patients/details/${patient.id}/info`,
          });
        }}
        getRequest={(searchText, count) =>
          apiRequest({
            endpoint: `members/search/${searchText}`,
            queryParams: { skip: 0, limit: count },
          })
        }
        minSearchTextLength={4}
        paperStyles={{ right: 0, width: 'max-content', minWidth: '450px' }}
      />
      <AccountMenuButton
        key="accountMenuBtn"
        classNames={[styles.accountMenuButton]}
        button={<img className={styles.icon} src={CommonIcons.hamburgerIcon} alt="menu-icon" />}
        onSelectTab={onSelectTab}
        onClickCreateClient={showAddClientPopup}
        patientId={Number(patientId)}
      />
    </>
  );

  return (
    <div className={styles.root}>
      <div className={[styles.leftContainer, 'vertically-centered'].join(' ')}>
        <button
          id="header-logo"
          className={[styles.headerLogo, 'cursor-pointer '].join(' ')}
          type="button"
          onClick={() => onSelectTab({ path: `${CLIENT_ROOT}/dashboard` })}
        >
          <img className={styles.logoImage} alt="logo" src={CommonImages.logoBird} />
        </button>
        {renderInfo()}
      </div>
      <div className={[styles.rightContainer, 'vertically-centered'].join(' ')}>
        {renderRightContent()}
      </div>
    </div>
  );
};

export default MainNavigation;
