import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getRoleConfig } from 'common-src/features/auth';
import PatientOncehubBooking, {
  OncehubBookingStatuses,
  updateOncehubBookingStatus,
} from 'common-src/models/PatientOncehubBooking';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import { ModalType } from 'src/components/base/ModalGroup';
import { ActionsRow, CreatorItem, TablePagination, TextItem, TimeItem } from 'src/components/table';
import BaseTable from 'src/components/table/BaseTable';
import { ResultsCount } from 'src/components/table/TablePagination/constants';
import { openModal } from 'src/features/modals';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useOpenWindow from 'src/hooks/useOpenWindow';
import { getScheduledCallTypeOrEventName } from 'src/pages/PatientDetails/fragments/ScheduledCalls/helpers';

import { getCallStatus, getHeaders } from './helpers';
import { StatusItem } from './items';
import styles from './ScheduledCallsList.module.scss';

const ScheduledCallsList = ({ patientId, scheduledCalls = [] }) => {
  const dispatch = useDispatch();

  const [types, setTypes] = useState([]);
  const [paginationData, setPaginationData] = useState({ from: 0, to: undefined });

  const patient = useCustomSelector((state) => singleModelSelector(state, 'Patient', patientId));
  const actions =
    useCustomSelector((state) => getRoleConfig(state)?.patientChart?.sections?.scheduledCalls) ||
    {};

  const { openWindow } = useOpenWindow();

  const timeZone = patient?.timeZone;

  const getFilteredScheduledCalls = () => {
    if (_.isEmpty(types)) return scheduledCalls;
    return scheduledCalls.filter((c) => types.some((t) => c?.visitType === t));
  };

  const headers = getHeaders(types, setTypes);
  const filteredScheduledCalls = useMemo(
    () => getFilteredScheduledCalls(),
    [scheduledCalls, types],
  );

  const getData = () =>
    filteredScheduledCalls.map((item) => {
      const status = getCallStatus(item, timeZone);

      return {
        scheduledTime: (
          <TimeItem date={item.scheduledAt} duration={item.durationMinutes} timeZone={timeZone} />
        ),
        createdTime: <TimeItem date={item.createdAt} timeZone={timeZone} />,
        type: getScheduledCallTypeOrEventName(item),
        reason: <TextItem isActive text={item?.visitReason || '-'} />,
        assigned: (
          <CreatorItem
            isActive
            creator={item?.client}
            creatorName={item?.client?.getName(false, true)}
          />
        ),
        status: <StatusItem status={status} />,
        actions: (
          <ActionsRow
            options={_.compact([
              {
                id: 'modify-button',
                text: 'Modify',
                backgroundColor: '#555671B2',
                color: 'white',
                disabled:
                  !['today', 'scheduled', 'rescheduled'].includes(status) || !item?.rescheduleUrl,
                onClick: () => openWindow({ url: item.rescheduleUrl, target: '_blank' }),
              },
              {
                id: 'mark-as-completed-button',
                text: 'Mark as Completed',
                backgroundColor: '#375844CC',
                color: 'white',
                disabled: !['missed'].includes(status),
                onClick: () =>
                  dispatch(
                    updateOncehubBookingStatus(
                      item.id,
                      OncehubBookingStatuses.completed.text.toLowerCase(),
                    ),
                  ),
              },
              {
                id: 'mark-as-missed-button',
                text: 'Mark as Missed',
                backgroundColor: 'rgba(240, 31, 81)',
                color: 'white',
                disabled: !['completed'].includes(status),
                onClick: () =>
                  dispatch(
                    updateOncehubBookingStatus(
                      item.id,
                      OncehubBookingStatuses.missed.text.toLowerCase(),
                    ),
                  ),
              },
              actions?.canEditScheduledCall && {
                id: 'edit-button',
                text: 'Edit',
                backgroundColor: '#555671B2',
                color: 'white',
                onClick: () =>
                  dispatch(
                    openModal(ModalType.EDIT_VISIT, {
                      scheduledCall: item,
                      timeZone,
                    }),
                  ),
              },
            ])}
          />
        ),
      };
    });

  return (
    <div className={styles.container}>
      <BaseTable
        headers={headers}
        data={getData()}
        name="scheduled-calls"
        classNames={[
          styles.table,
          filteredScheduledCalls?.length <= ResultsCount[25] ? styles.noPagination : '',
        ]}
        emptyText="No scheduled visits"
        emptyClassNames={[styles.empty]}
        paginationData={paginationData}
        initialSortColumn={{
          column: 'scheduledTime',
          label: 'Scheduled Time',
          width: '20%',
          sortBy: 'date',
        }}
      />
      <TablePagination onChangePageHandler={setPaginationData} results={filteredScheduledCalls} />
    </div>
  );
};

ScheduledCallsList.propTypes = {
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  scheduledCalls: PropTypes.arrayOf(PropTypes.exact(PatientOncehubBooking.schema)),
};

export default ScheduledCallsList;
