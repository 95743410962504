import { restRequest } from 'common-src/features/rest/actions';
import { HttpMethods } from 'common-src/features/rest/constants';

export const getPromptTemplatesRequest = (query, params) =>
  restRequest({
    uri: 'promptTemplates',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createPromptTemplate = (body, params) =>
  restRequest({
    uri: 'promptTemplates',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updatePromptTemplate = (id, body, params) =>
  restRequest({
    uri: `promptTemplates/${id}`,
    method: HttpMethods.Patch,
    body,
    ...params,
  });

export const deletePromptTemplate = (id, query, params) =>
  restRequest({
    uri: `promptTemplates/${id}`,
    method: HttpMethods.Delete,
    query,
    ...params,
  });
