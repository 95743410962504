import { getPatientCognitiveJourney } from 'common-src/models//PatientCognitiveJourney';
import { allModelsSelector } from 'common-src/utils/selectorUtils';

import { ScoreType } from './constants';

export const getPatientScores = (state, patientId) =>
  allModelsSelector(state, 'PatientScore', (ps) => ps.patientId === patientId).sort(
    (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
  );

export const getCognitivePatientScores = (state, patientId) => {
  const patientCognitiveJourney = getPatientCognitiveJourney(state, patientId);
  const isScreeningIncomplete = !!(
    patientCognitiveJourney?.optedOutOfScreening ||
    patientCognitiveJourney?.unableToCompleteScreening
  );
  const validScoreTypes = Object.values(ScoreType).map((st) => st.value);
  const excludedScoreTypes = isScreeningIncomplete
    ? [ScoreType.MemoryImpairmentScreen.value, ScoreType.MoCABlind.value]
    : [];

  return getPatientScores(state, patientId).filter(
    (ps) => validScoreTypes.includes(ps?.scoreType) && !excludedScoreTypes.includes(ps?.scoreType),
  );
};
