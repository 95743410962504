import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew, Input, Select } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import {
  catchLabOrderErrors,
  extractLabOrder,
  getInitialLabOrderData,
  getPatientLabOrders,
  LabOrderCancelReasonOptions,
  LabOrderLocationOptions,
  LabOrderResultRangeOptions,
  LabOrderStatus,
  LabOrderStatusOptions,
  LabOrderTypeOptions,
  validatePatientLabOrder,
} from 'common-src/models/PatientLabOrder';

import { AddButton, RemoveButton } from 'src/components/buttons';
import { SeparatorLine } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import { getLabOrderRequests } from './helpers';
import styles from './LabOrders.module.scss';

const LabOrders = ({ patientId }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState([]);

  const labOrders = useCustomSelector((state) => getPatientLabOrders(state, patientId));

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchLabOrderErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    setIsLoading(true);
    dispatch(
      restRequestMultiple({
        restRequests: getLabOrderRequests(formData, labOrders),
        successBlock: () => {
          setIsEditMode(false);
          setIsLoading(false);
          showAlert(AlertType.Success, '', 'You have successfully updated lab orders.');
        },
        errorBlock: (err) => {
          setIsLoading(false);
          showAlert(AlertType.Error, '', err);
        },
      }),
    );
  };

  const onEditHandler = () => {
    setTimeout(() => {
      const element = document.getElementById('lab-orders-buttons');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    setErrors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'labOrders',
  );

  useEffect(() => {
    const data = !_.isEmpty(labOrders)
      ? labOrders.map((labOrder) => extractLabOrder(labOrder))
      : [];

    setInitialData(data);
    setFormData(data);
  }, [labOrders]);

  const onChange = (field, value, data) => {
    const validationField = field.split('.')[1];
    const err = validatePatientLabOrder(validationField, value, data);

    setErrors((prev) => ({ ...prev, [field]: err[validationField] }));
    setFormData((prev) => {
      const newFormData = _.cloneDeep(prev);
      _.set(newFormData, field, value);
      return newFormData;
    });
  };

  const onDateChange = (field, date, data) => {
    const dateToApply = date ? moment(date).format('YYYY-MM-DD') : null;
    onChange(field, dateToApply, data);
  };

  const onStatusChange = (index, value, data) => {
    const currentStatus = _.get(formData, `${index}.status`);

    if (currentStatus === LabOrderStatus.Resulted && value !== LabOrderStatus.Resulted) {
      onChange(`${index}.dateResulted`, null, data);
      onChange(`${index}.resultRange`, null, data);
      setErrors((prev) => ({ ...prev, [`${index}.resultRange`]: '' }));
    }
    if (currentStatus === LabOrderStatus.Canceled && value !== LabOrderStatus.Canceled) {
      onChange(`${index}.dateCanceled`, null, data);
      onChange(`${index}.cancelReason`, null, data);
    }

    onChange(`${index}.status`, value, data);

    const statusDateMap = {
      [LabOrderStatus.Resulted]: 'dateResulted',
      [LabOrderStatus.Canceled]: 'dateCanceled',
    };
    const dateField = statusDateMap[value];

    if (dateField) {
      onChange(`${index}.${dateField}`, moment().format('YYYY-MM-DD'), data);
    }
  };

  const renderAddButton = () => {
    if (!isEditMode) return null;

    return (
      <AddButton
        id="add-lab-order"
        classNames={[styles.button]}
        label="Add lab order"
        onClick={() => {
          setFormData((prev) => [...prev, getInitialLabOrderData(patientId)]);
          onEditHandler();
        }}
      />
    );
  };

  const renderRemoveButton = (labOrder) => {
    if (!isEditMode) return <div />;

    return (
      <RemoveButton
        id="remove-lab-order"
        onClick={() => {
          const { errors } = catchLabOrderErrors(_.without(formData, labOrder));
          setErrors(errors);
          setFormData((prev) => _.without(prev, labOrder));
        }}
      />
    );
  };

  const renderCreatedBy = (labOrderId, index) => {
    if (isEditMode) return null;

    const labOrder = labOrders.find((labOrder) => labOrder.id === labOrderId);

    return (
      <Input
        id={`lab-order-created-by-${index}`}
        label="Created By"
        readOnly
        value={labOrder.createdBy?.getName()}
        size="small"
      />
    );
  };

  const renderForm = () => (
    <>
      {formData.map((labOrder, index) => (
        <div key={`lab-order-${index}`}>
          <div className={styles.grid}>
            <Select
              id={`lab-order-type-${index}`}
              placeholder="Select lab type"
              options={LabOrderTypeOptions}
              value={LabOrderTypeOptions.find((op) => op.value === labOrder.labType)}
              label="Lab Type"
              required
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.labType`, option.value, labOrder);
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.labType`]}
            />
            <Select
              id={`lab-order-status-${index}`}
              placeholder="Select status"
              options={LabOrderStatusOptions}
              value={LabOrderStatusOptions.find((op) => op.value === labOrder.status)}
              label="Status"
              required
              readOnly={!isEditMode}
              onChange={(option) => {
                onStatusChange(index, option.value, labOrder);
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.status`]}
            />
            <Select
              id={`lab-order-location-${index}`}
              placeholder="Select location"
              options={LabOrderLocationOptions}
              value={LabOrderLocationOptions.find((op) => op.value === labOrder.location)}
              label="Location"
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.location`, option.value, labOrder);
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.location`]}
            />
            <DatePickerNew
              id={`lab-order-date-ordered-${index}`}
              header="Date Ordered"
              placeholder="MM/YYYY"
              maxDate={new Date()}
              value={labOrder.dateOrdered}
              iconSrc={CommonIcons.calendarCheckBoldIcon}
              onDateSelected={(date) => onDateChange(`${index}.dateOrdered`, date, labOrder)}
              onBlur={(date) => onDateChange(`${index}.dateOrdered`, date, labOrder)}
              errorText={errors[`${index}.dateOrdered`]}
              size="small"
              required
              readOnly={!isEditMode}
            />
            <DatePickerNew
              id={`lab-order-date-resulted-${index}`}
              header="Date Resulted"
              placeholder="MM/YYYY"
              maxDate={new Date()}
              value={labOrder.dateResulted}
              iconSrc={CommonIcons.calendarCheckBoldIcon}
              onDateSelected={(date) => onDateChange(`${index}.dateResulted`, date, labOrder)}
              onBlur={(date) => onDateChange(`${index}.dateResulted`, date, labOrder)}
              errorText={errors[`${index}.dateResulted`]}
              size="small"
              readOnly={!isEditMode}
              disabled={isEditMode && labOrder.status !== LabOrderStatus.Resulted}
            />
            <Select
              id={`lab-order-result-range-${index}`}
              placeholder="Select result range"
              options={LabOrderResultRangeOptions}
              value={LabOrderResultRangeOptions.find((op) => op.value === labOrder.resultRange)}
              label="Result Range"
              required={labOrder.status === LabOrderStatus.Resulted}
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.resultRange`, option.value, labOrder);
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.resultRange`]}
              disabled={isEditMode && labOrder.status !== LabOrderStatus.Resulted}
            />
            <DatePickerNew
              id={`lab-order-date-canceled-${index}`}
              header="Date Canceled"
              placeholder="MM/YYYY"
              maxDate={new Date()}
              value={labOrder.dateCanceled}
              iconSrc={CommonIcons.calendarCheckBoldIcon}
              onDateSelected={(date) => onDateChange(`${index}.dateCanceled`, date, labOrder)}
              onBlur={(date) => onDateChange(`${index}.dateCanceled`, date, labOrder)}
              errorText={errors[`${index}.dateCanceled`]}
              size="small"
              readOnly={!isEditMode}
              disabled={isEditMode && labOrder.status !== LabOrderStatus.Canceled}
            />
            <Select
              id={`lab-order-cancel-reason-${index}`}
              placeholder="Select cancel reason"
              options={LabOrderCancelReasonOptions}
              value={LabOrderCancelReasonOptions.find((op) => op.value === labOrder.cancelReason)}
              label="Cancel Reason"
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.cancelReason`, option.value, labOrder);
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.cancelReason`]}
              disabled={isEditMode && labOrder.status !== LabOrderStatus.Canceled}
            />
            {renderCreatedBy(labOrder.id, index)}
            {renderRemoveButton(labOrder)}
          </div>
          <SeparatorLine classNames={['m-b-20']} />
        </div>
      ))}
      <div className={styles.grid}>{renderAddButton()}</div>
    </>
  );

  return (
    <>
      {renderForm()}
      <div id="lab-orders-buttons" className="scroll-m-b-20">
        {renderContent(false, false, onEditHandler)}
      </div>
    </>
  );
};

LabOrders.propTypes = {
  patientId: PropTypes.number,
};

export default LabOrders;
