export const validateCorrespondenceCopy = (field, value) => {
  let errMsg = '';
  switch (field) {
    case 'id':
      if (!value) {
        errMsg = 'Id should not be empty';
      }
      if (!/^[A-Z]+(?:_[A-Z0-9]+)*$/.test(value)) {
        errMsg = 'Invalid id format';
      }
      break;
    case 'copyText':
      if (!value) {
        errMsg = 'Copy text should not be empty';
      }
      break;
    default:
      errMsg = '';
  }

  return { [field]: errMsg };
};
