import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { CommonIcons } from 'common-src/assets/Icons';
import { DatePickerNew, Input, Select } from 'common-src/components/base';
import { restRequestMultiple } from 'common-src/features/rest/actions';
import {
  catchReferralErrors,
  extractPatientReferral,
  getInitialReferralData,
  getPatientReferrals,
  ReferralStatusOptions,
  ReferralTypeOptions,
  validatePatientReferral,
} from 'common-src/models/PatientReferral';

import { AddButton, RemoveButton } from 'src/components/buttons';
import { SeparatorLine } from 'src/components/elements';
import useAlert from 'src/hooks/useAlert';
import useCustomSelector from 'src/hooks/useCustomSelector';
import useFormButtons from 'src/hooks/useFormButtons';

import { getReferralRequests, hasDuplicateReferrals } from './helpers';
import styles from './Referrals.module.scss';

const Referrals = ({ patientId }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState([]);

  const referrals = useCustomSelector((state) => getPatientReferrals(state, patientId));

  const { showAlert, AlertType } = useAlert();

  const onSubmitHandler = (setIsLoading, setIsEditMode, handleErrors) => {
    const { errors, hasErrors } = catchReferralErrors(formData);
    if (hasErrors) {
      handleErrors(errors);
      return;
    }

    if (hasDuplicateReferrals(formData)) {
      showAlert(AlertType.Error, '', 'Duplicate referrals found');
      return;
    }

    setIsLoading(true);
    dispatch(
      restRequestMultiple({
        restRequests: getReferralRequests(formData, referrals),
        successBlock: () => {
          setIsEditMode(false);
          setIsLoading(false);
          showAlert(AlertType.Success, '', 'You have successfully updated referrals.');
        },
        errorBlock: (err) => {
          setIsLoading(false);
          showAlert(AlertType.Error, '', err);
        },
      }),
    );
  };

  const onEditHandler = () => {
    setTimeout(() => {
      const element = document.getElementById('referrals-buttons');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 0);
  };

  const {
    isEditMode,
    setIsEditMode,
    setIsLoading,
    errors,
    setErrors,
    handleErrors,
    renderContent,
    setInitialData,
  } = useFormButtons(
    'section',
    setFormData,
    () => onSubmitHandler(setIsLoading, setIsEditMode, handleErrors),
    formData,
    true,
    'referrals',
  );

  useEffect(() => {
    const data = !_.isEmpty(referrals)
      ? referrals.map((referral) => extractPatientReferral(referral))
      : [];

    setInitialData(data);
    setFormData(data);
  }, [referrals]);

  const onChange = (field, value) => {
    const validationField = field.split('.')[1];
    const err = validatePatientReferral(validationField, value);

    setErrors((prev) => ({ ...prev, [field]: err[validationField] }));
    setFormData((prev) => {
      const newFormData = _.cloneDeep(prev);
      _.set(newFormData, field, value);
      return newFormData;
    });
  };

  const onDateChange = (field, date) => {
    const dateToApply = date ? moment(date).format('YYYY-MM-DD') : null;
    onChange(field, dateToApply);
  };

  const renderAddButton = () => {
    if (!isEditMode) return null;

    return (
      <AddButton
        id="add-referral"
        classNames={[styles.button]}
        label="Add referral"
        onClick={() => {
          setFormData((prev) => [...prev, getInitialReferralData(patientId)]);
          onEditHandler();
        }}
      />
    );
  };

  const renderRemoveButton = (referral) => {
    if (!isEditMode) return <div />;

    return (
      <RemoveButton
        id="remove-referral"
        onClick={() => {
          const { errors } = catchReferralErrors(_.without(formData, referral));
          setErrors(errors);
          setFormData((prev) => _.without(prev, referral));
        }}
      />
    );
  };

  const renderCreatedBy = (referralId, index) => {
    if (isEditMode) return null;

    const referral = referrals.find((referral) => referral.id === referralId);

    return (
      <Input
        id={`referral-created-by-${index}`}
        label="Created By"
        readOnly
        value={referral.createdBy?.getName()}
        size="small"
      />
    );
  };

  const renderForm = () => (
    <>
      {formData.map((referral, index) => (
        <div key={`referral-${index}`}>
          <div className={styles.grid}>
            <Select
              id={`referral-type-${index}`}
              placeholder="Select referral type"
              options={ReferralTypeOptions}
              value={ReferralTypeOptions.find((op) => op.value === referral.referralType)}
              label="Referral Type"
              required
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.referralType`, option.value);
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.referralType`]}
            />
            <Select
              id={`referral-status-${index}`}
              placeholder="Select status"
              options={ReferralStatusOptions}
              value={ReferralStatusOptions.find((op) => op.value === referral.status)}
              label="Status"
              required
              readOnly={!isEditMode}
              onChange={(option) => {
                onChange(`${index}.status`, option.value);
              }}
              position="absolute"
              paperHeight={250}
              size="small"
              errorText={errors[`${index}.status`]}
            />
            <DatePickerNew
              id={`referral-date-sent-${index}`}
              header="Date Sent"
              placeholder="MM/YYYY"
              maxDate={new Date()}
              value={referral.dateSent}
              iconSrc={CommonIcons.calendarCheckBoldIcon}
              onDateSelected={(date) => onDateChange(`${index}.dateSent`, date)}
              onBlur={(date) => onDateChange(`${index}.dateSent`, date)}
              errorText={errors[`${index}.dateSent`]}
              size="small"
              readOnly={!isEditMode}
              required
            />
            {renderCreatedBy(referral.id, index)}
            {renderRemoveButton(referral)}
          </div>
          <div className={styles.grid}>
            <SeparatorLine classNames={[styles.separator, 'm-b-20']} />
          </div>
        </div>
      ))}
      <div className={styles.grid}>{renderAddButton()}</div>
    </>
  );

  return (
    <>
      {renderForm()}
      <div id="referrals-buttons" className="scroll-m-b-20">
        {renderContent(false, false, onEditHandler)}
      </div>
    </>
  );
};

Referrals.propTypes = {
  patientId: PropTypes.number,
};

export default Referrals;
