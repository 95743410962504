import moment from 'moment-timezone';

import { getVisitTypeLabel, VisitType } from 'common-src/models/PatientOncehubBooking';

import { ScheduledCallTabs } from './constants';

export const canceledFilter = (booking) => booking?.status === 'canceled';

export const getScheduledCallTabs = (oncehubBookings, timeZone) => {
  const upcoming =
    oncehubBookings?.filter(
      (b) =>
        !moment().tz(timeZone).isAfter(moment(b.scheduledAt).tz(timeZone)) && !canceledFilter(b),
    )?.length || 0;
  const past =
    oncehubBookings?.filter(
      (b) =>
        moment().tz(timeZone).isAfter(moment(b.scheduledAt).tz(timeZone)) && !canceledFilter(b),
    )?.length || 0;
  const canceled = oncehubBookings?.filter((b) => canceledFilter(b))?.length || 0;

  return {
    UPCOMING: {
      text: ScheduledCallTabs.UPCOMING.text,
      count: upcoming,
    },
    PAST: {
      text: ScheduledCallTabs.PAST.text,
      count: past,
    },
    CANCELED: {
      text: ScheduledCallTabs.CANCELED.text,
      count: canceled,
    },
    ALL: {
      text: ScheduledCallTabs.ALL.text,
      count: upcoming + past + canceled,
    },
  };
};

export const getScheduledCallType = (scheduledCall) => getVisitTypeLabel(scheduledCall.visitType);

export const getScheduledCallTypeOrEventName = (scheduledCall) => {
  const visitTypeLabel = getScheduledCallType(scheduledCall);

  return visitTypeLabel !== VisitType.Unknown.label
    ? visitTypeLabel
    : (scheduledCall.eventName ?? '-');
};
