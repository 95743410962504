import { HttpMethods, restRequest } from 'common-src/features/rest';

export const getCorrespondenceCopyRequest = (query, params) =>
  restRequest({
    uri: 'correspondenceCopy',
    query,
    method: HttpMethods.Get,
    ...params,
  });

export const createCorrespondenceCopy = (body, params) =>
  restRequest({
    uri: 'correspondenceCopy',
    method: HttpMethods.Post,
    body,
    ...params,
  });

export const updateCorrespondenceCopy = (id, body, params) =>
  restRequest({
    uri: `correspondenceCopy/${id}`,
    method: HttpMethods.Patch,
    body,
    ...params,
  });

export const deleteCorrespondenceCopy = (id, params) =>
  restRequest({
    uri: `correspondenceCopy/${id}`,
    method: HttpMethods.Delete,
    ...params,
  });
