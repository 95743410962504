import _ from 'lodash';

import { validatePromptTemplate } from 'common-src/models/PromptTemplate';

import { initialState } from './constants';

export const catchErrors = (data) => {
  const errors = {};
  Object.entries(data).forEach(([field, value]) => {
    const err = validatePromptTemplate(field, value);
    if (err[field]) {
      Object.assign(errors, { [field]: err[field] });
    }
  });

  return {
    errors,
    hasErrors: !_.isEmpty(errors) || Object.values(errors).some((e) => e),
  };
};

export const getCreateRequestBody = (data, currentUserId) => ({
  ..._.omit(data, ['roles']),
  clientId: _.isEmpty(data.roles) ? currentUserId : undefined,
  roleIds: data.roles.map((role) => role.value),
});

export const getUpdateRequestBody = (data, shouldUpdateForAllRoles) => ({
  ...data,
  clientId: shouldUpdateForAllRoles ? undefined : data.clientId || undefined,
  roleId: shouldUpdateForAllRoles ? undefined : data.roleId || undefined,
  shouldUpdateForAllRoles: shouldUpdateForAllRoles || undefined,
});

export const extractPromptTemplate = (data) =>
  _.pick(data, ['trigger', 'displayName', 'templateText', 'roleId', 'clientId']);

export const getIsButtonEnabled = (data, promptTemplate, errors) => {
  if (
    _.isEqual(data, initialState) ||
    _.isEqual(data, extractPromptTemplate(promptTemplate)) ||
    Object.values(errors).some((e) => e)
  )
    return false;
  return true;
};
