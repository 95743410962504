import _ from 'lodash';

import { defaultRolePermissions } from 'common-src/features/auth/constants';
import { allModelsSelector, singleModelSelector } from 'common-src/utils/selectorUtils';

import { getCurrentClient } from '../Client';

export const getPromptTemplate = (state, id) => singleModelSelector(state, 'PromptTemplate', id);

export const getAllPromptTemplates = (state) => allModelsSelector(state, 'PromptTemplate');

export const getClientPromptTemplates = (state) => {
  const client = getCurrentClient(state);
  const allPrompts = getAllPromptTemplates(state);
  const clientPermissions = defaultRolePermissions[client?.roleId];

  return allPrompts.filter(
    (pt) =>
      pt.clientId === client?.id ||
      pt.roleId === client?.roleId ||
      client?.isSuper ||
      (clientPermissions?.canManagePromptTemplates && !pt.clientId),
  );
};

export const getUniqueClientPromptTemplates = (state) =>
  _.uniqBy(getClientPromptTemplates(state), 'trigger').sort((a, b) => b.clientId - a.clientId);

export const getPersonalPromptTemplates = (state) => {
  const client = getCurrentClient(state);
  const allPrompts = getAllPromptTemplates(state);

  return allPrompts.filter((pt) => pt.clientId === client?.id);
};
