export const REQUEST_PAGE_SIZE = 10;
export const BOTTOM_DEVIATION = 2500;

export const AppointmentType = {
  canceled: 'Canceled',
  scheduled: 'Scheduled',
  rescheduled: 'Rescheduled',
};

export const CallMethod = {
  Outbound: 'OUTBOUND',
  Inbound: 'INBOUND',
  Transfer: 'TRANSFER',
  Callback: 'CALLBACK',
  Api: 'API',
  QueueTransfer: 'QUEUE_TRANSFER',
  ExternalOutbound: 'EXTERNAL_OUTBOUND',
  Monitor: 'MONITOR',
  Disconnect: 'DISCONNECT',
};

export const ChatDirection = {
  Outbound: 'OUTBOUND',
  Inbound: 'INBOUND',
};

export const ChatStatus = {
  Sent: 'SENT',
  Inbound: 'SUCCESS',
};

export const initialFilters = {
  types: [],
  alertsOnly: false,
  messagesOnly: false,
  appointmentsOnly: false,
};

export const initialSmsConfig = {
  text: '',
  error: '',
  isLoading: false,
};
