import PropTypes from 'prop-types';
import { attr, fk, Model } from 'redux-orm';

class PatientScore extends Model {
  static modelName = 'PatientScore';

  static fields = {
    id: attr(),
    scoreType: attr(),
    intResult: attr(),
    maxIntResult: attr(),
    boolResult: attr(),
    resultDate: attr(),
    interactionId: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    createdBy: fk('Client', 'patientScoreCreator'),
    updatedBy: fk('Client', 'patientScoreChanger'),
    patientId: fk({
      to: 'Patient',
      as: 'patient',
      relatedName: 'scores',
    }),
  };

  static schema = {
    _fields: PropTypes.exact({
      id: PropTypes.string,
      scoreType: PropTypes.string,
      intResult: PropTypes.number,
      maxIntResult: PropTypes.number,
      boolResult: PropTypes.bool,
      resultDate: PropTypes.string,
      interactionId: PropTypes.string,
      updatedAt: PropTypes.string,
      createdAt: PropTypes.string,
      updatedBy: PropTypes.number,
      createdBy: PropTypes.number,
      patientId: PropTypes.number,
    }),
    patientId: PropTypes.number,
  };
}

export default PatientScore;
