// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoCallInformationPopup-module__content___DtUXj {
  padding: 25px 30px;
}
.VideoCallInformationPopup-module__content___DtUXj .VideoCallInformationPopup-module__row___gWWX5 {
  justify-content: space-between;
}
.VideoCallInformationPopup-module__content___DtUXj .VideoCallInformationPopup-module__row___gWWX5 .VideoCallInformationPopup-module__button___Bq2rL {
  padding: 5px 18px;
  font-size: 12px !important;
}

.VideoCallInformationPopup-module__buttonsContainer___B3IKS {
  gap: 16px;
  padding: 30px;
  display: flex;
  justify-content: end;
  border-top: 1px solid rgba(91, 91, 91, 0.2);
}`, "",{"version":3,"sources":["webpack://./src/popups/VideoCallInformationPopup/VideoCallInformationPopup.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AACE;EACE,8BAAA;AACJ;AACI;EACE,iBAAA;EACA,0BAAA;AACN;;AAIA;EACE,SAAA;EACA,aAAA;EACA,aAAA;EACA,oBAAA;EACA,2CAAA;AADF","sourcesContent":[".content {\n  padding: 25px 30px;\n\n  .row {\n    justify-content: space-between;\n\n    .button {\n      padding: 5px 18px;\n      font-size: 12px !important;\n    }\n  }\n}\n\n.buttonsContainer {\n  gap: 16px;\n  padding: 30px;\n  display: flex;\n  justify-content: end;\n  border-top: 1px solid rgba(91, 91, 91, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `VideoCallInformationPopup-module__content___DtUXj`,
	"row": `VideoCallInformationPopup-module__row___gWWX5`,
	"button": `VideoCallInformationPopup-module__button___Bq2rL`,
	"buttonsContainer": `VideoCallInformationPopup-module__buttonsContainer___B3IKS`
};
module.exports = ___CSS_LOADER_EXPORT___;
