import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Checkbox, Input, Select, TextArea } from 'common-src/components/base';
import { getCurrentUserId, getRoleConfig } from 'common-src/features/auth';
import { roleOptions } from 'common-src/models/Client';
import {
  createPromptTemplate,
  updatePromptTemplate,
  validatePromptTemplate,
} from 'common-src/models/PromptTemplate';
import { getPromptTemplate } from 'common-src/models/PromptTemplate/selectors';
import { ColorsNew } from 'common-src/styles';

import useCustomSelector from 'src/hooks/useCustomSelector';
import usePopup from 'src/hooks/usePopup';
import BasePopup from 'src/popups/BasePopup';

import { initialState } from './constants';
import {
  catchErrors,
  extractPromptTemplate,
  getCreateRequestBody,
  getIsButtonEnabled,
  getUpdateRequestBody,
} from './helpers';
import styles from './PromptTemplatePopup.module.scss';

const PromptTemplatePopup = ({ promptTemplateId, onClose }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(initialState);
  const [shouldUpdateForAllRoles, setShouldUpdateForAllRoles] = useState(false);

  const currentUserId = useCustomSelector((state) => getCurrentUserId(state));
  const canManagePromptTemplates = useCustomSelector(
    (state) => getRoleConfig(state)?.canManagePromptTemplates,
  );
  const promptTemplate = useCustomSelector((state) => getPromptTemplate(state, promptTemplateId));

  const { errors, setErrors, renderButtons } = usePopup();

  useEffect(() => {
    if (_.isEmpty(promptTemplate)) return;
    setData(extractPromptTemplate(promptTemplate));
  }, [promptTemplate]);

  const onSubmitHandler = (loadingCallback, successCallback, errorCallback) => {
    const { errors, hasErrors } = catchErrors(data);
    if (hasErrors) {
      setErrors(errors);
      return;
    }

    loadingCallback();

    if (promptTemplateId) {
      dispatch(
        updatePromptTemplate(
          promptTemplateId,
          getUpdateRequestBody(data, shouldUpdateForAllRoles),
          {
            successBlock: () => {
              successCallback('Prompt template updated!');
              onClose(true);
            },
            errorBlock: (err) => errorCallback(err),
          },
        ),
      );
      return;
    }

    dispatch(
      createPromptTemplate(getCreateRequestBody(data, currentUserId), {
        successBlock: () => {
          successCallback('Prompt template created!');
          onClose(true);
        },
        errorBlock: (err) => errorCallback(err),
      }),
    );
  };

  const onChangeHandler = (field, value) => {
    setErrors((prev) => ({
      ...prev,
      ...validatePromptTemplate(field, value),
    }));
    setData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <BasePopup
      id="prompt-template"
      open
      onClose={onClose}
      title={`${promptTemplateId ? 'Edit' : 'Add'} Prompt Template`}
    >
      <div className={[styles.content, 'flex-column', 'gap-24'].join(' ')}>
        <Input
          id="trigger"
          placeholder="e.g., pcp"
          label="Short hand trigger"
          required
          value={data.trigger}
          onTextChange={(value) => onChangeHandler('trigger', value)}
          errorText={errors.trigger}
          requiredColor={ColorsNew.mediumDarkRed}
        />
        <Input
          id="display-name"
          placeholder="e.g., Refer to PCP"
          label="Display Name"
          required
          value={data.displayName}
          onTextChange={(value) => onChangeHandler('displayName', value)}
          errorText={errors.displayName}
          requiredColor={ColorsNew.mediumDarkRed}
        />
        <TextArea
          id="template-text"
          label="Template Text"
          placeholder="Text to insert when template is selected"
          maxRows={4}
          value={data.templateText}
          onTextChange={(value) => onChangeHandler('templateText', value)}
          required
          errorText={errors.templateText}
        />
        {!promptTemplateId && canManagePromptTemplates && (
          <Select
            id="role-ids"
            label="Role"
            options={roleOptions}
            onChange={(op) => onChangeHandler('roles', op)}
            value={data.roles}
            placeholder="Select role"
            paperHeight={270}
            multiple
            showAllValues
            classNames={[!data.roles.length ? styles.select : '']}
            tooltipText="If no role is selected, a personal prompt template will be created."
          />
        )}
        {promptTemplate?.roleId && canManagePromptTemplates && (
          <Checkbox
            id="update-for-all"
            label="Update for all"
            checked={shouldUpdateForAllRoles}
            onChange={(isChecked) => setShouldUpdateForAllRoles(isChecked)}
            classNames={[styles.checkbox]}
          />
        )}
      </div>
      {renderButtons({
        containerClassName: styles.buttonsContainer,
        onClose,
        onSubmit: onSubmitHandler,
        isSubmitEnabled: getIsButtonEnabled(data, promptTemplate, errors),
        submitButtonText: 'Save and close',
      })}
    </BasePopup>
  );
};

PromptTemplatePopup.propTypes = {
  promptTemplateId: PropTypes.string,
  onClose: PropTypes.func,
};

export default PromptTemplatePopup;
