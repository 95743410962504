import _ from 'lodash';

import { deleteOrmItem } from 'common-src/features/rest';
import PatientReferral, {
  createPatientReferral,
  deletePatientReferral,
  extractPatientReferral,
  updatePatientReferral,
} from 'common-src/models/PatientReferral';

export const getReferralRequests = (formData, referrals) => {
  const requests = [];

  if (!Array.isArray(referrals) || !Array.isArray(formData)) return requests;

  const oldReferrals = referrals.map((referral) => extractPatientReferral(referral));

  if (_.isEqual(formData, oldReferrals)) {
    return requests;
  }

  formData.forEach((referral) => {
    const referralExists = oldReferrals.find(({ id }) => id === referral.id);

    if (!referralExists) {
      requests.push(createPatientReferral(referral));
      return;
    }

    if (!_.isEqual(referral, referralExists)) {
      requests.push(updatePatientReferral(referral.id, referral));
    }
  });

  oldReferrals.forEach(({ id }) => {
    const referralExists = formData.find((referral) => referral.id === id);

    if (!referralExists) {
      requests.push(
        deletePatientReferral(id, {
          successBlock: () => {
            deleteOrmItem(PatientReferral.modelName, id);
          },
        }),
      );
    }
  });

  return requests;
};

export const hasDuplicateReferrals = (formData) => {
  const uniqueReferrals = _.uniqBy(
    formData,
    (referral) => `${referral.referralType}-${referral.status}-${referral.dateSent}`,
  );
  return uniqueReferrals.length !== formData.length;
};
