import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';

import { CommonIcons } from 'common-src/assets/Icons';
import { isDateValid } from 'common-src/utils/dateUtils';

import { generateDate, getFormattedDate } from '../../helpers';
import styles from './DateInput.module.scss';

const DateInput = forwardRef(
  (
    {
      value,
      idValue,
      withTime,
      isMonthly,
      classNames,
      setErrorMsg,
      onBlurHandler,
      onClickHandler,
      onClearHandler,
      disabled,
      isRange,
      isSmall,
      textClassNames = [],
      iconSrc = CommonIcons.calendar,
    },
    ref,
  ) => {
    const [date, setDate] = useState(null);
    const inputClasses = [styles.input, isSmall ? 'font-s-14' : 'font-s-16', ...textClassNames];
    const iconClasses = [styles.icon];

    if (disabled) {
      inputClasses.push(styles.disabled);
      iconClasses.push(styles.disabled);
    }

    const getInputValue = () => {
      if (date !== null && date !== 'Invalid date') {
        return date;
      }

      return value;
    };

    const getInputMask = () => {
      if (isRange) {
        return '99/99/9999 / 99/99/9999';
      }

      if (withTime) {
        return '99/99/9999 99:99 aa';
      }

      return isMonthly ? '99/9999' : '99/99/9999';
    };

    const getPlaceholder = () => {
      if (isRange) {
        return 'MM/DD/YYYY / MM/DD/YYYY';
      }

      if (withTime) {
        return 'MM/DD/YYYY HH:MM aa';
      }

      return isMonthly ? 'MM/YYYY' : 'MM/DD/YYYY';
    };

    const onBlur = () => {
      if (!date) return;

      if (!date.match(/[0-9]+/g)) {
        setErrorMsg(null);
        onBlurHandler('');
        return;
      }

      if (date.includes('_')) {
        onBlurHandler();
        setErrorMsg('Invalid date format');
        return;
      }

      if (isRange) {
        const [start, end] = date.replaceAll('/', '-').split(' - ');

        if (!isDateValid(start)) {
          setErrorMsg('Invalid start date format');
          return;
        }

        if (!isDateValid(end)) {
          setErrorMsg('Invalid end date format');
          return;
        }

        if (moment(moment(start)).isAfter(moment(end))) {
          setErrorMsg('Start date should be before end date');
          return;
        }

        setErrorMsg(null);
        onBlurHandler([moment(start).valueOf(), moment(end).valueOf()]);
        return;
      }

      if (date && getFormattedDate(generateDate(date, isMonthly)).toString() === 'Invalid Date') {
        setErrorMsg('Invalid date format');
        onBlurHandler();
        return;
      }

      setErrorMsg(null);
      onBlurHandler(date ? generateDate(date, isMonthly) : value);
    };

    // call blur handler in case of full typed date
    useEffect(() => {
      if (!isMonthly && !isRange && date?.length === 10 && !date?.includes('_')) {
        onBlur();
      }
    }, [date]);

    useEffect(() => {
      if (!value) {
        setDate(null);
      }
    }, [value]);

    return (
      <div className={classNames.join(' ')} type="button">
        <img
          className={iconClasses.join(' ')}
          src={iconSrc}
          onClick={onClickHandler}
          alt="calendar icon"
          role="presentation"
        />
        <InputMask
          id={idValue}
          className={inputClasses.join(' ')}
          placeholder={getPlaceholder()}
          ref={ref}
          onBlur={onBlur}
          value={getInputValue()}
          onChange={(e) => !disabled && setDate(e.target.value)}
          mask={getInputMask()}
          disabled={disabled}
        />
        {value && (
          <img
            className={iconClasses.join(' ')}
            src={CommonIcons.closeIcon}
            onClick={onClearHandler}
            alt="clear icon"
            role="presentation"
          />
        )}
      </div>
    );
  },
);

DateInput.propTypes = {
  idValue: PropTypes.string,
  value: PropTypes.string,
  withTime: PropTypes.bool,
  isMonthly: PropTypes.bool,
  classNames: PropTypes.arrayOf(PropTypes.string),
  setErrorMsg: PropTypes.func,
  onBlurHandler: PropTypes.func,
  onClickHandler: PropTypes.func,
  onClearHandler: PropTypes.func,
  disabled: PropTypes.bool,
  isRange: PropTypes.bool,
  iconSrc: PropTypes.string,
  textClassNames: PropTypes.arrayOf(PropTypes.string),
  isSmall: PropTypes.bool,
};

export default DateInput;
