// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RoutineExams-module__grid___fnAFI {
  width: 100%;
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(5, minmax(0, 220px)) 0.4fr;
}

.RoutineExams-module__button___sx6ET {
  margin-bottom: 8px;
  grid-column: 1 span;
}
.RoutineExams-module__button___sx6ET > img {
  width: 18px;
  height: 18px;
}
.RoutineExams-module__button___sx6ET > span {
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/forms/RoutineExams/RoutineExams.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,wDAAA;AACF;;AAEA;EACE,kBAAA;EACA,mBAAA;AACF;AACE;EACE,WAAA;EACA,YAAA;AACJ;AAEE;EACE,0BAAA;AAAJ","sourcesContent":[".grid {\n  width: 100%;\n  display: grid;\n  column-gap: 16px;\n  grid-template-columns: repeat(5, minmax(0, 220px)) 0.4fr;\n}\n\n.button {\n  margin-bottom: 8px;\n  grid-column: 1 span;\n\n  & > img {\n    width: 18px;\n    height: 18px;\n  }\n\n  & > span {\n    font-size: 14px !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `RoutineExams-module__grid___fnAFI`,
	"button": `RoutineExams-module__button___sx6ET`
};
module.exports = ___CSS_LOADER_EXPORT___;
