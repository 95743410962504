// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LabOrders-module__grid___SJOkT {
  width: 100%;
  display: grid;
  column-gap: 16px;
  grid-template-columns: repeat(9, minmax(0, 220px));
}
.LabOrders-module__grid___SJOkT .LabOrders-module__button___OZ_r6 {
  margin-bottom: 8px;
  grid-column: 1 span;
}
.LabOrders-module__grid___SJOkT .LabOrders-module__button___OZ_r6 > img {
  width: 18px;
  height: 18px;
}
.LabOrders-module__grid___SJOkT .LabOrders-module__button___OZ_r6 > span {
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/PatientDetails/fragments/Orders/forms/LabOrders/LabOrders.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,gBAAA;EACA,kDAAA;AACF;AACE;EACE,kBAAA;EACA,mBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEI;EACE,0BAAA;AAAN","sourcesContent":[".grid {\n  width: 100%;\n  display: grid;\n  column-gap: 16px;\n  grid-template-columns: repeat(9, minmax(0, 220px));\n\n  .button {\n    margin-bottom: 8px;\n    grid-column: 1 span;\n\n    & > img {\n      width: 18px;\n      height: 18px;\n    }\n\n    & > span {\n      font-size: 14px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `LabOrders-module__grid___SJOkT`,
	"button": `LabOrders-module__button___OZ_r6`
};
module.exports = ___CSS_LOADER_EXPORT___;
