import _ from 'lodash';
import { useEffect, useRef } from 'react';

import { apiRequest, HttpMethods } from 'common-src/features/rest';
import { QuestionnaireDocuments } from 'common-src/models/PatientInteraction';
import { TemplateItemType } from 'common-src/models/Template';

import { checkTemplateRequiredFields } from 'src/pages/DocumentationIndex/helpers';

const useDocumentationDynamicResult = (templates, setTemplates, hasDetectedChanges) => {
  const finishedQuestionnaires = useRef([]);

  const shouldSendRequest = (questionnaire) => {
    const finishedQuestionnaire = finishedQuestionnaires.current.find(
      (q) => q.id === questionnaire.id,
    );

    if (!finishedQuestionnaire) {
      finishedQuestionnaires.current.push(questionnaire);
      return true;
    }

    if (_.isEqual(finishedQuestionnaire, questionnaire)) return false;

    const objIndex = finishedQuestionnaires.current.findIndex((obj) => obj.id === questionnaire.id);
    finishedQuestionnaires.current[objIndex] = questionnaire;

    return true;
  };

  const updateQuestionnaireResult = (questionnaire, text) => {
    const templatesCopy = JSON.parse(JSON.stringify(templates));
    const questionnaireCopy = JSON.parse(JSON.stringify(questionnaire));
    const questionnaireIndex = templatesCopy.findIndex(
      (t) => t.templateType === questionnaire.templateType,
    );
    const result = [...questionnaireCopy.items]
      .reverse()
      .find((item) => item.itemType === TemplateItemType.Heading && item.size === 14);

    if (result) {
      result.text = text;
    }

    templatesCopy[questionnaireIndex] = questionnaireCopy;

    setTemplates(templatesCopy);
  };

  const updateResultSection = (questionnaire) => {
    const { hasMissingFields } = checkTemplateRequiredFields([questionnaire], false);

    if (hasMissingFields) {
      updateQuestionnaireResult(
        questionnaire,
        'Please, complete the questionnaire to see the result.',
      );

      finishedQuestionnaires.current = finishedQuestionnaires.current.filter(
        (q) => q.id !== questionnaire.id,
      );

      return;
    }

    if (!shouldSendRequest(questionnaire)) return;

    apiRequest({
      endpoint: 'templates/evaluateQuestionnaire',
      method: HttpMethods.Post,
      body: {
        templateType: questionnaire.templateType,
        version: 1,
        items: questionnaire.items,
      },
    })
      .then((res) => res.json())
      .then((res) => updateQuestionnaireResult(questionnaire, res.evaluation));
  };

  useEffect(() => {
    if (_.isEmpty(templates) || !hasDetectedChanges) return;

    const selectedTemplates = templates.filter((t) => t.isSelected);
    const selectedTemplateTypes = selectedTemplates.map((t) => t.templateType);
    const selectedQuestionnaireTypes = _.intersection(
      selectedTemplateTypes,
      QuestionnaireDocuments,
    );

    if (_.isEmpty(selectedQuestionnaireTypes)) return;

    const questionnaires = selectedTemplates.filter((t) =>
      QuestionnaireDocuments.includes(t.templateType),
    );

    questionnaires.forEach((questionnaire) => updateResultSection(questionnaire));
  }, [hasDetectedChanges]);
};

export default useDocumentationDynamicResult;
