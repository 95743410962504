import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import PatientInteraction from 'common-src/models/PatientInteraction';
import {
  extractRoutineExam,
  getMostRecentPatientRoutineExams,
} from 'common-src/models/PatientRoutineExam';

import { RoutineExams } from 'src/components/forms';
import { getTimer } from 'src/features/timeTracker';
import useCustomSelector from 'src/hooks/useCustomSelector';

import styles from './RoutineExamsModule.module.scss';

const RoutineExamsModule = ({
  patientId,
  errors,
  routineExams,
  setRoutineExams,
  setHasDetectedChanges,
  documentationToEdit,
}) => {
  const patientRoutineExams = useCustomSelector((state) =>
    getMostRecentPatientRoutineExams(state, patientId),
  );
  const timer = useCustomSelector((state) => getTimer(state, patientId));

  useEffect(() => {
    const initialExams =
      timer &&
      documentationToEdit.primaryModuleTypeId &&
      !_.isEmpty(documentationToEdit?.draftData?.routineExams)
        ? documentationToEdit?.draftData?.routineExams
        : patientRoutineExams.map(extractRoutineExam);

    setRoutineExams(initialExams || []);
    setHasDetectedChanges(new Date());
  }, []);

  return (
    <div className={styles.container}>
      <p className={['font-w-600', 'font-s-16', 'm-b-14'].join(' ')}>Routine Diabetes Exams</p>
      <RoutineExams
        patientId={patientId}
        isEditMode
        formData={routineExams}
        setFormData={(data) => {
          setRoutineExams(data);
          setHasDetectedChanges(new Date());
        }}
        errors={Object.fromEntries(errors)}
        setErrors={() => {}}
        isEmbedded
      />
    </div>
  );
};

RoutineExamsModule.propTypes = {
  patientId: PropTypes.number,
  errors: PropTypes.object,
  routineExams: PropTypes.array,
  setRoutineExams: PropTypes.func,
  setHasDetectedChanges: PropTypes.func,
  documentationToEdit: PropTypes.exact(PatientInteraction.schema),
};

export default RoutineExamsModule;
