import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { Accordion, RequestHandlerScreen } from 'common-src/components/base';
import { getRoleConfig, isViewOnly } from 'common-src/features/auth';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import Patient from 'common-src/models/Patient';
import { getPatientLabOrdersRequest } from 'common-src/models/PatientLabOrder';
import { getPatientReferralsRequest } from 'common-src/models/PatientReferral';
import { getPatientRoutineExamsRequest } from 'common-src/models/PatientRoutineExam/actions';

import useCustomSelector from 'src/hooks/useCustomSelector';
import useInterval from 'src/hooks/useInterval';
import useRequestLoading from 'src/hooks/useRequestLoading';

import { AlertSettings, DiabeticMonitoring, LabOrders, Referrals, RoutineExams } from './forms';
import styles from './Orders.module.scss';

const Orders = ({ patient }) => {
  const dispatch = useDispatch();

  const permissions = useCustomSelector(
    (state) => getRoleConfig(state)?.patientChart?.sections?.alerts,
  );
  const isHedisEnabled = useCustomSelector((state) => isFeatureEnabled(state, 'hedis'));

  const { isRequesting } = useRequestLoading([
    getPatientRoutineExamsRequest({ patientId: patient.id }),
    getPatientLabOrdersRequest({ patientId: patient.id }),
    getPatientReferralsRequest({ patientId: patient.id }),
  ]);

  useInterval(() => {
    dispatch(
      getPatientRoutineExamsRequest({ patientId: patient.id, sort_order: 'desc', limit: 5 }, {}),
    );
    dispatch(
      getPatientLabOrdersRequest({ patientId: patient.id, sort_order: 'desc', limit: 5 }, {}),
    );
    dispatch(
      getPatientReferralsRequest({ patientId: patient.id, sort_order: 'desc', limit: 5 }, {}),
    );
  });

  if (isRequesting) {
    return <RequestHandlerScreen isRequesting requestingText="Pulling data..." />;
  }

  return (
    <article id="orders-article" className={[styles.article, 'relative'].join(' ')}>
      <div className={[styles.wrapper, 'flex-column', 'gap-16'].join(' ')}>
        <Accordion
          classNames={[styles.width60]}
          title="Diabetic monitoring journeys"
          isDefaultExpanded
          content={<DiabeticMonitoring patient={patient} />}
        />
        {permissions && (
          <Accordion
            classNames={[styles.width60]}
            title="Alert settings"
            isDefaultExpanded
            content={<AlertSettings patient={patient} isViewOnly={isViewOnly(permissions)} />}
          />
        )}
        <Accordion
          classNames={[styles.width60]}
          title="Routine Diabetes Exams"
          isDefaultExpanded
          content={<RoutineExams patientId={patient.id} />}
        />
        {isHedisEnabled && (
          <>
            <Accordion
              classNames={[styles.width90]}
              title="Lab Orders"
              isDefaultExpanded
              content={<LabOrders patientId={patient.id} />}
            />
            <Accordion
              classNames={[styles.width90]}
              title="Referrals"
              isDefaultExpanded
              content={<Referrals patientId={patient.id} />}
            />
          </>
        )}
      </div>
    </article>
  );
};

Orders.propTypes = {
  patient: PropTypes.exact(Patient.schema),
};

export default Orders;
