import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { CommonIcons } from 'common-src/assets/Icons';
import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import { singleModelSelector } from 'common-src/utils/selectorUtils';

import Icons from 'src/assets/Icons';
import useCustomSelector from 'src/hooks/useCustomSelector';
import { getTab } from 'src/pages/Dashboard/helpers';

import styles from './TabItem.module.scss';

const TabItem = ({ tabName, onSelectTab, onCloseTab }) => {
  const location = useLocation();

  const patientId = tabName?.replace(/^\D+/g, '');
  const patient = useCustomSelector((state) =>
    patientId ? singleModelSelector(state, 'Patient', Number(patientId)) : null,
  );
  const isPromptTemplatesEnabled = useCustomSelector((state) =>
    isFeatureEnabled(state, 'promptTemplates'),
  );

  const patientName = useMemo(() => {
    if (_.isEmpty(patient)) return '';
    return patient.getName();
  }, [patient]);

  const isSelected = tabName === getTab(location?.pathname, isPromptTemplatesEnabled).getLabel();

  const getTabIcon = () => {
    switch (tabName) {
      case 'Dashboard':
        return Icons.smartIcon;
      default:
        return Icons.memberIcon;
    }
  };

  return (
    <div
      id={`tab-${tabName}`}
      className={[
        styles.container,
        isSelected ? styles.selected : '',
        'vertically-centered',
        'font-s-12',
        'font-w-500',
        'gap-5',
      ].join(' ')}
      onClick={() => {
        if (!isSelected) {
          onSelectTab({ label: tabName });
        }
      }}
      role="presentation"
    >
      <img
        className={[styles.tabIcon, isSelected ? styles.selected : ''].join(' ')}
        alt="tab-icon"
        src={getTabIcon()}
      />
      <span>
        {patientName || (tabName?.startsWith('Member Details') ? 'Member Details' : tabName)}
      </span>
      <img
        id={`close-tab-${tabName}-btn`}
        className={styles.closeIcon}
        src={isSelected ? Icons.boldCloseIcon : CommonIcons.closeIcon}
        alt="close-icon"
        onClick={(e) => {
          e.stopPropagation();
          onCloseTab(tabName, isSelected);
        }}
        role="presentation"
      />
    </div>
  );
};

TabItem.propTypes = {
  tabName: PropTypes.string,
  onSelectTab: PropTypes.func,
  onCloseTab: PropTypes.func,
};

export default TabItem;
