import _ from 'lodash';
import moment from 'moment-timezone';

import { ColorsNew } from 'common-src/styles';
import { bolderize } from 'common-src/utils/stringUtils';

const getGradient = (ctx, chartArea) => {
  const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);

  gradient.addColorStop(0, 'hsla(2, 100%, 81%, 0.6)');
  gradient.addColorStop(0.2, 'hsla(37, 93%, 82%, 0.6)');
  gradient.addColorStop(0.4, 'hsla(152, 64%, 79%, 0.7)');
  gradient.addColorStop(0.6, 'hsla(37, 93%, 82%, 0.7)');
  gradient.addColorStop(0.8, 'hsla(2, 100%, 81%, 0.5)');
  gradient.addColorStop(1, 'hsla(2, 100%, 81%, 0.6)');

  return gradient;
};

const getFilteredData = async (inputs, getInputs, removeInputs, displayAll) => {
  if (displayAll) {
    const results = inputs.map((input) => ({
      recordedAt: input.getRecordedAt('YYYY-MM-DD HH:mm:ss'),
      readings: [],
      minValue: Math.round(input.data.v),
      maxValue: Math.round(input.data.v),
      data: {
        v: Math.round(input.data.v),
      },
    }));

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(results);
      }, 250);
    });
  }

  const results = [];
  do {
    const firstEl = inputs[0];
    const currArr = getInputs(inputs, firstEl);
    if (currArr) {
      inputs = removeInputs(inputs, firstEl);

      results.push({
        recordedAt: firstEl?.getRecordedAt('YYYY-MM-DD HH:mm:ss'),
        readings: currArr.map((r) => ({
          ...r,
          recordedAt: r.getRecordedAt('YYYY-MM-DD HH:mm:ss'),
        })),
        minValue: Math.min(...currArr.map((input) => input.data.v)),
        maxValue: Math.max(...currArr.map((input) => input.data.v)),
        data: {
          v: Math.round(currArr.reduce((acc, input) => acc + input.data.v / currArr.length, 0)),
        },
      });
    } else {
      results.push(firstEl);
    }
  } while (inputs.length > 0);

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(results);
    }, 250);
  });
};

const getDataRange = (inputs) => {
  const minValue = Math.min(...inputs.map((input) => input.data.v));
  const maxValue = Math.max(...inputs.map((input) => input.data.v));
  const avgValue = Math.round(inputs.reduce((acc, input) => acc + input.data.v / inputs.length, 0));

  return { minValue, maxValue, avgValue };
};

const generateChartData = (data) => {
  const chartData = data.map((input) => ({ x: input.recordedAt, y: input.data.v }));

  return chartData;
};

const generateChartLabels = (period, endingDate) => {
  const labels = [];
  for (let i = 1; i <= period; i++) {
    if (i === 1) {
      labels.push(moment(endingDate).format('YYYY-MM-DD HH:mm'));
    }

    const label = moment(endingDate).subtract(i, 'days').format('YYYY-MM-DD HH:mm');
    labels.push(label);
  }

  return labels;
};

const getPointColor = (reading, axisYValues) => {
  if (reading) {
    const value = reading?.data?.v;
    const { minValue, maxValue } = reading;

    if (
      (!axisYValues.highValue && value >= axisYValues.maxValue) ||
      (!axisYValues.lowValue && value <= axisYValues.minValue) ||
      value >= axisYValues.highValue ||
      value <= axisYValues.lowValue ||
      maxValue >= axisYValues.highValue ||
      minValue <= axisYValues.lowValue
    ) {
      return ColorsNew.baseRed;
    }

    if (value < axisYValues.avgValue && value >= axisYValues.lowValue) {
      return ColorsNew.lightGreen;
    }

    return ColorsNew.mediumLightOrange;
  }
};

const getAxisYGridColor = (value, axisYValues) => {
  switch (value) {
    case axisYValues.avgValue:
      return ColorsNew.baseBlue;
    case axisYValues.highValue:
    case axisYValues.lowValue:
      return ColorsNew.baseRed;
    default:
      return 'rgba(91, 91, 91, 0.2)';
  }
};

const getAxisYLabel = (value, axisYValues) => {
  switch (value) {
    case axisYValues.minValue:
      return `${value} min`;
    case axisYValues.avgValue:
      return `${value} avg`;
    case axisYValues.maxValue:
      return `${value} max`;
    default:
      return '';
  }
};

const getYAxesConfig = (position, tickFilterFunc, axisYValues, ticks) => ({
  type: 'linear',
  position,
  ticks: {
    callback: (value) => getAxisYLabel(value, axisYValues),
    color: ColorsNew.darkGreen,
    padding: 10,
  },
  min: Math.min(axisYValues.minValue, axisYValues.lowValue) - 10,
  max: Math.max(axisYValues.maxValue, axisYValues.highValue),
  afterBuildTicks: (scale) => {
    scale.ticks = ticks.filter(tickFilterFunc);
  },
  border: {
    dash: [8, 4],
    display: position === 'left',
  },
  grid: {
    color: (y) => getAxisYGridColor(y.tick.value, axisYValues),
    drawTicks: false,
  },
});

const getTooltipBody = (item, filteredData, timeZone) => {
  const point = filteredData[item.dataIndex];
  if (!_.isEmpty(point.readings)) {
    const result = [`Average value: ${item.formattedValue} mg/dL`.replace(/./g, bolderize)];
    point.readings.forEach((r) => {
      result.push(
        `${`${r.data.v} mg/dL`.replace(/./g, bolderize)} at ${moment(r.recordedAt).format(
          'hh:mm A',
        )} ${moment().tz(timeZone).format('z')}`,
      );
    });
    return result;
  }

  return [`${item.formattedValue} mg/dL`.replace(/./g, bolderize)];
};

const getTooltipFooter = (items, selectedPeriod, timeZone) => {
  let format = 'MM/DD/YYYY hh:mm A';
  let withTimeZone = true;
  if (selectedPeriod > 30) {
    format = 'MM/DD/YYYY';
    withTimeZone = false;
  }

  const zoneAbbr = withTimeZone ? moment().tz(timeZone).format('z') : '';
  const dateTime = items[0]?.parsed?.x;

  return dateTime ? `${moment(dateTime).format(format)} ${zoneAbbr}` : '-';
};

export {
  getGradient,
  getFilteredData,
  getDataRange,
  generateChartData,
  generateChartLabels,
  getPointColor,
  getYAxesConfig,
  getTooltipBody,
  getTooltipFooter,
};
