import { isFeatureEnabled } from 'common-src/models/FeatureFlag';
import { allModelsSelector } from 'common-src/utils/selectorUtils';

import { MessageTypes } from './constants';

export const getPatientHistoryEvents = (state, patientId) => {
  const isVideoCallsEnabled = isFeatureEnabled(state, 'videoCalls');

  return allModelsSelector(
    state,
    'PatientHistoryEvent',
    (phe) =>
      phe.patientId === patientId &&
      (isVideoCallsEnabled ? true : phe.typeId !== MessageTypes.VideoCall),
  ).sort((a, b) => {
    const aDateToCompare = a?.meta?.sentAt || a.createdAt;
    const bDateToCompare = b?.meta?.sentAt || b.createdAt;

    return new Date(aDateToCompare) - new Date(bDateToCompare);
  });
};
