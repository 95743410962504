export default {
  // green
  ultraLightGreen: '#f1f6f2',
  lighterGreen: '#f1f3e7',
  lightGreen: '#a8eccc',
  mediumLightGreen: '#0ECB87',
  baseGreen: '#aec082',
  mediumGreen: '#859989',
  mediumDarkGreen: '#637768',
  darkGreen: '#375844',

  // orange
  baseOrange: '#f1bf72',
  mediumLightOrange: '#fcdba7',
  darkOrange: '#c56836',

  // gray
  ultraLightGray: '#fdfcfa',
  darkGray: '#6d7278',

  // red
  baseRed: '#ffa19d',
  mediumRed: '#db2828',
  mediumDarkRed: '#f06c67',

  // blue
  baseBlue: '#4683ff',
};
